import { useQuery } from "@tanstack/react-query";
import {
  getCollectiblesManagerABI,
  getTicketABI,
  getTicketManagerABI,
} from "../../../api/ticketing";
import { getGasEstimates } from "../../../api/collectibles";

export const useGetTicketABI = () => {
  return useQuery({
    queryKey: ["ticketABI"],
    queryFn: async () => {
      const data = await getTicketABI();

      return data.abi;
    },
  });
};

export const useGetTicketManagerABI = () => {
  return useQuery({
    queryKey: ["ticket-manager-ABI"],
    queryFn: async () => {
      const data = await getTicketManagerABI();

      return data.abi;
    },
  });
};

export const useGetCollectiblesManagerABI = () => {
  return useQuery({
    queryKey: ["collectibles-manager-ABI"],
    queryFn: async () => {
      const data = await getCollectiblesManagerABI();

      return data.abi;
    },
  });
};

export const useGetGasEstimates = () => {
  return useQuery({
    queryKey: ["gas-estimates"],
    queryFn: async () => {
      const { success, estimatedGasPrice } = (await getGasEstimates()) || {};

      if (!success) return null;

      return estimatedGasPrice;
    },
  });
};
