import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.footer`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: linear-gradient(
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.5)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 999;
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 10px 20px 17px;
  min-height: 82px;
`;

export const NavLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  transition: 0.2s ease-in-out;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
`;

export const ProfileImage = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
`;
