import { useAlchemyQuery } from "../../routes/Profile/hooks/profileQueries";
import { useBuyCollectibleStore } from "../../routes/PaidCollectible/stores/buyCollectibleStore";
import TopNavV2 from "../TopNavV2";
import {
  Container,
  Section,
  StatusContainer,
  ThumbnailContainer,
} from "./BuyCollectibles-Styles";
import CollectibleThumbnail from "../Collectibles/CollectibleThumbnail";
import { FooterText } from "../../GlobalStyles";
import CollectiblesStatus from "../CollectiblePage/CollectiblesStatus";
import CollectibleItem from "../Collectibles/CollectibleItem";
import BuyCollectiblesSummary from "./BuyCollectiblesSummary";

export default function BuyCollectibles() {
  const { collectible, setIsOpen } = useBuyCollectibleStore();

  const { max_per_account } = collectible || {};

  useAlchemyQuery();

  const handleBack = () => {
    setIsOpen(false);
  };

  return (
    <Section>
      <TopNavV2
        title="Buy collectible"
        onBack={handleBack}
        isSticky
        isBlurred
      />
      <Container>
        <ThumbnailContainer>
          <CollectibleThumbnail collectible={collectible} />
        </ThumbnailContainer>
        <StatusContainer>
          <FooterText>Max of {max_per_account} per account</FooterText>
          <CollectiblesStatus />
        </StatusContainer>
        <CollectibleItem />
      </Container>
      <BuyCollectiblesSummary />
    </Section>
  );
}
