import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px;
  max-height: 90svh;
  overflow-y: auto;
  width: 100%;
`;

export const TicketContainer = styled.div`
  padding: 16px 0 24px;
  width: 100%;
`;
