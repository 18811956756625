import { FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { useMemo } from "react";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";

const { colors } = theme;

export default function CollectiblesStatus() {
  const { collectiblesSelected, userCollectibles } = useBuyCollectibleStore();
  const { soldOut, maxCount, userMints, remaining } = userCollectibles || {};

  const isMaxQuantity = useMemo(() => {
    return (
      collectiblesSelected.length >= maxCount - userMints ||
      collectiblesSelected.length >= remaining
    );
  }, [collectiblesSelected, maxCount, userMints, remaining]);

  if (soldOut) {
    return (
      <FooterText color={colors.red} weight="600">
        Sold out
      </FooterText>
    );
  }

  if (userMints >= maxCount) {
    return (
      <FooterText color={theme.colors.red}>
        {"You've reached the limit bought"}
      </FooterText>
    );
  }

  return (
    <FooterText color={isMaxQuantity ? colors.green : colors.grey}>
      {collectiblesSelected?.length || 0}/{maxCount - userMints} selected
    </FooterText>
  );
}
