import { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  MuteButton,
  PlayButton,
  Video,
} from "./CollectibleVideo-Styles";
import { useMuteStore } from "../../../store/muteStore";
import VolumeHighIcon from "../../../assets/svg/volume-high.svg?react";
import VolumeMutedIcon from "../../../assets/svg/volume-muted.svg?react";
import { Play } from "@phosphor-icons/react";

export default function CollectibleVideo({ video }) {
  const videoRef = useRef();
  const [isPaused, setIsPaused] = useState(false);
  const [error, setError] = useState(null);
  const { isMuted, handleMute } = useMuteStore();

  const handlePlay = () => {
    if (!videoRef.current) return;

    if (videoRef.current.paused && isPaused) {
      videoRef.current.play();
      setIsPaused(false);
      return;
    }
    videoRef.current?.pause();
    setIsPaused(true);
  };

  return (
    <Container>
      <Video
        autoPlay
        loop
        playsInline
        muted={isMuted}
        src={video}
        onClick={handlePlay}
        ref={videoRef}
        onError={(e) => {
          setError(e.target.error);
        }}
      />
      {isPaused && !error && (
        <PlayButton onClick={handlePlay}>
          <Play height={52} width={52} color="white" />
        </PlayButton>
      )}
      {!error && (
        <MuteButton onClick={handleMute}>
          {!isMuted ? (
            <VolumeHighIcon height={16} width={16} />
          ) : (
            <VolumeMutedIcon height={16} width={16} />
          )}
        </MuteButton>
      )}
    </Container>
  );
}

CollectibleVideo.propTypes = {
  video: PropTypes.string,
};
