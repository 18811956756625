import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  BodyText,
  ButtonRect,
  ButtonRectSm,
  FooterText,
} from "../../GlobalStyles";
import ChatIcon from "../../assets/svg/chat.svg?react";
import useMixpanelTracker from "../../hooks/useMixpanelTracker";
import useMomentifyLogin from "../../hooks/useMomentifyLogin";
import {
  createOrUpdatePreEventRoomHandler,
  getRoom,
} from "../../utils/matrix-utils";
import {
  isCinnyAuthenticated,
  isCinnySupported,
} from "../../utils/cinny-utils";
import { ChatButton } from "./RoomButton-Styles";
import { toast } from "react-toastify";
import { useState } from "react";

export default function RoomButton({
  event,
  moment,
  dark,
  size,
  shape,
  withCount,
}) {
  const [creatingRoom, setCreatingRoom] = useState(false);
  const navigate = useNavigate();
  const { loggedInUser, login } = useMomentifyLogin();
  const { handleTracking } = useMixpanelTracker();
  const noRoomYet = moment && !moment?.room;

  const handleMomentClick = async (e) => {
    e.preventDefault();

    // Get the post-event room data
    let postEventRoom = moment.room;
    if (!postEventRoom) return;

    // Track the user's entry into the post-event room
    handleTracking(
      "Enter Post-event Room",
      {
        "Room data": JSON.stringify({
          room_id: postEventRoom.id,
          room_type: "post event room",
        }),
      },
      loggedInUser.id
    );

    // Navigate to the post-event room chat
    navigate(`/chat?roomID=${postEventRoom.room_address}`);
  };

  const handleEventClick = async () => {
    if (!loggedInUser) return login();

    try {
      // Get the pre-event room data
      let preEventRoom = await getRoom(event.room);

      if (!preEventRoom && isCinnySupported() && isCinnyAuthenticated()) {
        setCreatingRoom(true);
        await toast.promise(
          async () => {
            preEventRoom = await createOrUpdatePreEventRoomHandler(event).catch(
              (err) => {
                console.error(err);
                return false;
              }
            );
          },
          {
            pending: `Creating room...`,
            error: `Error creating room.`,
          }
        );

        if (!preEventRoom) return setCreatingRoom(false);
      }

      // Track the user's entry into the pre-event room
      handleTracking(
        "Enter Pre-event Room",
        {
          "Room data": JSON.stringify({
            room_id: preEventRoom.id,
            room_type: "pre event room",
          }),
        },
        loggedInUser.id
      );

      navigate(`/chat?roomID=${preEventRoom.room_address}`);
    } catch (err) {
      console.error(err);
      setCreatingRoom(false);
    } finally {
      setCreatingRoom(false);
    }
  };

  // Determine which click handler to use based on the moment's existence
  const handleOnClick = (e) => {
    if (!loggedInUser) return login();

    if (moment) handleMomentClick(e);
    else handleEventClick(e);
  };

  if (shape === "rect" && size === "small") {
    return (
      <ButtonRectSm
        onClick={handleOnClick}
        disabled={creatingRoom || noRoomYet}
      >
        <ChatIcon stroke="white" height={14} width={14} />
        <FooterText weight="600">Chat</FooterText>
      </ButtonRectSm>
    );
  }

  if (shape === "rect" && size !== "small") {
    return (
      <ButtonRect onClick={handleOnClick} disabled={creatingRoom || noRoomYet}>
        <ChatIcon stroke="white" height={14} width={14} />
        <BodyText weight="600">Chat</BodyText>
      </ButtonRect>
    );
  }

  if (withCount) {
    return (
      <ButtonRectSm
        onClick={handleOnClick}
        disabled={creatingRoom || noRoomYet}
        primary
      >
        <ChatIcon stroke="white" height={14} width={14} />
        <FooterText>
          {event?.room?.total_members ?? moment?.room?.total_members ?? 0}
        </FooterText>
      </ButtonRectSm>
    );
  }
  // Render the chat button with appropriate props
  return (
    <ChatButton
      onClick={handleOnClick}
      dark={dark}
      disabled={creatingRoom || noRoomYet}
    >
      <ChatIcon stroke="white" height={16} width={16} />
    </ChatButton>
  );
}

RoomButton.propTypes = {
  event: PropTypes.object,
  moment: PropTypes.object,
  dark: PropTypes.bool,
  size: PropTypes.string,
  shape: PropTypes.string,
  withCount: PropTypes.bool,
};
