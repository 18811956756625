import EmptyState from "../../EmptyState";
import Joy from "../../../assets/svg/joy.svg?react";
export default function BackstageProfileContent() {
  return (
    <EmptyState
      icon={<Joy height={24} width={24} opacity={0.6} />}
      title="Summary Coming Soon!"
      description="All your fan, event and revenue metrics will appear here magically soon!"
      style={{
        padding: "0px 20px",
        justifyContent: "center",
      }}
    />
  );
}
