import { ButtonRect } from "../../../GlobalStyles";
import { Container } from "./TicketTransferFooter-Styles";
import Plane from "../../../assets/svg/plane.svg?react";
import useGlobalModal from "../../../hooks/useGlobalModal";
import TransferTicketModal from "../../Modal/TransferTicketModal";

export default function TicketTransferFooter() {
  const { setSheetModalContent, toggleSheetModal } = useGlobalModal();

  const handleClick = () => {
    setSheetModalContent(<TransferTicketModal />);
    toggleSheetModal();
  };

  return (
    <Container>
      <ButtonRect green onClick={handleClick}>
        <Plane height={16} width={16} stroke="black" strokeWidth={1.5} />
        Transfer
      </ButtonRect>
    </Container>
  );
}
