import PropTypes from "prop-types";
import {
  ContentContainer,
  DetailsContainer,
  NavContainer,
  NavImage,
  NavTitle,
} from "./TopNavV2-Styles";
import BackButton from "../BackButton";
import { useScrollStore } from "../../store/scrollStore";
import { useLocation } from "react-router-dom";
import { SkeletonElement } from "../Skeleton/Skeleton-Style";
import { useEffect } from "react";

/**
 * Top navigation bar component
 * @param {string} type - The type of navigation bar
 * @returns {React.JSX.Element} - The top navigation bar
 */
export default function TopNavV2({
  icon,
  title,
  content,
  isSticky,
  isBlurred,
  noBack,
  isLoading,
  onBack,
  style,
}) {
  const location = useLocation();
  const { scrollPosition, setScrollPosition } = useScrollStore();

  // Effect to add and remove scroll event listener
  useEffect(() => {
    const handleScroll = (e) => {
      const { scrollTop } = e.target;

      setScrollPosition(scrollTop);
    };

    const rootElement = document.querySelector("#root");
    rootElement.addEventListener("scroll", handleScroll);

    return () => {
      rootElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Return the navigation bar components based on the type and profile ownership
  return (
    <NavContainer
      blur={isBlurred || scrollPosition > 10}
      isSticky={isSticky}
      style={style}
    >
      <DetailsContainer>
        {!noBack && <BackButton onClick={onBack} />}
        {icon &&
          (isLoading ? (
            <SkeletonElement
              height="32px"
              width="32px"
              radius="50%"
              style={{
                marginRight: "16px",
              }}
            />
          ) : (
            <NavImage
              isVisible={
                isSticky || location.pathname.includes("performances")
                  ? true
                  : scrollPosition > 180
              }
              src={icon}
              style={{
                borderRadius:
                  location.pathname.includes("venues") ||
                  location.pathname.includes("events")
                    ? "8px"
                    : "50%",
                height: location.pathname.includes("backstage") ? 40 : 32,
                width: location.pathname.includes("backstage") ? 40 : 32,
                marginRight: location.pathname.includes("backstage") ? 12 : 16,
              }}
            />
          ))}
        {isLoading ? (
          <SkeletonElement height="32px" width="200px" />
        ) : (
          <NavTitle
            isVisible={
              isSticky || location.pathname.includes("performances")
                ? true
                : scrollPosition > 180
            }
            truncate
          >
            {title}
          </NavTitle>
        )}
      </DetailsContainer>
      {content && (
        <ContentContainer
          isVisible={
            isSticky ||
            location.pathname.includes("performances") ||
            location.pathname.includes("backstage")
              ? true
              : location.pathname.includes("events")
                ? scrollPosition > 310
                : scrollPosition > 180
          }
        >
          {content}
        </ContentContainer>
      )}
    </NavContainer>
  );
}

TopNavV2.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node,
  isSticky: PropTypes.bool,
  isBlurred: PropTypes.bool,
  noBack: PropTypes.bool,
  isLoading: PropTypes.bool,
  onBack: PropTypes.func,
  style: PropTypes.object,
};
