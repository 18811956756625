import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 10px;
  padding: 16px;
  gap: 16px;
  width: 100%;
  overflow: hidden;
`;

export const PriceContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: nowrap;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RightContainer = styled(LeftContainer)`
  align-items: flex-end;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
