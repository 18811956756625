import { BodyText, ButtonRect } from "../../GlobalStyles";
import TicketIcon from "../../assets/svg/ticket.svg?react";
import PropTypes from "prop-types";
import useAuth from "../../hooks/useAuth";
import { upsertTicketClick } from "../../api/events";

export default function TicketButton({ event, ticketType, ticketUrl }) {
  const { loggedInUser } = useAuth();

  const handleOnClick = (e) => {
    if (!ticketUrl) return;

    e.preventDefault();
    window.open(ticketUrl, "_blank");

    upsertTicketClick({
      userId: loggedInUser?.id,
      eventId: event?.id,
      ticketType: ticketType,
      ticketUrl: ticketUrl,
    });
  };

  return (
    <ButtonRect
      onClick={handleOnClick}
      style={{ padding: "12px 16px" }}
      primary
    >
      <TicketIcon stroke="white" height={16} width={16} />
      <BodyText weight="600">Buy</BodyText>
    </ButtonRect>
  );
}

TicketButton.propTypes = {
  event: PropTypes.object,
  ticketType: PropTypes.string,
  ticketUrl: PropTypes.string,
};
