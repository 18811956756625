import PropTypes from "prop-types";
import { Container } from "./TicketEvent-Styles";
import { useNavigate } from "react-router-dom";
import {
  BodyText,
  ButtonRectSm,
  FooterText,
  IconWithText,
} from "../../../GlobalStyles";
import { ArrowUpRight } from "@phosphor-icons/react";
import { theme } from "../../../utils/theme";
import moment from "moment";
import {
  ArtistContainer,
  HeaderContainer,
} from "../../../routes/Collectible/Collectible-Styles";
import ArtistIcon from "../../Artist/ArtistIcon";
import MapIcon from "../../../assets/svg/map.svg?react";
import BookmarkIcon from "../../../assets/svg/bookmark.svg?react";
import { useMemo } from "react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

const { colors } = theme;

export default function TicketEventDetails({ ticket, isLoading }) {
  const {
    headline_artist,
    artists,
    event_id,
    date,
    event_start_time,
    venue,
    city,
    country,
  } = ticket || {};
  const navigate = useNavigate();

  const eventDate = event_start_time || date;

  const headlineArtist = useMemo(
    () => artists?.find((artist) => artist.artist_name === headline_artist),
    [artists, headline_artist]
  );

  const { id: artist_id, artist_name, profile_img } = headlineArtist || {};

  if (isLoading) {
    return (
      <Container>
        <HeaderContainer>
          <ArtistContainer>
            <SkeletonElement height="40px" width="40px" radius="50%" />
            <SkeletonElement height="24px" width="100px" />
          </ArtistContainer>
          <SkeletonElement height="32px" width="123px" radius="10px" />
        </HeaderContainer>
        <Container style={{ gap: "8px" }}>
          <SkeletonElement height="17px" width="200px" />
          <SkeletonElement height="17px" width="200px" />
        </Container>
      </Container>
    );
  }
  return (
    <Container>
      <HeaderContainer>
        <ArtistContainer onClick={() => navigate(`/artists/${artist_id}`)}>
          <ArtistIcon imageUrl={profile_img} />
          <BodyText truncate>{artist_name}</BodyText>
        </ArtistContainer>
        <ButtonRectSm
          primary
          onClick={() =>
            navigate(`/events/${event_id}?date=${eventDate.split("+")[0]}`)
          }
        >
          <FooterText>View event</FooterText>
          <ArrowUpRight size={20} />
        </ButtonRectSm>
      </HeaderContainer>
      <Container style={{ gap: "8px" }}>
        <IconWithText>
          <div>
            <MapIcon
              height={12}
              width={12}
              stroke={colors.white}
              fill={colors.white}
            />
          </div>
          <BodyText truncate>
            {venue}, {city}, {country}
          </BodyText>
        </IconWithText>
        <IconWithText>
          <div>
            <BookmarkIcon
              height={12}
              width={12}
              stroke={colors.white}
              fill={colors.white}
            />
          </div>
          <BodyText truncate color={colors.green}>
            {moment(eventDate).utc().format("ddd DD MMM")}
          </BodyText>
          <FooterText color={colors.grey}>
            {moment(eventDate).utc().format("hh:ss A")}
          </FooterText>
        </IconWithText>
      </Container>
    </Container>
  );
}

TicketEventDetails.propTypes = {
  ticket: PropTypes.object,
  isLoading: PropTypes.bool,
};
