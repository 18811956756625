import { ButtonRect } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { ContentContainer } from "./CollectiblePurchase-Styles";
import CollectiblesIcon from "../../../assets/svg/collectibles-plus.svg?react";
import { useMemo } from "react";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
const { colors } = theme;

export default function CollectiblePurchaseButton() {
  const { loggedInUser, login } = useMomentifyLogin();
  const { collectiblesSelected, setIsOpen } = useBuyCollectibleStore();

  const disabled = useMemo(() => {
    return !collectiblesSelected?.length;
    // return !isEligible || !collectiblesSelected?.length;
  }, [collectiblesSelected]);

  const handleOpenModal = () => {
    if (!loggedInUser) {
      return login();
    }

    setIsOpen(true);
  };

  return (
    <ContentContainer style={{ width: "100%", alignItems: "center" }}>
      <ButtonRect
        style={{
          border: "none",
          background: colors.green,
          color: "black",
          gap: 8,
        }}
        disabled={disabled}
        onClick={handleOpenModal}
      >
        <CollectiblesIcon height={14} width={14} stroke="black" />
        Collect
      </ButtonRect>
      {/* {!isEligible && (
        <FooterText color={colors.red}>Sorry, you’re not eligible.</FooterText>
      )} */}
    </ContentContainer>
  );
}
