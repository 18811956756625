import { useQuery } from "@tanstack/react-query";
import { getBackstageAccess } from "../../../api/backstage";
import { useBackstageStore } from "../../../store/backstageStore";

export const useBackstageAccessQuery = ({ userId }) => {
  const { setArtistIds, setVenueIds } = useBackstageStore();

  return useQuery({
    queryKey: ["backstage-access", userId],
    queryFn: async () => {
      const access = await getBackstageAccess(userId);

      const artistIds = access
        .map((row) => row?.combined_artist_ids?.[0])
        .filter((row) => !!row);

      setArtistIds(artistIds);

      const venueIds = access
        .map((row) => row?.venue_id)
        .filter((row) => !!row);

      setVenueIds(venueIds);

      return access;
    },
    enabled: !!userId,
  });
};
