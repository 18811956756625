import moment from "moment";
import PropTypes from "prop-types";
import { FooterText } from "../../GlobalStyles";
import { EventItemTypes, EventPlaceholder } from "../../constants/constants";
import { useGetRoom } from "../../routes/Event/hooks/eventQueries";
import { theme } from "../../utils/theme";
import RoomButton from "../RoomButton";
import {
  ButtonsContainer,
  Card,
  EventDate,
  EventDetails,
  EventDetailsContainer,
  EventDetailsDiv,
  EventHeadlineArtist,
  EventImage,
  EventVenue,
  RoomButtonContainer,
} from "./EventItem-Styles";
import InterestedButton from "./InterestedButton";

export default function EventItem({
  event,
  type,
  isOwnProfile,
  innerRef,
  isBackstage,
}) {
  // Updating this to react query so that it's reusable by other components
  const roomQuery = useGetRoom({ room: event.room });
  const { colors } = theme;

  // Remove everything after + in date
  const date = event?.date && event?.date.split("+")[0];

  const link = isBackstage
    ? `/backstage/events/${event?.id ?? event?.event_id}?date=${date}`
    : `/events/${event?.id ?? event?.event_id}?date=${date}`;

  // No event venue name
  if (event.venue_name == "") return <></>;

  return (
    <Card to={link} key={event?.id ?? event?.event_id} ref={innerRef}>
      <EventDetailsContainer>
        <EventImage
          loading="lazy"
          src={event.image || EventPlaceholder}
          onError={(e) => (e.target.src = EventPlaceholder)}
          type={type}
        />
        <EventDetails>
          <EventDetailsDiv>
            <EventHeadlineArtist>{event.headline_artist}</EventHeadlineArtist>
          </EventDetailsDiv>
          <EventDetailsDiv>
            {type !== EventItemTypes.VENUE && (
              <EventVenue color={colors.grey}>
                {event.venue_name}
                {event?.country_name && `, ${event.country_name}`}
              </EventVenue>
            )}
            <EventDate>
              <FooterText color={colors.softGreen}>
                {moment(event?.event_start_time ?? event.date)
                  .utc()
                  .format("ddd D MMM")}
              </FooterText>
              <FooterText color={colors.grey}>
                {moment(event?.event_start_time ?? event.date)
                  .utc()
                  .format("LT")}
              </FooterText>
            </EventDate>
          </EventDetailsDiv>
        </EventDetails>
      </EventDetailsContainer>
      <ButtonsContainer>
        {type === EventItemTypes.PROFILE &&
          isOwnProfile &&
          !!roomQuery?.data && (
            <RoomButtonContainer>
              <RoomButton event={event} />
              <FooterText color={colors.grey}>
                {event?.room?.total_members}
              </FooterText>
            </RoomButtonContainer>
          )}
        {isOwnProfile && (
          <div
            style={{
              marginBottom:
                type === EventItemTypes.PROFILE && roomQuery?.data
                  ? "20px"
                  : "0",
            }}
          >
            <InterestedButton event={event} size={20} />
          </div>
        )}
      </ButtonsContainer>
    </Card>
  );
}

EventItem.propTypes = {
  event: PropTypes.object.isRequired,
  type: PropTypes.string,
  isOwnProfile: PropTypes.bool,
  innerRef: PropTypes.any,
  isBackstage: PropTypes.bool,
};
