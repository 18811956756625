import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TopNavV2 from "../../components/TopNavV2";
import { useBackstageEventScansQuery } from "./hooks/backstageEventScansQueries";
import Scans from "../../components/BackstageEventScans/Scans";
import { Container, Header } from "./BackstageEventScans-Styles";
import BackstageEventFilters from "../../components/BackstageEventScans/Filters";
import SelectTicketFilter from "../../components/BackstageEventScans/SelectTicketFilter";
import { useTicketFiltersStore } from "../BackstageEvent/stores/ticketFiltersStore";

export default function BackstageEventScans() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isOpen } = useTicketFiltersStore();

  const { data, isLoading } = useBackstageEventScansQuery({
    eventId: id,
  });

  const handleBack = () => {
    navigate(`/backstage/events/${id}?date=${searchParams.get("date")}`);
  };

  if (isOpen) {
    return <SelectTicketFilter data={data} />;
  }

  return (
    <Container>
      <Header>
        <TopNavV2
          title="Scan history"
          onBack={handleBack}
          isSticky
          style={{
            background: "transparent",
            backdropFilter: "none",
          }}
        />
        {data?.length && <BackstageEventFilters />}
      </Header>
      <Scans data={data} isLoading={isLoading} />
    </Container>
  );
}
