import { useWertWidget } from "@wert-io/module-react-component";
import { theme } from "../../../utils/theme";
import { USDC_ADDRESS, WERT_TEST_TOKEN } from "../../../constants/constants";
import useAuth from "../../../hooks/useAuth";
import { encodeFunctionData } from "viem";
import { useGetCollectiblesManagerABI } from "./checkoutQueries";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { useCheckoutStore } from "../checkoutStore";
import {
  addDelayInMs,
  processCollectiblesMinting,
  sortItems,
} from "../../../utils/checkout";
import { useGetWertKey } from "../../../routes/Ticket/ticketQueries";
import { useEffect } from "react";
import { isProd } from "../../../utils/utils";
import ProcessingWertModal from "../../Modal/ProcessingWertModal";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";
import CollectiblePaymentSuccess from "../CollectiblePaymentSuccess";

const { colors } = theme;

const options = {
  // PUT YOUR PARTNER ID HERE
  partner_id: import.meta.env.VITE_WERT_PARTNER_ID,
  is_crypto_hidden: true,
  origin: isProd ? "https://widget.wert.io" : "https://sandbox.wert.io",
  extra: {
    item_info: {
      author: "Momentify",
      author_image_url: "https://cdn.momentify.xyz/test/image.png",
      image_url: null,
      name: null,
      category: "Collectible",
    },
  },
  color_background: colors.black,
  color_buttons: colors.green,
  buttons_border_radius: 12,
  color_buttons_text: colors.black,
  color_links: colors.green,
};

const smartContractOptions = {
  address: null,
  commodity: isProd ? "USDC" : "TT",
  network: isProd ? "base" : "base_sepolia",
  commodity_amount: 0, // Amount of total in USDC
  sc_address: import.meta.env.VITE_COLLECTIBLES_MANAGER_ADDRESS, // Collectibles Manager Contract Address
  sc_input_data: null,
  // PUT YOUR PRIVATE KEY HERE
  private_key: "", // We advise you not to store the private key on the frontend
};

export const useWertCollectiblesCheckout = () => {
  const { data: wertKey } = useGetWertKey();
  const { collectible, collectiblesSelected, resetCollectiblesSelected } =
    useBuyCollectibleStore();
  const {
    setIsMinting,
    setTxnHash,
    setPendingTxn,
    setPendingEvent,
    isWertPaymentPending,
    setIsWertPaymentPending,
    setError,
    total,
    setBoughtCollectibles,
    resetPending,
    resetTotal,
  } = useCheckoutStore();
  const { data: collectiblesManagerABI } = useGetCollectiblesManagerABI();
  const {
    setSheetModalContent,
    toggleSheetModal,
    closeSheetModal,
    setSheetModalBackdropStyle,
  } = useGlobalModal();
  const { loggedInUser } = useAuth();
  const userWallet = loggedInUser?.receiving_wallet;
  const {
    contract_address: contractAddress,
    edition_name,
    image,
  } = collectible || {};

  useEffect(() => {
    if (!wertKey) return;

    // eslint-disable-next-line no-undef
    const key = Buffer.from(wertKey, "base64").toString("utf8");

    smartContractOptions.private_key = key;
  }, [wertKey]);

  const handleStatus = async (payment) => {
    const { tx_id, status } = payment || {};

    if (status === "pending") {
      console.log("Pending");

      const checkout = JSON.parse(localStorage.getItem("checkout"));

      if (!checkout?.state?.isWertPaymentPending && !isWertPaymentPending) {
        console.log("Payment is pending");
        setSheetModalBackdropStyle({});
        setSheetModalContent(<ProcessingWertModal />);
        toggleSheetModal();
        setIsWertPaymentPending(true);
      }

      if (!tx_id) return;

      console.log("Minting collectibles with transaction id:", tx_id);

      const boughtCollectibles = sortItems(collectiblesSelected);

      const stateUpdaters = {
        setTxnHash,
        setBoughtCollectibles,
        setIsMinting,
        setPendingTxn,
        setPendingEvent,
        setError,
      };

      // Process minting
      const mintingResult = await processCollectiblesMinting(
        {
          tx_id,
          userWallet,
          contractAddress,
          boughtCollectibles,
          loggedInUser,
        },
        stateUpdaters
      ).catch((error) => {
        console.error("Failed to process minting:", error);
        return { success: false };
      });

      if (mintingResult.success) {
        closeSheetModal();

        setSheetModalBackdropStyle({});
        await addDelayInMs(1000);

        setSheetModalContent(<CollectiblePaymentSuccess />);
        toggleSheetModal();
        return;
      }

      // Proccess Minting if ever it wasnt added successful in pending state
      if (status === "success") {
        const mintingResult = await processCollectiblesMinting(
          {
            tx_id,
            userWallet,
            contractAddress,
            boughtCollectibles,
            loggedInUser,
          },
          stateUpdaters
        ).catch((error) => {
          console.error("Failed to process minting:", error);
          return { success: false };
        });

        if (mintingResult.success) {
          closeSheetModal();
          setSheetModalBackdropStyle({});
          await addDelayInMs(1000);

          setSheetModalContent(<CollectiblePaymentSuccess />);
          toggleSheetModal();
        }
      }
    }
  };

  const handleClose = () => {
    setIsMinting(false);
    setSheetModalBackdropStyle({});

    console.log("Wert closed.");

    setIsWertPaymentPending(false);
    resetCollectiblesSelected();
    resetTotal();
  };

  const handleError = async (error) => {
    console.error("error", error);

    setIsMinting(false);
    setIsWertPaymentPending(false);
    setError("Something went wrong. Please try again later.");
  };

  const { open: openWertWidget } = useWertWidget({
    theme: "dark",
    listeners: {
      loaded: () => console.log("loaded"),
      "payment-status": (payment) => handleStatus(payment),
      error: handleError,
      close: () => handleClose(),
    },
  });

  const buyWithCard = async () => {
    if (
      !smartContractOptions.private_key ||
      !options.partner_id ||
      !userWallet ||
      !contractAddress
    )
      return;

    try {
      setIsMinting(true);

      // Input Data
      const inputData = encodeFunctionData({
        abi: collectiblesManagerABI,
        functionName: "ERC1155Mint",
        args: [
          contractAddress, // Collectibles Contract
          isProd ? USDC_ADDRESS : WERT_TEST_TOKEN, // USDC or Wert Test Token
          userWallet, // Wallet Address of User
          collectiblesSelected.length, // Selected Collectibles
          1,
        ],
      });

      // Set input data
      smartContractOptions.sc_input_data = inputData;

      // Set collectible name
      options.extra.item_info.name = edition_name;

      // Set collectible image
      options.extra.item_info.image_url = image;

      // Set address to user's wallet
      smartContractOptions.address = userWallet;

      // Set amount of commodities
      smartContractOptions.commodity_amount = total;

      openWertWidget({ options, smartContractOptions });
      setBoughtCollectibles([]);
      setTxnHash(null);
      resetPending();
      toggleSheetModal();
    } catch (error) {
      console.error(error);
      setIsMinting(false);
      setError(error);
    }
  };

  return { buyWithCard };
};
