import styled from "styled-components";

export const TicketContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 8px 16px;
  width: 100%;
  background: ${({ theme, glass }) =>
    glass
      ? `linear-gradient(
    122.5deg,
    rgba(180, 180, 180, 0.2) -11.54%,
    rgba(79, 79, 79, 0.2) -11.53%,
    rgba(255, 255, 255, 0.2) 24.12%,
    rgba(175, 175, 175, 0.2) 97.15%
  );`
      : theme.colors.blue};
  border-radius: 10px;
  gap: 8px;
`;
