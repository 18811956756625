import PropTypes from "prop-types";
import Bookmark from "../../../assets/svg/bookmark.svg?react";
import Polaroids from "../../../assets/svg/polaroids.svg?react";
import Collectibles from "../../../assets/svg/collectibles.svg?react";
import ShoppingBag from "../../../assets/svg/shopping-bag.svg?react";
import EventsTab from "../../Tabs/EventsTab";
import MomentsTab from "../../Tabs/MomentsTab";
import Tabs from "../../Tabs";
import EmptyState from "../../EmptyState";
import ArtistCollectiblesTab from "../../Tabs/ArtistCollectiblesTab";

export default function ArtistTabs({
  events,
  moments,
  collectibles,
  isEventsLoading,
  isMomentsLoading,
  isCollectiblesLoading,
}) {
  const tabs = [
    {
      id: "artists",
      icon: <Bookmark height="16px" stroke="white" />,
      component: <EventsTab events={events} isLoading={isEventsLoading} />,
    },
    {
      id: "photos",
      icon: <Polaroids height="16px" stroke="white" />,
      component: <MomentsTab moments={moments} isLoading={isMomentsLoading} />,
    },
    {
      id: "collectibles",
      icon: <Collectibles height="16px" stroke="white" />,
      component: (
        <ArtistCollectiblesTab
          collectiblesData={collectibles}
          isLoading={isCollectiblesLoading}
        />
      ),
    },
    {
      id: "merch",
      icon: <ShoppingBag height="16px" stroke="white" />,
      component: (
        <EmptyState
          icon={
            <ShoppingBag height={24} width={24} stroke="white" opacity={0.6} />
          }
          title="Coming Soon"
          description="Buy merch directly from the artist!"
          style={{
            paddingTop: "15%",
          }}
        />
      ),
    },
  ];

  return <Tabs tabs={tabs} type="artist" />;
}

ArtistTabs.propTypes = {
  events: PropTypes.array,
  moments: PropTypes.array,
  collectibles: PropTypes.object,
  isEventsLoading: PropTypes.bool,
  isMomentsLoading: PropTypes.bool,
  isCollectiblesLoading: PropTypes.bool,
};
