import PropTypes from "prop-types";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import EmptyState from "../../EmptyState";
import ScanIcon from "../../../assets/svg/scan.svg?react";
import ScanItem from "../ScanItem";
import { MintsContainer } from "../../BackstageManualCheckin/Mints/Mints-Styles";

export default function Scans({ data, isLoading }) {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const status = searchParams.get("status");

  const scansToShow = useMemo(() => {
    if (!type && !status) return data;

    if (type && !status) {
      return data?.filter((scan) => {
        return (
          scan?.tickets_contracts_mints?.type?.toLowerCase() ===
          type?.toLowerCase()
        );
      });
    }

    if (!type && status) {
      return data?.filter((scan) => {
        return scan?.status?.toLowerCase() === status?.toLowerCase();
      });
    }

    if (type && status) {
      return data?.filter((scan) => {
        return (
          scan?.tickets_contracts_mints?.type?.toLowerCase() ===
            type?.toLowerCase() &&
          scan?.status?.toLowerCase() === status?.toLowerCase()
        );
      });
    }
  }, [data, type, status]);

  if (scansToShow?.length === 0) {
    return (
      <EmptyState
        icon={<ScanIcon height={24} width={24} stroke="white" />}
        title={!type ? "No Scans Yet" : "No Scans Found"}
        style={{
          paddingTop: "50%",
        }}
      />
    );
  }

  return (
    <MintsContainer
      style={{
        paddingTop: "0px",
      }}
    >
      {isLoading &&
        [...Array(12)].map((_, index) => <ScanItem key={index} isLoading />)}
      {scansToShow?.length > 0 &&
        scansToShow.map((scan) => <ScanItem scan={scan} key={scan.id} />)}
    </MintsContainer>
  );
}

Scans.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};
