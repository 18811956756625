import { Container } from "./CollectibleThumbnail-Styles";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import CollectibleThumbnailHeader from "./CollectibleThumbnailHeader";
import CollectibleThumbnailImage from "./CollectibleThumbnailImage";
import CollectibleThumbnailDetails from "./CollectibleThumbnailDetails";
import CollectibleThumbnailVideo from "./CollectibleThumbnailVideo";

export default function CollectibleThumbnail({
  collectible,
  isExternal,
  notLink,
}) {
  const {
    id,
    image,
    video,
    edition_type = "airdrop",
    user_ids,
    user_id,
    contract_address,
    collectible_type,
    token_id,
    price,
  } = collectible || {};
  const location = useLocation();
  const isPaid = price >= 0;

  const url = isExternal
    ? `collectibles/${contract_address}/${token_id}`
    : isPaid
      ? `/collectibles/${id}`
      : location.pathname?.includes("profile")
        ? `collectibles/${id}?type=${collectible_type || edition_type || "airdrop"}`
        : `/profile/${user_id ?? user_ids?.[0]}/collectibles/${id}?type=${collectible_type || edition_type || "airdrop"}`;

  return (
    <Container as={notLink ? "div" : Link} to={url}>
      <CollectibleThumbnailHeader
        collectible={collectible}
        isExternal={isExternal}
        notLink={notLink}
      />
      {video ? (
        <CollectibleThumbnailVideo video={video} />
      ) : (
        <CollectibleThumbnailImage image={image} />
      )}
      <CollectibleThumbnailDetails
        collectible={collectible}
        isExternal={isExternal}
        notLink={notLink}
      />
    </Container>
  );
}

CollectibleThumbnail.propTypes = {
  collectible: PropTypes.object,
  isExternal: PropTypes.bool,
  notLink: PropTypes.bool,
};
