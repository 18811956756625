import { ButtonRectSm } from "../../../GlobalStyles";
import PropTypes from "prop-types";
import { useBackstageSearchStore } from "../../../routes/BackstageSearch/stores/backstageSearchStore";

export default function BackstageFilterButton({ type }) {
  const { type: selectedType, setType } = useBackstageSearchStore();

  const handleClick = () => {
    setType(type);
  };

  return (
    <ButtonRectSm
      style={{
        textTransform: "capitalize",
      }}
      primary={selectedType === type}
      onClick={handleClick}
    >
      {type === "future" ? "upcoming" : type}
    </ButtonRectSm>
  );
}

BackstageFilterButton.propTypes = {
  type: PropTypes.string,
};
