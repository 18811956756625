import styled from "styled-components";

export const MintsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  flex: 1;
  width: 100%;
  padding: 0px 20px 12px;
`;
