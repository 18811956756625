import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  aspect-ratio: 3/4;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  gap: 8px;
  background: #ffffff14;
  border-radius: 10px 10px 0 0;
`;

export const ImageContainer = styled.div`
  flex: 1;
  position: relative;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  min-height: 160px;
  height: 160px;
  max-height: 160px;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 8px;
  background: #ffffff14;
  border-radius: 0 0 10px 10px;
`;

export const PaidDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  gap: 8px;
`;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.greyBtn};
  gap: 4px;
  padding: 4px 8px;
  border-radius: 8px;
`;
