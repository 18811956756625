import { HeaderL } from "../../GlobalStyles";
import { Container, HeaderContainer } from "./Checkout-Styles";
import PaymentMethods from "./PaymentMethods";
import { useCheckoutStore } from "./checkoutStore";
import OrderSummary from "./OrderSummary";
import TotalSummary from "./TotalSummary";
import { PAYMENT_METHODS } from "../../constants/constants";
import { useEffect } from "react";
import { useBuyTicketStore } from "../BuyTicket/buyTicketStore";
import CheckoutButton from "./CheckoutButton";
import { useBuyCollectibleStore } from "../../routes/PaidCollectible/stores/buyCollectibleStore";

export default function Checkout() {
  const { ticketsSelected, isOpen: ticketsOpen } = useBuyTicketStore();
  const { collectiblesSelected, isOpen: collectiblesOpen } =
    useBuyCollectibleStore();
  const { paymentMethod, setPaymentMethod, total, isMinting, setError } =
    useCheckoutStore();

  const free =
    total === 0 &&
    (ticketsOpen
      ? ticketsSelected?.length > 0
      : collectiblesOpen
        ? collectiblesSelected?.length > 0
        : false);

  useEffect(() => {
    setError(null);

    if (free) {
      setPaymentMethod(null);
    }

    if (!paymentMethod && !free) {
      setPaymentMethod(PAYMENT_METHODS.CREDIT_CARD);
    }
  }, []);

  return (
    <Container
      style={{
        padding: isMinting ? 24 : "0 24px 24px",
      }}
    >
      <HeaderContainer>
        <HeaderL style={{ textTransform: "uppercase" }}>Checkout</HeaderL>
        <OrderSummary />
      </HeaderContainer>
      {!free && <PaymentMethods />}
      <TotalSummary />
      <CheckoutButton />
    </Container>
  );
}
