import PropTypes from "prop-types";
import { ButtonRect, FooterText } from "../../../GlobalStyles";
import { Container, TextContainer } from "./BackstageEventCheckin-Styles";
import Checkbox from "../../../assets/svg/checkbox.svg?react";
import { useNavigate } from "react-router-dom";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export default function BackstageEventCheckin({ eventId, date, isLoading }) {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/backstage/events/${eventId}/manual-checkin?date=${date}`);
  };

  if (isLoading) {
    return (
      <Container>
        <SkeletonElement height="32px" width="180px" radius="10px" />
        <TextContainer>
          <SkeletonElement
            height="14px"
            width="200px"
            style={{ marginBottom: "8px" }}
          />
          <SkeletonElement height="14px" width="200px" />
        </TextContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ButtonRect primary onClick={handleOnClick}>
        <Checkbox height={16} width={16} stroke="white" />
        Manual check in
      </ButtonRect>
      <TextContainer>
        <FooterText secondary>
          Let fans check in without scanning QR codes.
        </FooterText>
        <br />
        <FooterText secondary>
          You can check their ticket no., username, etc!
        </FooterText>
      </TextContainer>
    </Container>
  );
}

BackstageEventCheckin.propTypes = {
  eventId: PropTypes.any,
  date: PropTypes.string,
  isLoading: PropTypes.bool,
};
