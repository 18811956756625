import PropTypes from "prop-types";
import TopNavV2 from "../../TopNavV2";
import { Container, FiltersContainer } from "./SelectTicketFilter-Styles";
import { useTicketFiltersStore } from "../../../routes/BackstageEvent/stores/ticketFiltersStore";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import TicketFilter from "../TicketFilter";

export default function SelectTicketFilter({ data }) {
  const { setIsOpen, filterType } = useTicketFiltersStore();
  const { pathname } = useLocation();

  const title = useMemo(() => {
    switch (filterType) {
      case "type":
        return "Select ticket type";
      case "status":
        return "Select ticket status";
      default:
        return "Select ticket filter";
    }
  }, [filterType]);

  const filters = useMemo(() => {
    if (!data || !data?.length) return [];

    switch (filterType) {
      case "type":
        return [
          ...new Set(
            data.map((scan) => {
              const type = pathname.includes("scan")
                ? scan?.tickets_contracts_mints?.type
                : scan?.type;
              return type;
            })
          ),
        ];
      case "status":
        return [
          "SUCCESS",
          ...new Set(
            data
              .map((scan) => scan?.status)
              .filter((status) => status !== "SUCCESS")
          ),
        ];
      default:
        return [
          ...new Set(
            data.map((scan) =>
              pathname.includes("scan")
                ? scan?.tickets_contracts_mints?.type
                : scan?.type
            )
          ),
        ];
    }
  }, [data, filterType, pathname]);

  return (
    <Container>
      <TopNavV2
        title={title}
        onBack={() => setIsOpen(false)}
        isSticky
        isBlurred
      />
      <FiltersContainer>
        {filters?.map((type) => (
          <TicketFilter key={type} type={type} />
        ))}
      </FiltersContainer>
    </Container>
  );
}

SelectTicketFilter.propTypes = {
  data: PropTypes.array,
};
