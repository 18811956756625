import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBackstageEventMints,
  updateBackstageMintCheckinStatus,
} from "../../../api/backstage";

export const useBackstageEventMintsQuery = ({ eventId }) => {
  return useQuery({
    queryKey: ["backstage_event_mints", { eventId }],
    queryFn: () => {
      return getBackstageEventMints({ eventId });
    },
    enabled: !!eventId,
  });
};

export const useBackstageManualCheckinMutate = ({ eventId, mintId }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["manual-checkin"],
    mutationFn: async ({ isCheckedIn }) => {
      return updateBackstageMintCheckinStatus({ mintId, isCheckedIn });
    },
    onSettled: () => {
      // Refetch to ensure server state
      queryClient.invalidateQueries({
        queryKey: ["backstage_event_mints", { eventId }],
      });
    },
  });
};
