import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { BodyText, FooterText } from "../../../GlobalStyles";
import { TokenIdsContainer } from "./TicketTokens-Styles";
import { theme } from "../../../utils/theme";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import TicketToken from "../TicketToken";

export default function TicketTokens({ tickets, isLoading }) {
  // Display all events if showAllEvents is true, otherwise display the first 3 events
  const [displayedTickets, setDisplayedTickets] = useState();
  // Set initial state for showing all tickets
  const [showAllTickets, setShowAllTickets] = useState(false);
  const maxTickets = 3;

  const handleDisplayedTickets = () => {
    setShowAllTickets(!showAllTickets);

    if (!showAllTickets) {
      setDisplayedTickets(tickets);
    } else {
      setDisplayedTickets(tickets?.slice(0, maxTickets));
    }
  };

  // Update the displayed tickets when the data is fetched
  useEffect(() => {
    if (!tickets || tickets.length === 0 || isLoading) return;

    setDisplayedTickets(tickets?.slice(0, maxTickets));
  }, [tickets, isLoading]);

  if (isLoading) {
    return (
      <TokenIdsContainer>
        <SkeletonElement height="17px" width="100px" />
        {Array.from({ length: maxTickets }).map((_, index) => (
          <SkeletonElement
            key={index}
            height="48px"
            width="100%"
            radius="8px"
          />
        ))}
      </TokenIdsContainer>
    );
  }

  return (
    <TokenIdsContainer>
      <BodyText weight="600">Tickets</BodyText>
      {displayedTickets?.map((ticket) => (
        <TicketToken key={ticket?.id} ticket={ticket} />
      ))}
      {tickets?.length > maxTickets && (
        <FooterText
          color={theme.colors.grey}
          onClick={handleDisplayedTickets}
          style={{
            cursor: "pointer",
            alignSelf: "flex-end",
          }}
        >
          {showAllTickets ? "Show less" : "See more"}
        </FooterText>
      )}
    </TokenIdsContainer>
  );
}

TicketTokens.propTypes = {
  tickets: PropTypes.array,
  isLoading: PropTypes.bool,
};
