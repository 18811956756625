import { useParams } from "react-router-dom";
import { ButtonRect, Section } from "../../GlobalStyles";
import { useSearchParams } from "react-router-dom";
import { useBackstageEventQuery } from "./hooks/backstageEventQueries";
import EventHeader from "../../components/Event/EventHeader";
import TopNavV2 from "../../components/TopNavV2";
import EventInfo from "../../components/Event/EventInfo";
import { Content } from "./BackstageEvent-Styles";
import BackstageEventDetails from "../../components/BackstageEvent/BackstageEventDetails";
import { useEffect, useMemo } from "react";
import BackstageEventCheckin from "../../components/BackstageEvent/BackstageEventCheckin";
import { useNavigate } from "react-router-dom";
import ScanIcon from "../../assets/svg/scan.svg?react";
import { useTicketFiltersStore } from "./stores/ticketFiltersStore";

export default function BackstageEvent() {
  const { id } = useParams();
  const { reset } = useTicketFiltersStore();
  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");
  const navigate = useNavigate();

  const { data, isLoading } = useBackstageEventQuery({
    eventId: id,
    date,
  });

  useEffect(() => {
    reset();
  }, [reset]);

  const { event, mints, scanLogs, revenue } = data || {};
  const { headline_artist, event_name, image } = event || {};

  const ticketsScanned = useMemo(() => {
    return mints?.filter((mint) => {
      return !!mint?.is_scanned;
    });
  }, [mints]);

  const scansError = useMemo(() => {
    return scanLogs?.filter((scan) => {
      return scan?.status !== "SUCCESS";
    });
  }, [scanLogs]);

  const handleBack = () => {
    navigate("/backstage/events");
  };

  const handleHistoryClick = () => {
    navigate(`/backstage/events/${id}/scan-history?date=${date}`);
  };

  return (
    <Section>
      <TopNavV2
        title={headline_artist ?? event_name}
        content={
          !isLoading && (
            <ButtonRect onClick={handleHistoryClick}>
              <ScanIcon height={14} width={14} stroke="white" />
              Scan history
            </ButtonRect>
          )
        }
        icon={image}
        onBack={handleBack}
      />
      <EventHeader event={event} isLoading={isLoading} />
      <Content>
        <EventInfo event={event} isLoading={isLoading} />
        <BackstageEventDetails
          details={[
            {
              header: "Tickets scanned",
              content: ticketsScanned?.length ?? 0,
            },
            {
              header: "Scan errors",
              content: scansError?.length ?? 0,
            },
          ]}
          isLoading={isLoading}
        />
        <BackstageEventDetails
          details={[
            {
              header: "Tickets sold",
              content: mints?.length ?? 0,
            },
            {
              header: "Revenue",
              content: `$${revenue ?? 0}`,
            },
          ]}
          isLoading={isLoading}
        />
        <BackstageEventCheckin eventId={id} date={date} isLoading={isLoading} />
      </Content>
    </Section>
  );
}
