import { useEffect, useState } from "react";
import { ButtonRect, ButtonSm, Header, Subheader } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import {
  Container,
  FloatingFooter,
  HeaderContainer,
  RecordsContainer,
} from "./QrModal-Styles";
import PropTypes from "prop-types";
import useGlobalModal from "../../../hooks/useGlobalModal";
import TicketQRImage from "../../TicketPage/TicketQRImage";
import CloseIcon from "../../../assets/svg/close.svg?react";
import { refreshTicketKey } from "../../../api/ticketing";
import { generateCustomKey } from "../../../utils/utils";

const TIME_CONSTANT = 50;
export default function QrModal({ ticket }) {
  const { toggleCenterModal } = useGlobalModal();

  const [timeLeft, setTimeLeft] = useState(TIME_CONSTANT);
  const [qrdata, setQrdata] = useState(null);
  const [hasError, setHasError] = useState(false);

  async function createKey(ticket, pass) {
    if (hasError && !ticket && !pass) return;

    const randomKey = generateCustomKey();
    try {
      const result = await refreshTicketKey({
        mintId: ticket.id,
        key: randomKey,
      });
      if (!result?.success) {
        setHasError(true);
        return;
      }

      setQrdata({ k: randomKey, t: Date.now(), d: ticket.id });
      setHasError(false);
    } catch (error) {
      setHasError(true);
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 1) {
          return TIME_CONSTANT; // Reset the timer
        }
        return prev - 1; // Decrease time
      });
    }, 1000);

    // Create the interval to update count every second
    const intervalId = setInterval(() => {
      createKey(ticket).catch((err) => {
        setHasError(true);
      });
    }, 50000);

    createKey(ticket).catch((err) => {
      setHasError(true);
    });

    // Cleanup interval on component unmount
    return () => {
      clearInterval(timer);
      clearInterval(intervalId);
    };
  }, []); // Empty dependency array ensures this runs only once

  return (
    <Container>
      <HeaderContainer>
        <TicketQRImage data={qrdata} error={hasError} />
      </HeaderContainer>
      <RecordsContainer>
        {hasError ? (
          ""
        ) : qrdata ? (
          <Subheader style={{ textAlign: "center" }} weight="600">
            Ticket #{ticket.token_id}
          </Subheader>
        ) : (
          ""
        )}
        <Header style={{ textTransform: "uppercase", textAlign: "center" }}>
          {hasError ? (
            <>
              <div>oops!</div>
              <div>sorry, it failed...</div>
            </>
          ) : qrdata ? (
            <>
              <div>scan this code</div>
              <div>to enter the event!</div>
            </>
          ) : (
            ""
          )}
        </Header>
        {hasError ? (
          <ButtonRect
            primary
            style={{
              margin: "0 auto 0 auto",
              height: "32px",
            }}
            onClick={() => {
              setHasError(false);
              setTimeLeft(TIME_CONSTANT);
              createKey(ticket, true);
            }}
          >
            Try again
          </ButtonRect>
        ) : qrdata ? (
          <Subheader
            style={{ textAlign: "center" }}
            color={theme.colors.grey}
            weight="600"
          >
            {timeLeft}
          </Subheader>
        ) : (
          ""
        )}
      </RecordsContainer>
      <FloatingFooter>
        <ButtonSm primary onClick={() => toggleCenterModal()}>
          <CloseIcon width={12} height={12} stroke={theme.colors.white} />
        </ButtonSm>
      </FloatingFooter>
    </Container>
  );
}

QrModal.propTypes = {
  ticket: PropTypes.object,
};
