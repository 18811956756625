import PropTypes from "prop-types";
import EmptyState from "../../EmptyState";
import TicketTransferUserItem from "../TicketTransferUserItem";
import { Container } from "./TicketTransferUsers-Styles";
import Sad from "../../../assets/svg/sad.svg?react";

export default function TicketTransferUsers({ users, isLoading }) {
  if (isLoading) {
    return (
      <Container>
        {Array.from({ length: 10 }).map((_, index) => (
          <TicketTransferUserItem key={index} isLoading={isLoading} />
        ))}
      </Container>
    );
  }

  if (!users?.length && !isLoading) {
    return (
      <EmptyState
        icon={<Sad height={24} width={24} stroke="white" opacity={0.6} />}
        title="No Users Found"
        description="Try searching for a username or mobile number."
      />
    );
  }

  return (
    <Container>
      {users?.map((user) => (
        <TicketTransferUserItem
          key={user?.id}
          user={user}
          isLoading={isLoading}
        />
      ))}
    </Container>
  );
}

TicketTransferUsers.propTypes = {
  users: PropTypes.array,
  isLoading: PropTypes.bool,
};
