import PropTypes from "prop-types";
import { GlassmorphPlaceholder } from "../../../constants/constants";
import { Image, ImageContainer } from "./CollectibleThumbnail-Styles";
import { VideoLength } from "../../Moments/MomentThumbnailCard/MomentThumbnailCard-Styles";
import { FooterText } from "../../../GlobalStyles";

export default function CollectibleThumbnailVideo({ video }) {
  const { url, duration } = video || {};

  return (
    <ImageContainer>
      <Image
        as={"video"}
        src={`${url}#t=0.01`}
        alt="collectible"
        muted
        preload="metadata"
        style={{
          pointerEvents: "none",
        }}
        poster={GlassmorphPlaceholder}
        onError={(e) => (e.target.src = GlassmorphPlaceholder)}
      />
      <VideoLength
        style={{
          zIndex: "0",
        }}
      >
        <FooterText>{duration}</FooterText>
      </VideoLength>
    </ImageContainer>
  );
}

CollectibleThumbnailVideo.propTypes = {
  video: PropTypes.object,
};
