import { Container, TicketContainer } from "./TransferTicketSuccess-Styles";
import Checkbox from "../../../assets/svg/checkbox.svg?react";
import {
  BodyText,
  ButtonRect,
  HeaderL,
  IconWithText,
} from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { useTicketTransferStore } from "../../../routes/TicketTransfer/stores/ticketTransferStore";
import TicketItem from "../../Tickets/TicketItem";
import { UserImage } from "../TransferTicketModal/TransferTicketModal-Styles";
import { AvatarPlaceholder } from "../../../constants/constants";
import { useMemo } from "react";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { setDelay } from "../../../utils/utils";

export default function TransferTicketSuccessModal() {
  const { loggedInUser } = useAuth();
  const { ticket, selectedUsers, reset } = useTicketTransferStore();
  const { toggleSheetModal } = useGlobalModal();
  const navigate = useNavigate();

  const selectedUser = useMemo(
    () => (selectedUsers?.length ? selectedUsers[0] : {}),
    [selectedUsers]
  );

  const handleClick = async () => {
    toggleSheetModal();
    navigate(`/profile/${loggedInUser?.id}`);

    await setDelay(500);
    reset();
  };

  return (
    <Container>
      <Checkbox height={32} width={32} stroke={theme.colors.green} />
      <HeaderL
        style={{
          padding: "16px 0 12px",
          textTransform: "uppercase",
        }}
      >
        Congratulations!
      </HeaderL>
      <BodyText secondary>
        You have successfully transferred your ticket!
      </BodyText>
      <TicketContainer>
        <TicketItem ticket={ticket} />
      </TicketContainer>
      <IconWithText
        style={{
          paddingBottom: 24,
        }}
      >
        <BodyText secondary>to</BodyText>
        <UserImage
          src={selectedUser?.avatar || AvatarPlaceholder}
          onError={(e) => (e.target.src = AvatarPlaceholder)}
        />
        <BodyText>{selectedUser?.username}</BodyText>
      </IconWithText>
      <ButtonRect primary onClick={handleClick}>
        Cool!
      </ButtonRect>
    </Container>
  );
}
