import styled from "styled-components";
import { BottomNavBarHeight } from "../../constants/constants";

export const Container = styled.div`
  height: calc(100dvh - ${BottomNavBarHeight});
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.backgroundOverlay};
  background-color: ${({ theme }) => theme.colors.background};
  background-blend-mode: multiply;
  z-index: 998;
  overflow-y: scroll;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background: #11111fe5;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 16px 20px 0;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MintsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  flex: 1;
  width: 100%;
  padding: 12px 20px;
`;
