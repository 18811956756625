import { BodyText, Title } from "../../../GlobalStyles";
import { Container, HeaderContainer } from "./ProcessingWertModal-Styles";
import TwoTickets from "../../../assets/svg/two-tickets.svg?react";
import { theme } from "../../../utils/theme";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";

export default function ProcessingWertModal() {
  const { isOpen } = useBuyTicketStore();

  return (
    <Container>
      <HeaderContainer>
        <TwoTickets height={24} width={24} stroke={theme.colors.green} />
        <Title>Processing</Title>
      </HeaderContainer>
      <BodyText secondary>
        We’re working hard to process your {isOpen ? "tickets" : "collectibles"}
        .
      </BodyText>
      <BodyText secondary>
        This may take a few minutes. A success modal will appear once the order
        is completed. Please do not close the app.
      </BodyText>
    </Container>
  );
}
