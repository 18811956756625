import PropTypes from "prop-types";
import CollectiblesIcon from "../../../assets/svg/collectibles.svg?react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import EmptyState from "../../EmptyState";
import { theme } from "../../../utils/theme";
import CollectibleThumbnail from "../../Collectibles/CollectibleThumbnail";
import {
  Container,
  UserCollectiblesContainer,
} from "./UserCollectibles-Styles";
import { BodyText, FooterText } from "../../../GlobalStyles";
import { useUserStore } from "../../../routes/Profile/store/userStore";
import useAuth from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import {
  useExternalMints,
  useExternalNFTs,
  useUserWallets,
} from "../../../routes/Profile/hooks/profileQueries";

export default function UserCollectibles({
  collectibles,
  isCollectiblesLoading,
}) {
  // Whether or not the user has any events to render.
  const noCollectibles =
    !collectibles || collectibles.length == 0 || collectibles[0] == null;
  const { id: userId } = useParams();
  const { loggedInUser } = useAuth();
  const isOwnProfile = loggedInUser?.id ? loggedInUser?.id == userId : false;

  const { externalMints } = useUserStore();

  const { data: wallets, isLoading: isWalletsLoading } = useUserWallets({
    userId: isOwnProfile ? null : userId,
  });

  const externalNFTsQuery = useExternalNFTs();

  const { data: externalMintsOther, isLoading: isExternalMintsLoading } =
    useExternalMints({
      externalNFTs: externalNFTsQuery?.data,
      enabled:
        !externalNFTsQuery.isLoading &&
        !isOwnProfile &&
        wallets &&
        !isWalletsLoading,
      wallets: wallets?.data,
      userId: userId,
      isOwnProfile: false,
    });

  if (isCollectiblesLoading || isExternalMintsLoading) {
    return (
      <Container>
        <BodyText weight="600">Your collectibles</BodyText>
        <UserCollectiblesContainer>
          {Array.from({ length: 12 }).map((_, index) => (
            <SkeletonElement
              key={index}
              width="100%"
              height="180px"
              radius="10px"
            />
          ))}
        </UserCollectiblesContainer>
      </Container>
    );
  }

  // Render the empty tab if the user doesn't have any events.
  if (
    noCollectibles &&
    (externalMints?.length == 0 || !externalMints) &&
    (externalMintsOther?.length == 0 || !externalMintsOther)
  ) {
    return (
      <EmptyState
        icon={
          <CollectiblesIcon
            height={24}
            width={24}
            stroke="white"
            opacity={0.6}
          />
        }
        title="Coming Soon"
        description="Your digital collectibles will appear here!"
      />
    );
  }

  // Render the list of events if the user has events.
  return (
    <Container>
      <BodyText weight="600">
        Your collectibles
        <FooterText color={theme.colors.grey} style={{ marginLeft: "8px" }}>
          (
          {collectibles?.length +
            (isOwnProfile ? externalMints?.length : externalMintsOther?.length)}
          )
        </FooterText>
      </BodyText>
      <UserCollectiblesContainer>
        {collectibles.map((collectible) => (
          <CollectibleThumbnail
            key={`${collectible?.id}${collectible?.edition_type}`}
            collectible={collectible}
          />
        ))}
        {isOwnProfile
          ? externalMints.map((collectible) => (
              <CollectibleThumbnail
                key={
                  collectible?.contract_address + "/" + collectible?.token_id
                }
                collectible={collectible}
                isExternal={true}
              />
            ))
          : externalMintsOther.map((collectible) => (
              <CollectibleThumbnail
                key={
                  collectible?.contract_address + "/" + collectible?.token_id
                }
                collectible={collectible}
                isExternal={true}
              />
            ))}
      </UserCollectiblesContainer>
    </Container>
  );
}

UserCollectibles.propTypes = {
  user: PropTypes.object,
  collectibles: PropTypes.array,
  isCollectiblesLoading: PropTypes.bool,
};
