import { TICKET_SOURCES } from "../constants/constants";

const { SKIDDLE, GIGANTIC, WEGOTTICKETS } = TICKET_SOURCES;

export const addSkiddleAffiliate = (url) => {
  const trimmedUrl = url.split("?")[0];

  const newUrl = `${trimmedUrl}${SKIDDLE.SUFFIX}`;

  return newUrl;
};

export const addGiganticAffiliate = (url) => {
  const trimmedUrl = url.split("?")[0];

  const newUrl = `${trimmedUrl}${GIGANTIC.SUFFIX}`;

  return newUrl;
};

export const addWeGotTicketsAffiliate = (url) => {
  const regex = /\/af\/\d+\//;

  return url.replace(regex, WEGOTTICKETS.SUFFIX);
};

export const generateTicketAffiliate = ({ url, type }) => {
  if (!type) return url;

  switch (type?.toLowerCase()) {
    case SKIDDLE.NAME.toLowerCase():
      return addSkiddleAffiliate(url);
    case GIGANTIC.NAME.toLowerCase():
      return addGiganticAffiliate(url);
    case WEGOTTICKETS.NAME.toLowerCase():
      return addWeGotTicketsAffiliate(url);
    default:
      return url;
  }
};
