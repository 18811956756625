import { formatUnits, parseUnits } from "viem";
import {
  deployMetadata,
  getMintByTxn,
  getTokensMinted,
  insertTicketsMinted,
} from "../api/ticketing";
import moment from "moment";
import {
  deployCollectibleMetadata,
  getCollectibleTokensMinted,
  getMintedCollectiblesById,
  getMintedCollectiblesByTxn,
  insertCollectiblesMinted,
} from "../api/collectibles";

export const calculateTotal = ({ ticketsTotal, momentifyFee, gas }) => {
  // Convert values to smallest unit (6 decimals for USDC)
  const ticketsTotalInSmallestUnit = parseUnits(ticketsTotal.toString(), 6); // Returns BigInt
  const mintFeeInSmallestUnit = parseUnits(momentifyFee.toString(), 6); // Returns BigInt
  const gasInSmallestUnit = parseUnits(gas.toString(), 6); // Returns BigInt

  // Calculate the total amount required (prices + mint fees + gas)
  const totalAmount =
    ticketsTotalInSmallestUnit + mintFeeInSmallestUnit + gasInSmallestUnit;

  return {
    formattedTotal: formatUnits(totalAmount, 6),
    parsedTotal: totalAmount,
  };
};

export const countTicketsByType = (tickets) => {
  return tickets.reduce(
    (acc, ticket) => {
      const typeKey = ticket.type === "General admission" ? "GA" : ticket.type;
      acc[typeKey] = (acc[typeKey] || 0) + 1;
      return acc;
    },
    { VIP: 0, GA: 0 }
  );
};

export const sortItems = (items) => {
  return items.sort((a, b) => a.type.localeCompare(b.type));
};

export const countAndDeduplicateTickets = (tickets) => {
  // Sort the tickets by type
  const sortedTickets = sortItems(tickets);

  // Create a map to store the count for each ticket by its type
  const ticketMap = sortedTickets.reduce((acc, ticket) => {
    const typeKey = ticket.type;

    if (!acc[typeKey]) {
      // Add a new entry with the ticket object and count 1
      acc[typeKey] = { ...ticket, type: typeKey, count: 1 };
    } else {
      // Increment the count if the ticket type already exists
      acc[typeKey].count += 1;
    }

    return acc;
  }, {});

  // Return an array of unique ticket objects with the count added
  return Object.values(ticketMap);
};

export const countAndDeduplicateCollectibles = (collectibles) => {
  // Sort the tickets by type
  const sortedTickets = sortItems(collectibles);

  // Create a map to store the count for each ticket by its type
  const collectibleMap = sortedTickets.reduce((acc, collectible) => {
    const typeKey = collectible.edition_name;

    if (!acc[typeKey]) {
      // Add a new entry with the ticket object and count 1
      acc[typeKey] = { ...collectible, type: typeKey, count: 1 };
    } else {
      // Increment the count if the ticket type already exists
      acc[typeKey].count += 1;
    }

    return acc;
  }, {});

  // Return an array of unique ticket objects with the count added
  return Object.values(collectibleMap);
};

export const addDelayInMs = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const checkTokensMinted = async (wallet, contractAddress, tx_id) => {
  let attempt = 0;
  let tokens = [];

  while (attempt < 3 && !tokens.length) {
    attempt++;

    console.log("Getting minted tokens on Attempt:", attempt);

    try {
      const { data, success } = (await getTokensMinted({
        owner: wallet,
        contractAddress: contractAddress,
        txn: tx_id,
      })) || { data: [], success: false };

      tokens = data;

      if (success && data?.length > 0) {
        console.log("Tokens minted", data);
        return { data, success };
      }
    } catch (error) {
      console.error(
        "Failed to get minted tokens on attempt",
        attempt,
        ":",
        error
      );
    }

    if (attempt === 3) {
      console.error("Failed to get minted tokens after 3 attempts");
      return {
        success: false,
        error: "Failed to get minted tokens after 3 attempts",
      };
    }

    await addDelayInMs(2000);
  }
};
export const checkCollectiblesTokensMinted = async (
  wallet,
  contractAddress,
  tx_id
) => {
  let attempt = 0;
  let tokens = [];

  while (attempt < 3 && !tokens.length) {
    attempt++;

    console.log("Getting minted tokens on Attempt:", attempt);

    try {
      const { data, success } = (await getCollectibleTokensMinted({
        owner: wallet,
        contractAddress: contractAddress,
        txn: tx_id,
      })) || { data: [], success: false };

      tokens = data;

      if (success && data?.length > 0) {
        console.log("Tokens minted", data);
        return { data, success };
      }
    } catch (error) {
      console.error(
        "Failed to get minted tokens on attempt",
        attempt,
        ":",
        error
      );
    }

    if (attempt === 3) {
      console.error("Failed to get minted tokens after 3 attempts");
      return {
        success: false,
        error: "Failed to get minted tokens after 3 attempts",
      };
    }

    await addDelayInMs(2000);
  }
};

export const processTicketMinting = async (params, stateUpdaters) => {
  const { tx_id, userWallet, contractAddress, boughtTickets, loggedInUser } =
    params;

  const { setTxnHash, setBoughtTickets, setIsMinting, setError } =
    stateUpdaters;

  try {
    const { mintedTickets } = (await getMintByTxn({ txn: tx_id })) || {};
    if (mintedTickets?.length > 0) {
      return { success: false, alreadyMinted: true };
    }

    await addDelayInMs(1000);

    const { data: batchMintData, success: batchMintSuccess } =
      await checkTokensMinted(userWallet, contractAddress, tx_id);

    if (!batchMintSuccess) {
      setError("Failed to get tickets minted");
      throw new Error("Failed to get tickets minted");
    }

    const { success, data } =
      (await insertTicketsMinted({
        tickets: boughtTickets,
        userId: loggedInUser?.id,
        userWallet: userWallet,
        txnHash: tx_id,
        mints: batchMintData,
      })) || {};

    if (!success || !data?.length) {
      setError("Failed to insert tickets minted");
      throw new Error("Failed to insert tickets minted");
    }

    // Set initial states concurrently
    await Promise.all([setTxnHash(tx_id), setBoughtTickets(boughtTickets)]);

    console.log("Tickets Minted Inserted to DB");

    await clearPendingState(stateUpdaters);

    return { success: true, data };
  } catch (error) {
    console.error("Error in ticket minting process:", error);
    setError(`Error in ticket minting process: ${error?.message || error}`);
    setIsMinting(false);
    return {
      success: false,
      error: error.message || "Unknown error occurred",
    };
  } finally {
    setIsMinting(false);
  }
};

export const processCollectiblesMinting = async (params, stateUpdaters) => {
  const {
    tx_id,
    userWallet,
    contractAddress,
    boughtCollectibles,
    loggedInUser,
  } = params;

  const { setTxnHash, setBoughtCollectibles, setIsMinting, setError } =
    stateUpdaters;

  try {
    const { mintedCollectibles } =
      (await getMintedCollectiblesByTxn({ txn: tx_id })) || {};
    if (mintedCollectibles?.length > 0) {
      return { success: false, alreadyMinted: true };
    }

    await addDelayInMs(1000);

    const { data: batchMintData, success: batchMintSuccess } =
      await checkCollectiblesTokensMinted(userWallet, contractAddress, tx_id);

    if (!batchMintSuccess) {
      setError("Failed to get collectibles minted");
      throw new Error("Failed to get collectibles minted");
    }

    console.log("Minted collectibles", batchMintData);

    const { success, data } =
      (await insertCollectiblesMinted({
        collectibles: boughtCollectibles,
        userId: loggedInUser?.id,
        txnHash: tx_id,
      })) || {};

    if (!success || !data?.length) {
      setError("Failed to insert collectibles minted");
      throw new Error("Failed to insert collectibles minted");
    }

    // Add ID of data to each bought collectible
    const newBoughtCollectibles = boughtCollectibles.map(
      (collectible, index) => {
        return { ...collectible, id: data?.[index].id };
      }
    );

    // Set initial states concurrently
    await Promise.all([
      setTxnHash(tx_id),
      setBoughtCollectibles(newBoughtCollectibles),
    ]);

    console.log("Collectibles Minted Inserted to DB", data);

    return { success: true, data: batchMintData };
  } catch (error) {
    console.error("Error in collectibles minting process:", error);
    setError(
      `Error in collectibles minting process: ${error?.message || error}`
    );
    setIsMinting(false);
    return {
      success: false,
      error: error.message || "Unknown error occurred",
    };
  } finally {
    setIsMinting(false);
  }
};

export const clearPendingState = async (stateUpdaters) => {
  const { setPendingBoughtTickets, setPendingTxn, setPendingEvent } =
    stateUpdaters;

  await Promise.all([
    setPendingBoughtTickets([]),
    setPendingTxn(null),
    setPendingEvent(null),
  ]);
};

export const setPendingState = async (
  event,
  boughtTickets,
  tx_id,
  stateUpdaters
) => {
  const { setPendingEvent, setPendingBoughtTickets, setPendingTxn } =
    stateUpdaters;

  await Promise.all([
    setPendingEvent(event),
    setPendingBoughtTickets(boughtTickets),
    setPendingTxn(tx_id),
  ]);
};

export const deployTicketingMetadata = async (tickets, metadata) => {
  const {
    contractAddress,
    event_name,
    symbol,
    image,
    headline_artist,
    headline_artist_id,
    event_date,
    event_id,
    venue,
    userId,
  } = metadata || {};

  const sortedTickets = sortItems(tickets);

  const res = await deployMetadata({
    metadata: {
      artistId: headline_artist_id,
      eventId: event_id,
      contractAddress: contractAddress,
      name: event_name,
      symbol: symbol,
      image: image,
      description: `Ticket for ${event_name} by ${headline_artist}`,
      attributes: sortedTickets.map(({ type }) => {
        return {
          data: [
            {
              trait_type: "Event name",
              value: event_name,
            },
            {
              trait_type: "Artist name",
              value: headline_artist,
            },
            {
              trait_type: "Venue name",
              value: venue,
            },
            {
              trait_type: "Date",
              value: moment(event_date).format("Do MMM YYYY"),
            },
            {
              trait_type: "Event start time",
              value: moment(event_date).format("HH:mm"),
            },
            {
              trait_type: "Ticket type",
              value: type,
            },
            {
              trait_type: "User id",
              value: userId,
            },
          ],
        };
      }),
    },
  })
    .then((res) => {
      console.log("Metadata deployed", res);
      return { success: true, data: res };
    })
    .catch((err) => {
      console.error("Failed to deploy metadata", err);
      return { success: false, error: err };
    });

  return res;
};

export const deployCollecitblesMetadata = async (metadata) => {
  const {
    id,
    contractAddress,
    symbol,
    image,
    edition_name,
    artist_id,
    description,
  } = metadata || {};

  console.log(metadata);

  const { mintedCollectibles } =
    (await getMintedCollectiblesById({ id })) || {};

  if (mintedCollectibles?.length > 0) {
    console.log("MetamintedCollectibles already deployed", mintedCollectibles);
    return {
      success: true,
      data: mintedCollectibles,
    };
  }

  const res = await deployCollectibleMetadata({
    metadata: {
      name: edition_name,
      artistId: artist_id,
      contractAddress: contractAddress,
      symbol,
      image,
      description,
      attributes: [{ trait_type: "Type", value: "Paid Edition" }],
    },
  })
    .then((res) => {
      console.log("Metadata deployed", res);
      return { success: true, data: res };
    })
    .catch((err) => {
      console.error("Failed to deploy metadata", err);
      return { success: false, error: err };
    });

  return res;
};

export const checkPrerequisites = ({
  loggedInUser,
  primaryWallet,
  receivingWallet,
  setError,
}) => {
  if (!loggedInUser || !loggedInUser.id) {
    setError("You must be logged in to buy tickets.");
    return false;
  }

  if (!primaryWallet) {
    setError("Please connect your payment wallet.");
    return false;
  }

  if (!receivingWallet) {
    setError("Please select a receiving wallet.");
    return false;
  }

  return true;
};
