import { FooterText } from "../../../GlobalStyles";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";
import CollectibleItem from "../../Collectibles/CollectibleItem";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import CollectiblesStatus from "../CollectiblesStatus";
import {
  Container,
  ContentContainer,
  HeaderContainer,
} from "./CollectiblePurchase-Styles";
import CollectiblePurchaseButton from "./CollectiblePurchaseButton";
import PropTypes from "prop-types";

export default function CollectiblePurchase({ isLoading }) {
  const { collectible } = useBuyCollectibleStore();
  const { max_per_account } = collectible || {};

  if (isLoading) {
    return (
      <Container>
        <ContentContainer>
          <HeaderContainer>
            <SkeletonElement width="30%" height="17px" />
            <SkeletonElement width="30%" height="17px" />
          </HeaderContainer>
          <SkeletonElement height="88px" radius="10px" />
        </ContentContainer>
        <SkeletonElement height="37px" width="120px" radius="10px" />
      </Container>
    );
  }

  return (
    <Container>
      <ContentContainer>
        <HeaderContainer>
          <FooterText>Max of {max_per_account} per account</FooterText>
          <CollectiblesStatus />
        </HeaderContainer>
        <CollectibleItem />
      </ContentContainer>
      <CollectiblePurchaseButton />
    </Container>
  );
}

CollectiblePurchase.propTypes = {
  isLoading: PropTypes.bool,
};
