import { ButtonRectSm, FooterText } from "../../../GlobalStyles";
import { Container } from "./BackstageProfileStatus-Styles";
import useAuth from "../../../hooks/useAuth";
import { theme } from "../../../utils/theme";

const { colors, fontSizes } = theme;

export default function BackstageProfileStatus() {
  const { loggedInUser } = useAuth();
  const { user_role } = loggedInUser || {};

  return (
    <Container>
      <ButtonRectSm
        style={{
          background: colors.blue,
          border: "none",
          fontSize: fontSizes.mobile.footer,
          fontWeight: 400,
          gap: 3,
        }}
      >
        {"You're Backstage:"}
        <FooterText
          style={{
            textTransform: "capitalize",
          }}
        >
          {user_role}
        </FooterText>
        view
      </ButtonRectSm>
    </Container>
  );
}
