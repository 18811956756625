import styled from "styled-components";
import { ButtonRectSm } from "../../../GlobalStyles";

export const Button = styled(ButtonRectSm)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const IconWithText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
