import { useMemo } from "react";
import { BodyText, ButtonRect, HeaderL } from "../../../GlobalStyles";
import Checkbox from "../../../assets/svg/checkbox.svg?react";
import { useCheckoutStore } from "../checkoutStore";
import {
  Container,
  HeaderContainer,
  CollectiblesContainer,
} from "./CollectiblePaymentSuccess-Styles";
import { theme } from "../../../utils/theme";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { useTabsStore } from "../../Tabs/tabsStore";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";
import CollectibleThumbnail from "../../Collectibles/CollectibleThumbnail";
import { ThumbnailContainer } from "../../BuyCollectibles/BuyCollectibles-Styles";

export default function CollectiblePaymentSuccess() {
  const { loggedInUser } = useAuth();
  const { reset } = useBuyCollectibleStore();
  const {
    boughtCollectibles,
    reset: resetCheckout,
    resetPending,
  } = useCheckoutStore();
  const { toggleSheetModal } = useGlobalModal();
  const { setActiveTab } = useTabsStore();
  const navigate = useNavigate();

  const collectiblesBought = useMemo(() => {
    const checkout = localStorage.getItem("checkout");
    const collectibles =
      checkout && JSON.parse(checkout).state.boughtCollectibles;

    return boughtCollectibles || collectibles || [];
  }, [boughtCollectibles]);

  const handleConfirm = () => {
    toggleSheetModal();
    setActiveTab("collectibles");

    reset();
    resetCheckout();
    resetPending();
    navigate(`/profile/${loggedInUser?.id}`);
  };

  return (
    <Container>
      <HeaderContainer>
        <Checkbox stroke={theme.colors.green} />
        <HeaderL
          style={{ textTransform: "uppercase", margin: "16px 0 12px 0" }}
        >
          Congratulations!
        </HeaderL>
        <BodyText secondary>
          You successfully bought {collectiblesBought?.length}{" "}
          {collectiblesBought?.length <= 1 ? "collectible" : "collectibles"}.
        </BodyText>
      </HeaderContainer>
      {collectiblesBought?.length > 0 && (
        <CollectiblesContainer isOne={collectiblesBought.length === 1}>
          {collectiblesBought.map((collectible, index) => {
            return (
              <ThumbnailContainer key={index}>
                <CollectibleThumbnail
                  collectible={{ ...collectible, price: null }}
                  notLink
                />
              </ThumbnailContainer>
            );
          })}
        </CollectiblesContainer>
      )}
      <ButtonRect primary onClick={handleConfirm} style={{ marginTop: 8 }}>
        <BodyText weight="600">Confirm</BodyText>
      </ButtonRect>
    </Container>
  );
}
