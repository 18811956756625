import { useInfiniteQuery } from "@tanstack/react-query";
import {
  getBackstageEvents,
  searchBackstageEvents,
} from "../../../api/backstage";

export const useBackstageInfiniteSearch = ({
  query,
  type,
  country,
  city,
  year,
  month,
  artistIds,
  venueId,
  limit = 30,
}) => {
  return useInfiniteQuery({
    queryKey: [
      "search",
      {
        query,
        type,
        country,
        city,
        year,
        month,
        artistIds,
        venueId,
      },
    ],
    queryFn: async ({ pageParam = 0, signal }) => {
      if (
        (!query || query === "") &&
        !country &&
        !city &&
        !year &&
        !month &&
        !artistIds &&
        !venueId
      )
        return [];
      try {
        return performSearch({
          type,
          query,
          country,
          city,
          year,
          month,
          artistIds,
          venueId,
          signal,
          pageParam,
          limit,
        });
      } catch (err) {
        console.warn(err);
        throw new Error(err);
      }
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage && lastPage?.length < limit) {
        return undefined;
      }
      return (allPages?.length ?? 0) * limit;
    },
    // staleTime: Infinity,
    enabled:
      !!query ||
      !!type ||
      !!country ||
      !!city ||
      !!year ||
      !!month ||
      !!artistIds ||
      !venueId,
  });
};

export const performSearch = async ({
  query,
  country,
  city,
  year,
  month,
  artistIds,
  venueId,
  type,
  signal,
  pageParam,
  limit,
}) => {
  return (
    searchBackstageEvents({
      query,
      artistIds,
      venueId,
      country,
      city,
      year,
      month,
      type,
      signal,
      limit,
      pageParam,
    }) ?? []
  );
};

export const useBackstageEvents = ({
  artistIds,
  venueIds,
  type,
  limit = 30,
}) => {
  return useInfiniteQuery({
    queryKey: ["backstage-events", { artistIds, venueIds, type }],
    queryFn: async ({ pageParam = 0 }) => {
      return (
        getBackstageEvents({
          artistIds,
          venueIds,
          type,
          limit,
          offset: pageParam,
        }) ?? []
      );
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage && lastPage?.length < limit) {
        return undefined;
      }
      return (allPages?.length ?? 0) * limit;
    },
    enabled: !!artistIds,
  });
};
