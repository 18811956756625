import moment from "moment";

export const getBackstageAccess = async (userId) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/backstage/users/${userId}/access`
    );

    if (!response.ok) {
      throw new Error("Failed to get user");
    }

    const body = await response.json();

    if (!body.success) {
      throw new Error("Failed to get user");
    }

    return body?.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const searchBackstageEvents = async ({
  query,
  artistIds,
  venueId,
  country,
  city,
  year,
  month,
  type,
  signal,
  limit = 20,
  offset = 0,
}) => {
  try {
    // Fetch nearby events
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/backstage/search/events?limit=${limit}&offset=${offset}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          country,
          city,
          year,
          month,
          artistIds,
          venueId,
          type,
        }),
        signal,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get events");
    }

    // Parse response
    const body = await response.json();

    if (!body.success) {
      throw new Error("Failed to get events");
    }

    return body.data;
  } catch (err) {
    return [];
  }
};

export const getBackstageEvents = async ({
  artistIds,
  venueIds,
  type,
  limit = 20,
  offset = 0,
}) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/backstage/events?limit=${limit}&offset=${offset}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          artistIds,
          venueIds,
          type,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get events");
    }

    // Parse response
    const body = await response.json();

    if (!body.success) {
      throw new Error("Failed to get events");
    }

    return body.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getBackstageEvent = async ({ eventId, date }) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/backstage/events/${eventId}?date=${date}`
    );

    if (!response.ok) {
      throw new Error("Failed to get event");
    }

    // Parse response
    const body = await response.json();

    if (!body.success) {
      throw new Error("Failed to get event");
    }

    return body.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getBackstageEventMints = async ({ eventId }) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/backstage/events/${eventId}/mints`
    );

    if (!response.ok) {
      throw new Error("Failed to get event mints");
    }

    // Parse response
    const body = await response.json();

    if (!body.success) {
      throw new Error("Failed to get event mints");
    }

    return body.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const updateBackstageMintCheckinStatus = async ({
  mintId,
  isCheckedIn,
}) => {
  try {
    let date;
    if (!isCheckedIn) {
      const localTime = moment().local().format("YYYY-MM-DD HH:mm:ss");
      const userTimezone = moment.tz.guess();
      date = moment
        .tz(localTime, "YYYY-MM-DD HH:mm:ss", userTimezone)
        .format("YYYY-MM-DD HH:mm:ss");
    }

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/backstage/mints/${mintId}/manual-check-in`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ date: date || null }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update mint status");
    }

    // Parse response
    const body = await response.json();

    if (!body.success) {
      throw new Error("Failed to update mint status");
    }

    return body.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getBackstageEventScans = async ({ eventId }) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/backstage/events/${eventId}/scans`
    );

    if (!response.ok) {
      throw new Error("Failed to get event scans");
    }

    // Parse response
    const body = await response.json();

    if (!body.success) {
      throw new Error("Failed to get event scans");
    }

    return body.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
