import {
  BodyText,
  ButtonRect,
  HeaderL,
  IconWithText,
  Subheader,
} from "../../../GlobalStyles";

import Plane from "../../../assets/svg/plane.svg?react";
import Wallet from "../../../assets/svg/wallet.svg?react";
import {
  Container,
  Content,
  HeaderContainer,
  UserImage,
} from "./TransferTicketModal-Styles";
import { transferTicketMinted } from "../../../api/ticketing";
import useAuth from "../../../hooks/useAuth";
import { useTicketTransferStore } from "../../../routes/TicketTransfer/stores/ticketTransferStore";
import { useMemo, useState } from "react";
import { setDelay, trimAddress } from "../../../utils/utils";
import { AvatarPlaceholder } from "../../../constants/constants";
import useGlobalModal from "../../../hooks/useGlobalModal";
import TransferTicketSuccessModal from "../TransferTicketSuccessModal";
import { toast } from "react-toastify";
import { transferTicketInBlockchain } from "../../../utils/ticket-transfer";
import useAccountKit from "../../../hooks/useAccountKit";
import { useGetTicketManagerABI } from "../../Checkout/hooks/checkoutQueries";
import { useAlchemyQuery } from "../../../routes/Profile/hooks/profileQueries";

export default function TransferTicketModal() {
  const { loggedInUser } = useAuth();
  const { ticket, selectedUsers } = useTicketTransferStore();
  const [transferring, setTransferring] = useState(false);
  const { setSheetModalContent, toggleSheetModal } = useGlobalModal();
  const { alchemyProvider } = useAccountKit();
  const { data: ticketManagerABI } = useGetTicketManagerABI();

  const { isLoading } = useAlchemyQuery();

  const { contract_address, token_id, user_wallet } = ticket || {};

  const selectedUser = useMemo(
    () => (selectedUsers?.length ? selectedUsers[0] : {}),
    [selectedUsers]
  );

  const handleTransfer = async () => {
    if (!loggedInUser || !selectedUser || !ticket) return;

    if (loggedInUser?.id === selectedUser?.id) {
      return;
    }

    try {
      setTransferring(true);

      // TO BE ENABLED ONCE BLOCKCHAIN BE DONE
      const { success: transferSuccess } =
        (await transferTicketInBlockchain({
          alchemyProvider,
          abi: ticketManagerABI,
          args: [
            contract_address,
            user_wallet ?? loggedInUser?.receiving_wallet,
            selectedUser?.receiving_wallet,
            token_id,
          ],
        })) || {};

      if (!transferSuccess) {
        console.error("Failed to transfer ticket in blockchain");
        toast.error("Failed to transfer ticket in blockchain");
        toggleSheetModal();
        return;
      }

      const { success, error } =
        (await transferTicketMinted({
          sender: {
            id: loggedInUser?.id,
            wallet: user_wallet ?? loggedInUser?.receiving_wallet,
          },
          receiver: {
            id: selectedUser?.id,
            wallet: selectedUser?.receiving_wallet,
          },
          ticket: {
            id: ticket?.ticket_id,
            mintId: ticket?.id,
            contractId: ticket?.tickets_contract_id,
          },
        })) || {};

      if (!success) {
        console.error(error);
        toast.error(error);
        toggleSheetModal();
        return;
      }

      toggleSheetModal();
      await setDelay(1000);
      setSheetModalContent(<TransferTicketSuccessModal />);
      await setDelay(100);
      toggleSheetModal();
    } catch (error) {
      console.error(error);
    } finally {
      setTransferring(false);
    }
  };

  return (
    <Container>
      <Content>
        <HeaderContainer>
          <HeaderL
            style={{
              textTransform: "uppercase",
            }}
          >
            Transfer This Ticket To
          </HeaderL>
        </HeaderContainer>
        <IconWithText>
          <UserImage
            src={selectedUser?.avatar || AvatarPlaceholder}
            onError={(e) => (e.target.src = AvatarPlaceholder)}
          />
          <Subheader>{selectedUser?.username}</Subheader>
        </IconWithText>
        <IconWithText>
          <Wallet height={16} width={16} stroke="white" />
          <BodyText secondary>
            {trimAddress(selectedUser?.receiving_wallet)}
          </BodyText>
        </IconWithText>
      </Content>
      <ButtonRect
        green
        onClick={handleTransfer}
        disabled={transferring || isLoading}
      >
        <Plane height={16} width={16} stroke="black" strokeWidth={1.5} />
        {isLoading
          ? "Loading..."
          : transferring
            ? "Transferring..."
            : "Transfer"}
      </ButtonRect>
    </Container>
  );
}
