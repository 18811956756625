import { useFundWallet, usePrivy } from "@privy-io/react-auth";
import { PAYMENT_METHODS } from "../../../constants/constants";
import {
  BodyText,
  ButtonRectSm,
  FooterText,
  IconWithText,
  Line,
} from "../../../GlobalStyles";
import {
  usePrimaryWallet,
  usePrimaryWalletBalance,
} from "../../../hooks/usePrimaryWallet";
import { theme } from "../../../utils/theme";
import SelectButton from "../../Buttons/SelectButton";
import AnimatedDropdown from "../../Dropdown/AnimatedDropdown";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { useCheckoutStore } from "../checkoutStore";
import {
  BalanceContainer,
  ButtonWithMethod,
  Container,
  TopUpContainer,
} from "./PaymentMethod-Styles";
import PropTypes from "prop-types";
import USDCIcon from "../../../assets/svg/usdc.svg?react";
import WalletIcon from "../../../assets/svg/wallet.svg?react";
import SendMoneyIcon from "../../../assets/svg/send-money.svg?react";
import { base, baseSepolia } from "viem/chains";
import { useEffect, useState } from "react";
import { useChainId, useSwitchChain } from "wagmi";
import { isProd } from "../../../utils/utils";
import { Link } from "@phosphor-icons/react";

const { WALLET } = PAYMENT_METHODS;
const { colors } = theme;

export default function WalletPaymentMethod({ handlePaymentMethod }) {
  const { primaryWallet } = usePrimaryWallet();
  const { balance, isLoading } = usePrimaryWalletBalance();
  const {
    hasEnoughWalletBalance,
    paymentMethod,
    total,
    setHasEnoughWalletBalance,
  } = useCheckoutStore();
  const { fundWallet } = useFundWallet();
  const { connectWallet } = usePrivy();
  const chainId = useChainId();
  const { switchChain } = useSwitchChain();
  const [switchingChain, setSwitchingChain] = useState(false);
  const { address, connected } = primaryWallet || {};

  useEffect(() => {
    const balanceInt = Number(balance);

    if (balanceInt < total) setHasEnoughWalletBalance(false);
    else if (balanceInt >= total) setHasEnoughWalletBalance(true);
  }, [balance, total, setHasEnoughWalletBalance]);

  const handleDeposit = () => {
    fundWallet(address, {
      chain: base,
    });
  };

  const handleConnectWallet = () => {
    connectWallet({
      suggestedAddress: address,
    });
  };

  const handleSelectWallet = () => {
    if (isLoading) return;
    handlePaymentMethod(WALLET);
  };

  const handleSwitchChain = async () => {
    try {
      setSwitchingChain(true);
      switchChain({ chainId: isProd ? base.id : baseSepolia.id });
      primaryWallet?.switchChain &&
        (await primaryWallet?.switchChain(isProd ? base.id : baseSepolia.id));
    } catch (error) {
      console.error(error);
    } finally {
      setSwitchingChain(false);
    }
  };

  const isRed =
    !hasEnoughWalletBalance ||
    !connected ||
    (isProd ? chainId !== base.id : chainId !== baseSepolia.id);

  return (
    <AnimatedDropdown
      header={
        <Container
          style={{
            background: "none",
            padding: 0,
            opacity: isLoading ? 0.4 : 1,
          }}
          onClick={handleSelectWallet}
        >
          <ButtonWithMethod>
            <IconWithText>
              <SelectButton selected={paymentMethod === WALLET} />
              <WalletIcon stroke={colors.white} height={16} width={16} />
              <BodyText>Wallet</BodyText>
              {isLoading ? (
                <SkeletonElement height="17px" width="80px" />
              ) : (
                <BalanceContainer>
                  <FooterText secondary>Balance:</FooterText>
                  <BodyText
                    color={isRed ? colors.red : colors.grey}
                    lineHeight="normal"
                    bold
                  >
                    ${balance}
                  </BodyText>
                  <USDCIcon height={16} width={16} />
                </BalanceContainer>
              )}
            </IconWithText>
          </ButtonWithMethod>
        </Container>
      }
      content={
        !connected ? (
          <>
            <Line />
            <TopUpContainer>
              <FooterText color={colors.red}>Wallet not connected.</FooterText>
              <ButtonRectSm primary onClick={handleConnectWallet}>
                <FooterText weight="600">Connect</FooterText>
              </ButtonRectSm>
            </TopUpContainer>
          </>
        ) : (isProd && chainId !== base.id) ||
          (!isProd && chainId !== baseSepolia.id) ? (
          <>
            <Line />
            <TopUpContainer>
              <FooterText color={colors.red}>
                Please switch to Base {isProd ? "Mainnet" : "Sepolia"}.
              </FooterText>
              <ButtonRectSm
                primary
                onClick={handleSwitchChain}
                disabled={switchingChain}
              >
                <Link height={16} width={16} colortroke={colors.white} />
                <FooterText weight="600">
                  {switchingChain ? "Switching..." : "Switch"}
                </FooterText>
              </ButtonRectSm>
            </TopUpContainer>
          </>
        ) : !hasEnoughWalletBalance ? (
          <>
            <Line />
            <TopUpContainer>
              <FooterText color={colors.red}>Insufficient balance.</FooterText>
              <ButtonRectSm primary onClick={handleDeposit}>
                <SendMoneyIcon height={16} width={16} stroke={colors.white} />
                <FooterText weight="600">Top up</FooterText>
              </ButtonRectSm>
            </TopUpContainer>
          </>
        ) : null
      }
      noArrow
      isClosed={paymentMethod !== WALLET || isLoading}
    />
  );
}

WalletPaymentMethod.propTypes = {
  handlePaymentMethod: PropTypes.func,
};
