import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  useMomentsStore,
  usePersistentMomentsStore,
} from "../../routes/MomentsFeed/stores/momentsStore";
import { ScrollableContainer } from "./Moment-Styles";
import MomentView from "./MomentView";
import { MomentSkeleton } from "./MomentSkeleton";
import { useDebouncedValue } from "@mantine/hooks";

export default function ScrollableMoments({
  moments,
  seenMoments,
  isMomentsFeed,
  isLikesFetched,
  isLoading = true,
  isFYP,
  listRef,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) {
  const { momentsIndex, eventsIndex, setMomentsIndex, setEventsIndex } =
    useMomentsStore();
  const { seenIndices, setSeenIndices } = usePersistentMomentsStore();
  const [visibleMoments, setVisibleMoments] = useState(5);
  const [debouncedVisibleMoments] = useDebouncedValue(visibleMoments, 100);

  useEffect(() => {
    const element = listRef.current;
    const scrollOffset =
      (isFYP ? momentsIndex : eventsIndex) * window.innerHeight;

    if (element && element.scrollTop !== scrollOffset) {
      requestAnimationFrame(() => {
        element.style.scrollBehavior = "auto";
        element.scrollTop = scrollOffset;
        element.style.scrollBehavior = "smooth";
      });
    }
  }, [isFYP, listRef]);

  useEffect(() => {
    const element = listRef.current;
    if (!element) return;

    const momentElements = element.querySelectorAll(".moment-view-placeholder");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.dataset.index, 10);
            console.log("Current Index:", index);
            setVisibleMoments(index + 5);

            if (isFYP) {
              setMomentsIndex(index);
            } else {
              setEventsIndex(index);
            }

            if (
              index >= moments.length - 13 &&
              hasNextPage &&
              !isFetchingNextPage
            ) {
              fetchNextPage();
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    momentElements.forEach((moment) => {
      observer.observe(moment);
    });

    return () => {
      observer.disconnect();
    };
  }, [
    moments,
    isFYP,
    setMomentsIndex,
    setEventsIndex,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    listRef,
  ]);

  useEffect(() => {
    const newSeenIndices = new Set(seenIndices);
    newSeenIndices.add(
      moments?.[momentsIndex]?.moment_id +
        "-" +
        moments?.[momentsIndex]?.user_id
    );

    setSeenIndices(Array.from(newSeenIndices));
  }, [momentsIndex]);

  if (
    (isLoading && !isFetchingNextPage) ||
    (moments?.length == 0 && hasNextPage)
  )
    return <MomentSkeleton isFYP />;

  return (
    <ScrollableContainer ref={listRef} id="scrollable-moments">
      {moments?.map((moment, index) => {
        if (moment?.fan_photo_urls?.length < 1) return null;

        return (
          <div
            key={index}
            data-index={index}
            className="moment-view-placeholder"
          >
            {index <= debouncedVisibleMoments && (
              <MomentView
                moment={moment}
                isMomentsFeed={isMomentsFeed}
                isLikesFetched={isLikesFetched}
              />
            )}
          </div>
        );
      })}
      {!hasNextPage &&
        seenMoments?.map((moment, index) => {
          if (moment?.fan_photo_urls?.length < 1) return null;

          return (
            <div
              key={index + moments.length - 1}
              data-index={index + moments.length - 1}
              className="moment-view-placeholder"
            >
              {index + moments.length - 1 <= debouncedVisibleMoments && (
                <MomentView
                  moment={moment}
                  isMomentsFeed={isMomentsFeed}
                  isLikesFetched={isLikesFetched}
                />
              )}
            </div>
          );
        })}
    </ScrollableContainer>
  );
}

ScrollableMoments.propTypes = {
  moments: PropTypes.array,
  seenMoments: PropTypes.array,
  isMomentsFeed: PropTypes.bool,
  isLikesFetched: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFYP: PropTypes.bool,
  listRef: PropTypes.object,
  fetchNextPage: PropTypes.func,
  isFetchingNextPage: PropTypes.bool,
  hasNextPage: PropTypes.bool,
};
