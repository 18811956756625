import { HeaderContainer } from "../../../routes/Collectible/Collectible-Styles";
import { FooterText, IconWithText, Title } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import PropTypes from "prop-types";
import AirdropIcon from "../../../assets/svg/airdrop-icon.svg?react";
import EditionIcon from "../../../assets/svg/edition-icon.svg?react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { useParams, useSearchParams } from "react-router-dom";
import CollectibleIcon from "../CollectibleIcon";

const { colors } = theme;

export default function CollectibleHeader({ collectible, isLoading }) {
  const { id, type, collectible_type, token_id } = collectible || {};
  const { profileId } = useParams() || {};
  const [searchParams] = useSearchParams();
  const editionType = collectible_type || searchParams?.get("type");

  if (isLoading) {
    return (
      <HeaderContainer>
        <SkeletonElement height="24px" width="70px" />
        <HeaderContainer style={{ gap: "12px" }}>
          <SkeletonElement height="24px" width="80px" />
          <SkeletonElement height="24px" width="80px" />
        </HeaderContainer>
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer>
      {profileId && <Title>#{token_id || id}</Title>}
      <HeaderContainer style={{ gap: "12px" }}>
        <IconWithText gap="8px">
          <CollectibleIcon type={type} />
          <FooterText
            color={colors.grey}
            style={{ textTransform: "capitalize" }}
          >
            {type}
          </FooterText>
        </IconWithText>
        {editionType && (
          <IconWithText gap="8px">
            {editionType === "airdrop" ? (
              <AirdropIcon stroke={colors.grey} height={17} width={17} />
            ) : (
              <EditionIcon stroke={colors.grey} height={17} width={17} />
            )}
            <FooterText
              color={colors.grey}
              style={{ textTransform: "capitalize" }}
            >
              {editionType}
            </FooterText>
          </IconWithText>
        )}
      </HeaderContainer>
    </HeaderContainer>
  );
}

CollectibleHeader.propTypes = {
  collectible: PropTypes.object,
  isLoading: PropTypes.bool,
};
