import PropTypes from "prop-types";
import {
  DetailsContainer,
  PaidDetailsContainer,
  PriceContainer,
} from "./CollectibleThumbnail-Styles";
import { BodyText, FooterText, FooterTextSm } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import USDC from "../../../assets/svg/usdc.svg?react";
import { useLocation } from "react-router-dom";

const { colors } = theme;

export default function CollectibleThumbnailDetails({
  collectible,
  isExternal,
  notLink,
}) {
  const { artist, edition_name, name, price, quantity } = collectible || {};
  const { pathname } = useLocation();

  const isPaid = price >= 0 && !notLink;

  if (isPaid) {
    return (
      <DetailsContainer>
        <BodyText bold truncate style={{ marginBottom: 8, textAlign: "left" }}>
          {edition_name}
        </BodyText>
        <PaidDetailsContainer>
          <PriceContainer>
            <FooterText>${price}</FooterText>
            <USDC height={16} width={16} />
          </PriceContainer>
          <FooterTextSm secondary>Qty. {quantity}</FooterTextSm>
        </PaidDetailsContainer>
      </DetailsContainer>
    );
  }

  return (
    <DetailsContainer
      style={{
        height: pathname.includes("artists") ? "75px" : "auto",
      }}
    >
      <BodyText bold truncate style={{ marginBottom: 8 }}>
        {isExternal ? name : artist}
      </BodyText>
      <FooterTextSm color={colors.grey} truncate>
        {isExternal ? "Momentify Badge" : (edition_name ?? "No edition name")}
      </FooterTextSm>
    </DetailsContainer>
  );
}

CollectibleThumbnailDetails.propTypes = {
  collectible: PropTypes.object,
  isExternal: PropTypes.bool,
  notLink: PropTypes.bool,
};
