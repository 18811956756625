import PropTypes from "prop-types";

import { TicketContainer } from "./EventTicket-Styles";
import { BodyText } from "../../../GlobalStyles";
import TicketButton from "../../Events/TicketButton";
import { useEffect, useMemo } from "react";
import { generateTicketAffiliate } from "../../../utils/affiliates";
import { TICKET_SOURCES } from "../../../constants/constants";

export default function EventTicket({ ticket, event }) {
  const [name, url] = Object.entries(ticket)[0];

  const { ticketType, ticketUrl } = useMemo(() => {
    const { WEGOTTICKETS } = TICKET_SOURCES;
    const ticketType = name.charAt(0).toUpperCase() + name.slice(1);
    const ticketUrl = generateTicketAffiliate({ url, type: ticketType });

    return {
      ticketType:
        ticketType === "Wegottickets" ? WEGOTTICKETS.NAME : ticketType,
      ticketUrl,
    };
  }, [name, url]);

  useEffect(() => {
    console.log(ticketUrl);
  }, [ticketUrl]);

  if (!ticketType || !ticketUrl) {
    return null;
  }

  return (
    <TicketContainer>
      <BodyText>{ticketType}</BodyText>
      <TicketButton
        event={event}
        ticketType={ticketType}
        ticketUrl={ticketUrl}
      />
    </TicketContainer>
  );
}

EventTicket.propTypes = {
  ticket: PropTypes.object,
  event: PropTypes.object,
};
