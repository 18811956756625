import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import TopNavV2 from "../../TopNavV2";
import {
  useUserMessages,
  useUserNotifications,
} from "../../../routes/Profile/hooks/profileQueries";
import { useMemo } from "react";
import {
  GreenDot,
  ProfileRightHeader,
} from "../../Profile/Styles/ProfileHeader-Styles";
import { ButtonGhostL } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import BellIcon from "../../../assets/svg/bell.svg?react";
import MailIcon from "../../../assets/svg/mail.svg?react";
import SettingsIcon from "../../../assets/svg/settings.svg?react";

const { colors } = theme;

export default function BackstageProfileHeader() {
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const userNotificationsQuery = useUserNotifications({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: true,
  });
  const userMessagesQuery = useUserMessages({
    userId: loggedInUser?.id ?? "",
    isOwnProfile: true,
  });
  const { avatar, username } = loggedInUser || {};

  const hasNotification = useMemo(() => {
    return (
      userNotificationsQuery?.data?.filter((row) => !row.read_at)?.length > 0
    );
  }, [userNotificationsQuery?.data]);

  const hasMessages = useMemo(() => {
    return userMessagesQuery?.data?.filter((row) => !row.read_at)?.length > 0;
  }, [userMessagesQuery?.data]);

  return (
    <TopNavV2
      icon={avatar}
      title={username}
      content={
        <ProfileRightHeader>
          <ButtonGhostL onClick={() => navigate(`/backstage/messages`)}>
            <MailIcon stroke={colors.white} height={16} width={16} />
            {hasMessages && <GreenDot />}
          </ButtonGhostL>
          <ButtonGhostL onClick={() => navigate(`/backstage/notifications`)}>
            <BellIcon stroke={colors.white} height={16} width={16} />
            {hasNotification && <GreenDot />}
          </ButtonGhostL>
          <ButtonGhostL onClick={() => navigate("/backstage/settings")}>
            <SettingsIcon height={16} width={16} stroke={colors.white} />
          </ButtonGhostL>
        </ProfileRightHeader>
      }
      isSticky
      noBack
    />
  );
}
