import { useMemo } from "react";
import { BodyText, ButtonRect, HeaderL } from "../../../GlobalStyles";
import Checkbox from "../../../assets/svg/checkbox.svg?react";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import { useCheckoutStore } from "../checkoutStore";
import {
  Container,
  HeaderContainer,
  TicketsContainer,
} from "./PaymentSuccess-Styles";
import TicketItem from "../../Tickets/TicketItem";
import { theme } from "../../../utils/theme";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { useTabsStore } from "../../Tabs/tabsStore";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function PaymentSuccess() {
  const { loggedInUser } = useAuth();
  const { event, reset: resetBuyTicket, contract } = useBuyTicketStore();
  const {
    boughtTickets,
    reset: resetCheckout,
    pendingEvent,
    resetPending,
  } = useCheckoutStore();
  const { toggleSheetModal } = useGlobalModal();
  const { setActiveTab } = useTabsStore();
  const navigate = useNavigate();
  const {
    headline_artist_id,
    artists,
    artists_lineup,
    venue,
    city,
    country,
    date,
    headline_artist,
  } = event || pendingEvent || {};
  const { image } = contract || {};

  const ticketsBought = useMemo(() => {
    const checkout = localStorage.getItem("checkout");
    const tickets = checkout && JSON.parse(checkout).state.boughtTickets;

    return boughtTickets || tickets || [];
  }, [boughtTickets]);

  const ticketsCount = useMemo(() => {
    if (!ticketsBought || !ticketsBought?.length) return 0;

    return ticketsBought.reduce((total, ticket) => total + ticket.count, 0);
  }, [ticketsBought]);

  const handleConfirm = () => {
    toggleSheetModal();
    setActiveTab("tickets");

    resetBuyTicket();
    resetCheckout();
    resetPending();
    navigate(`/profile/${loggedInUser?.id}`);
  };

  return (
    <Container>
      <HeaderContainer>
        <Checkbox stroke={theme.colors.green} />
        <HeaderL
          style={{ textTransform: "uppercase", margin: "16px 0 12px 0" }}
        >
          Congratulations!
        </HeaderL>
        <BodyText secondary>
          You successfully bought {ticketsCount}{" "}
          {ticketsCount <= 1 ? "ticket" : "tickets"} and supported the artist
          directly.
        </BodyText>
      </HeaderContainer>
      {ticketsBought?.length > 0 && (
        <TicketsContainer>
          {ticketsBought.map((ticket, index) => {
            const ticketData = {
              ...ticket,
              headline_artist_id,
              headline_artist,
              artists,
              artists_lineup,
              image,
              venue,
              city,
              country,
              date,
            };
            return <TicketItem key={index} ticket={ticketData} />;
          })}
        </TicketsContainer>
      )}
      <ButtonRect primary onClick={handleConfirm} style={{ marginTop: 8 }}>
        <BodyText weight="600">Confirm</BodyText>
      </ButtonRect>
    </Container>
  );
}
