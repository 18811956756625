import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import { BodyText } from "../../../GlobalStyles";
import { LineLeftItem, SummaryHeader } from "./OrderSummary-Styles";
import { formatPrice } from "../../../utils/utils";
import AnimatedDropdown from "../../Dropdown/AnimatedDropdown";
import { useCheckoutStore } from "../checkoutStore";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";
import OrderSummaryContent from "./OrderSummaryContent";

export default function OrderSummary() {
  const { ticketsSelected, isOpen: ticketsOpen } = useBuyTicketStore();
  const { collectiblesSelected, isOpen: collectiblesOpen } =
    useBuyCollectibleStore();
  const { momentifyFee, ticketsTotal, collectiblesTotal, total } =
    useCheckoutStore();

  const free =
    total === 0 &&
    (ticketsOpen
      ? ticketsSelected?.length > 0
      : collectiblesOpen
        ? collectiblesSelected?.length > 0
        : false);

  const formattedTotal = formatPrice(
    (ticketsOpen ? ticketsTotal : collectiblesTotal) + momentifyFee
  );

  return (
    <AnimatedDropdown
      header={
        <SummaryHeader>
          <LineLeftItem style={{ gap: "10px" }}>
            <BodyText weight="600">Order summary</BodyText>
            <BodyText
              secondary
            >{`(${ticketsOpen ? ticketsSelected.length : collectiblesSelected.length} items)`}</BodyText>
          </LineLeftItem>
          <LineLeftItem style={{ gap: "16px" }}>
            <BodyText>
              {free ? "FREE!" : `$${formattedTotal[0]}.${formattedTotal[1]}`}
            </BodyText>
          </LineLeftItem>
        </SummaryHeader>
      }
      content={<OrderSummaryContent />}
    />
  );
}
