import { BodyText } from "../../../GlobalStyles";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";
import {
  Container,
  LeftContainer,
  RightContainer,
} from "./CollectibleItem-Styles";
import CollectibleItemButtons from "./CollectibleItemButtons";
import CollectibleItemPrice from "./CollectibleItemPrice";
import CollectibleStock from "./CollectibleStock";

export default function CollectibleItem() {
  const { isOpen, collectible } = useBuyCollectibleStore();
  const { edition_name } = collectible || {};

  return (
    <Container>
      <LeftContainer style={{ gap: isOpen ? 12 : 8 }}>
        {isOpen && <BodyText bold>{edition_name}</BodyText>}
        <CollectibleItemPrice />
      </LeftContainer>
      <RightContainer style={{ gap: isOpen ? 12 : 8 }}>
        <CollectibleStock />
        <CollectibleItemButtons />
      </RightContainer>
    </Container>
  );
}
