import PropTypes from "prop-types";
import { Column, Container, UserImage } from "./MintItem-Styles";
import {
  BodyText,
  ButtonSm,
  FooterText,
  IconWithText,
} from "../../../GlobalStyles";
import { AvatarPlaceholder } from "../../../constants/constants";
import Check from "../../../assets/svg/check-green.svg?react";
import Phone from "../../../assets/svg/phone.svg?react";
import { trimMobileNumber } from "../../../utils/utils";
import { theme } from "../../../utils/theme";
import { useEffect, useState } from "react";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import { useBackstageManualCheckinMutate } from "../../../routes/BackstageManualCheckin/hooks/backstageEventQueries";
import { useParams } from "react-router-dom";

export default function MintItem({ mint, isLoading }) {
  const { id: eventId } = useParams();
  const {
    id: mintId,
    token_id,
    users,
    type,
    is_scanned,
    is_transferred,
    checkin_date,
  } = mint || {};
  const { avatar, username, mobile_number } = users || {};
  const [isCheckedIn, setIsCheckedIn] = useState(is_scanned || checkin_date);

  const checkInMutation = useBackstageManualCheckinMutate({
    eventId,
    mintId,
  });

  useEffect(() => {
    setIsCheckedIn(is_scanned || checkin_date);
  }, [is_scanned, checkin_date]);

  const handleCheckIn = () => {
    checkInMutation.mutate({
      isCheckedIn,
    });

    setIsCheckedIn(!isCheckedIn);
  };

  if (isLoading) {
    return (
      <Container>
        <Column>
          <SkeletonElement height="17px" />
          <SkeletonElement height="17px" />
        </Column>
        <Column>
          <SkeletonElement height="17px" />
          <SkeletonElement height="17px" />
        </Column>
        <SkeletonElement height="30px" width="30px" radius="8px" />
      </Container>
    );
  }

  return (
    <Container>
      <Column>
        <BodyText bold secondary truncate>
          {type}
        </BodyText>
        <BodyText truncate>#{token_id}</BodyText>
      </Column>
      <Column>
        <IconWithText gap="8px">
          <UserImage
            src={avatar || AvatarPlaceholder}
            onError={(e) => (e.target.src = AvatarPlaceholder)}
          />
          <FooterText truncate>{username}</FooterText>
        </IconWithText>
        <IconWithText gap="8px">
          <Phone height={14} width={14} opacity={0.6} />
          <FooterText secondary truncate>
            {mobile_number ? trimMobileNumber(mobile_number) : "N/A"}
          </FooterText>
        </IconWithText>
        <FooterText secondary>
          {is_transferred ? "Transferred" : "Purchased"}
        </FooterText>
      </Column>
      <ButtonSm
        style={{
          border: isCheckedIn && `1px solid ${theme.colors.green}`,
        }}
        onClick={handleCheckIn}
        disabled={is_scanned}
      >
        {isCheckedIn && <Check height={16} width={16} />}
      </ButtonSm>
    </Container>
  );
}

MintItem.propTypes = {
  mint: PropTypes.object,
  isLoading: PropTypes.bool,
};
