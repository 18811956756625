import { FooterText, HeaderL, HeaderSm } from "../../../GlobalStyles";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";
import { formatPrice } from "../../../utils/utils";
import { PriceContainer } from "./CollectibleItem-Styles";
import USDCIcon from "../../../assets/svg/usdc.svg?react";

export default function CollectibleItemPrice() {
  const { collectible } = useBuyCollectibleStore();
  const { price, fees } = collectible || {};
  const { value, isPercentage } = fees || {};

  const formattedPrice = formatPrice(price);
  const formattedFee = !isPercentage ? formatPrice(value, false) : value;

  return (
    <PriceContainer style={{ alignItems: "flex-end" }}>
      <PriceContainer style={{ alignItems: "center", gap: 8 }}>
        <PriceContainer style={{ alignItems: "flex-end", gap: 0 }}>
          <HeaderL
            lineHeight="1"
            style={{
              textWrap: "nowrap",
            }}
          >
            $ {formattedPrice[0]}
          </HeaderL>
          <HeaderSm>.{formattedPrice[1] ?? "00"}</HeaderSm>
        </PriceContainer>
        <USDCIcon height={24} width={24} />
      </PriceContainer>
      <FooterText
        secondary
        style={{
          textWrap: "nowrap",
        }}
      >
        +{isPercentage ? `${formattedFee}%` : `${formattedFee}`} fee
      </FooterText>
    </PriceContainer>
  );
}
