import { BodyText, ButtonRect, Title } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import PropTypes from "prop-types";
import { Container, Header } from "./ErrorModal-Styles";
import { WarningCircle } from "@phosphor-icons/react";
import useGlobalModal from "../../../hooks/useGlobalModal";

const { colors } = theme;

export default function ErrorModal({ error }) {
  const { closeSheetModal } = useGlobalModal();
  return (
    <Container>
      <Header>
        <WarningCircle height={35} width={35} color={colors.red} />
        <Title>An error occurred</Title>
      </Header>
      <BodyText>Something went wrong. Try again later.</BodyText>
      {error && <BodyText color={colors.red}>{error}</BodyText>}
      <ButtonRect primary onClick={closeSheetModal}>
        Continue
      </ButtonRect>
    </Container>
  );
}

ErrorModal.propTypes = {
  error: PropTypes.string,
};
