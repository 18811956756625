import {
  ButtonRectSm,
  ButtonRectXs,
  FooterText,
  FooterTextSm,
} from "../../../GlobalStyles";
import { AddressContainer, Container } from "./WalletCardFooter-Styles";
import CopyIcon from "../../../assets/svg/copy.svg?react";
import { handleCopyText, trimAddress } from "../../../utils/utils";
import { theme } from "../../../utils/theme";
import PropTypes from "prop-types";
import { useFundWallet, usePrivy } from "@privy-io/react-auth";
import { ButtonsContainer } from "../WalletCard/WalletCard-Styles";
import { base } from "viem/chains";

export default function WalletCardFooter({ wallet }) {
  const { address, connected, primary } = wallet || {};
  const { connectWallet } = usePrivy();
  const { fundWallet } = useFundWallet();

  const handleDeposit = () => {
    fundWallet(address, {
      chain: base,
    });
  };
  return (
    <Container>
      <AddressContainer>
        <FooterText color={theme.colors.grey} truncate>
          {trimAddress(address)}
        </FooterText>
        <div>
          <CopyIcon
            height={14}
            width={14}
            stroke="white"
            onClick={() => handleCopyText(address)}
          />
        </div>
      </AddressContainer>
      {primary && (
        <ButtonsContainer>
          <ButtonRectSm disabled style={{ opacity: 0.4 }}>
            <FooterText weight="600">Withdraw</FooterText>
          </ButtonRectSm>
          <ButtonRectSm primary onClick={handleDeposit}>
            <FooterText weight="600">Deposit</FooterText>
          </ButtonRectSm>
        </ButtonsContainer>
      )}
      {!connected && !primary && (
        <ButtonRectXs
          onClick={() => connectWallet({ suggestedAddress: address })}
          primary
        >
          <FooterTextSm weight="600">Connect</FooterTextSm>
        </ButtonRectXs>
      )}
    </Container>
  );
}

WalletCardFooter.propTypes = {
  wallet: PropTypes.object,
};
