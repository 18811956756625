// Replace this with your app's required chains
import { http } from "wagmi";
import { base, baseSepolia } from "wagmi/chains";
import { coinbaseWallet, injected, metaMask } from "@wagmi/connectors";
import { createConfig } from "@privy-io/wagmi";

export const wagmiConfig = createConfig({
  chains: [base, baseSepolia], // Pass your required chains as an array
  connectors: [
    coinbaseWallet({
      appName: "Momentify",
      preference: "smartWalletOnly",
      version: "4",
    }),
    injected(),
    metaMask(),
  ],
  transports: {
    [base.id]: http(),
    [baseSepolia.id]: http(),
    // For each of your required chains, add an entry to `transports` with
    // a key of the chain's `id` and a value of `http()`
  },
});
