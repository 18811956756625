import PropTypes from "prop-types";
import { FooterText } from "../../../GlobalStyles";
import { Button, IconWithText } from "./FilterButton-Styles";
import Close from "../../../assets/svg/close.svg?react";

export default function FilterButton({
  icon,
  name,
  active,
  disabled,
  onClick,
  onRemove,
}) {
  return (
    <Button primary={active} disabled={disabled} type="button">
      <IconWithText onClick={onClick}>
        {icon}
        <FooterText>{name}</FooterText>
      </IconWithText>
      {onRemove && (
        <Close stroke="white" height={10} width={10} onClick={onRemove} />
      )}
    </Button>
  );
}

FilterButton.propTypes = {
  icon: PropTypes.element.isRequired,
  name: PropTypes.any.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.any,
};
