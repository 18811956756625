import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import {
  InputContainer,
  SearchInput,
} from "../../../routes/Search/Search-Styles";
import SearchIcon from "../../../assets/svg/search.svg?react";
import { useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export default function TicketTransferInput({ isLoading }) {
  const { register, handleSubmit } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const { name, onChange, onBlur, ref } = register("usersSearch"); // Form input
  const query = searchParams.get("query");

  // Submit search and save search history
  const onSubmitEnter = async (data) => {
    if (!data.usersSearch) return;

    document.querySelector("input[name=usersSearch]")?.blur();

    searchParams.set("query", data.usersSearch);
    setSearchParams(searchParams);
  };

  const debounceSearch = useDebouncedCallback((query) => {
    if (query === undefined) return;

    searchParams.set("query", query?.trim());

    setSearchParams(searchParams);
  }, 300);

  const handleOnChange = (e) => {
    onChange(e);

    debounceSearch(e.target.value);
  };

  if (isLoading) {
    return <SkeletonElement height="41px" radius="10px" />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmitEnter)}
      action="."
      style={{ width: "100%" }}
    >
      <InputContainer>
        <SearchIcon stroke="rgba(255, 255, 255, 0.5)" height={16} width={16} />
        <SearchInput
          type="search"
          enterKeyHint="search"
          placeholder="Search fans"
          name={name}
          defaultValue={query}
          onChange={handleOnChange}
          onBlur={onBlur}
          ref={ref}
        />
      </InputContainer>
    </form>
  );
}

TicketTransferInput.propTypes = {
  isLoading: PropTypes.bool,
};
