import PropTypes from "prop-types";
import { BodyText, ButtonRect, OneLineText } from "../../../GlobalStyles";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import { useMemo } from "react";
import TwoTicketIcon from "../../../assets/svg/two-tickets.svg?react";
import { ArtistPlaceholder } from "../../../constants/constants";
import useMomentifyLogin from "../../../hooks/useMomentifyLogin";
import { Container, TicketContainer } from "./EventTickets-Styles";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import EventTicket from "../EventTicket";

export default function EventTickets({ event, ticketsLoading, artistTickets }) {
  const { loggedInUser, login } = useMomentifyLogin();
  const { setIsOpen } = useBuyTicketStore();
  const {
    headline_artist,
    artists,
    artists_lineup,
    urls: tickets,
  } = event || {};
  const { tickets: directTickets, contract } = artistTickets || {};

  const { lowestPrice, highestPrice } = useMemo(() => {
    if (!directTickets || directTickets.length === 0) {
      return { lowestPrice: 0, highestPrice: 0 };
    }

    return directTickets.reduce(
      (acc, ticket) => {
        if (ticket.price < acc.lowestPrice) acc.lowestPrice = ticket.price;
        if (ticket.price > acc.highestPrice) acc.highestPrice = ticket.price;
        return acc;
      },
      { lowestPrice: Infinity, highestPrice: -Infinity }
    );
  }, [directTickets]);

  const headlineArtist = useMemo(() => {
    if (artists?.length > 0) {
      return artists?.find((artist) => artist?.artist_name === headline_artist);
    } else if (artists_lineup?.length > 0) {
      return artists_lineup?.find(
        (artist) => artist?.artist_name === headline_artist
      );
    }
  }, [artists, artists_lineup, headline_artist]);

  const { artist_name, profile_img } = headlineArtist || {};

  const handleOpenDirectTicket = () => {
    if (!loggedInUser) return login();
    setIsOpen(true);
  };

  if (ticketsLoading) {
    return (
      <Container>
        <BodyText weight="600">Tickets</BodyText>
        {Array.from({ length: 3 }).map((_, index) => (
          <SkeletonElement
            key={index}
            height="53px"
            width="100%"
            radius="10px"
          />
        ))}
      </Container>
    );
  }

  if (
    !directTickets?.length &&
    (!tickets?.length || !tickets?.[0]) &&
    !ticketsLoading
  ) {
    return null;
  }

  return (
    <Container>
      <BodyText weight="600">Tickets</BodyText>
      <Container
        style={{
          gap: 8,
        }}
      >
        {directTickets?.length > 0 && contract && (
          <TicketContainer glass>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <BodyText style={{ textWrap: "nowrap" }}>Direct from</BodyText>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <img
                  src={profile_img ?? ArtistPlaceholder}
                  alt={artist_name}
                  style={{ width: "20px", height: "20px", borderRadius: "50%" }}
                  onError={(e) => {
                    e.target.src = ArtistPlaceholder;
                  }}
                />
                <OneLineText weight="600">{artist_name}</OneLineText>
              </div>
            </div>
            <ButtonRect
              primary
              onClick={handleOpenDirectTicket}
              style={{ padding: "12px 16px", gap: "8px" }}
            >
              <TwoTicketIcon stroke="white" height={16} width={16} />
              <BodyText weight="600" style={{ textWrap: "nowrap" }}>
                ${lowestPrice} - ${highestPrice}
              </BodyText>
            </ButtonRect>
          </TicketContainer>
        )}

        {tickets &&
          tickets[0] != null &&
          tickets.map((item, index) => {
            if (!item) return;
            return <EventTicket key={index} ticket={item} event={event} />;
          })}
      </Container>
    </Container>
  );
}

EventTickets.propTypes = {
  event: PropTypes.object,
  ticketsLoading: PropTypes.bool,
  artistTickets: PropTypes.object,
};
