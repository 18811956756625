import { FooterText } from "../../../GlobalStyles";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";

export default function CollectibleStock() {
  const { userCollectibles, collectible } = useBuyCollectibleStore();
  const { quantity } = collectible || {};
  const { soldOut, remaining } = userCollectibles || {};

  if (soldOut) {
    return null;
  }

  return (
    <FooterText secondary>
      {remaining}/{quantity} left
    </FooterText>
  );
}
