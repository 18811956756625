import { useParams, useSearchParams } from "react-router-dom";
import TopNav from "../../components/TopNav";
import { NavBarTypes } from "../../constants/constants";
import { useCollectibleQuery } from "./collectiblesQueries";
import { Container } from "./Collectible-Styles";
import { Section } from "../../GlobalStyles";
import ErrorPage from "../Error";
import CollectibleHeader from "../../components/CollectiblePage/CollectibleHeader";
import CollectibleOwner from "../../components/CollectiblePage/CollectibleOwner";
import CollectibleArtist from "../../components/CollectiblePage/CollectibleArtist";
import CollectibleDescription from "../../components/CollectiblePage/CollectibleDescription";
import CollectibleUrls from "../../components/CollectiblePage/CollectibleUrls";
import CollectibleAdditionalInfo from "../../components/CollectiblePage/CollectibleAdditionalInfo";
import CollectibleTxn from "../../components/CollectiblePage/CollectibleTxn";
import CollectibleTitle from "../../components/CollectiblePage/CollectibleTitle";
import CollectibleAsset from "../../components/CollectiblePage/CollectibleAsset";

export default function Collectible() {
  const { profileId, collectibleId } = useParams();
  const [searchParams] = useSearchParams();
  const editionType = searchParams?.get("type");

  const { data, isLoading } = useCollectibleQuery({
    userId: profileId,
    collectibleId,
    type: editionType,
  });

  const {
    created_at,
    contract_address,
    image,
    video,
    edition_name,
    artist,
    description,
    chain,
    transaction_hash,
    user,
    symbol,
  } = data || {};

  if (!isLoading && (data?.length === 0 || !data)) {
    return <ErrorPage description="We can't find this collectible." />;
  }

  return (
    <Section>
      <TopNav type={NavBarTypes.COLLECTIBLES} />
      <Container style={{ padding: "0 20px 16px" }}>
        <CollectibleHeader collectible={data} isLoading={isLoading} />
        <CollectibleAsset
          image={image}
          video={video}
          chain={chain}
          isLoading={isLoading}
        />
        <CollectibleTitle edition_name={edition_name} isLoading={isLoading} />
        <CollectibleArtist artist={artist} isLoading={isLoading} />
        {user && (
          <CollectibleOwner
            owner={user}
            created_at={created_at}
            isLoading={isLoading}
          />
        )}
        <CollectibleDescription
          symbol={symbol}
          description={description}
          isLoading={isLoading}
        />
        <CollectibleUrls
          contractAddress={contract_address}
          isLoading={isLoading}
        />
        <CollectibleAdditionalInfo collectible={data} isLoading={isLoading} />
        <CollectibleTxn txnHash={transaction_hash} isLoading={isLoading} />
      </Container>
    </Section>
  );
}
