import {
  BalanceContainer,
  Container,
  LeftContainer,
  WalletContainer,
} from "./WalletOption-Styles";
import Wallet from "../../../assets/svg/wallet.svg?react";
import USDCIcon from "../../../assets/svg/usdc.svg?react";
import { BodyText, ButtonRectSm } from "../../../GlobalStyles";
import {
  formatPrice,
  handleCopyText,
  isProd,
  trimAddress,
} from "../../../utils/utils";
import useAuth from "../../../hooks/useAuth";
import { useReadContracts } from "wagmi";
import {
  USDC_ADDRESS,
  USDC_ADDRESS_SEPOLIA,
} from "../../../constants/constants";
import { base, baseSepolia } from "viem/chains";
import { erc20Abi, formatUnits } from "viem";
import { useNavigate } from "react-router-dom";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export default function WalletOption() {
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const { embedded_wallet_id: address } = loggedInUser || {};
  const trimmedAddress = trimAddress(address);
  const USDC = isProd ? USDC_ADDRESS : USDC_ADDRESS_SEPOLIA;
  const chainId = isProd ? base.id : baseSepolia.id;

  const { data, isLoading } = useReadContracts({
    allowFailure: true,
    contracts: [
      {
        address: USDC,
        abi: erc20Abi,
        functionName: "balanceOf",
        args: [address],
        chainId,
      },
      {
        address: USDC,
        abi: erc20Abi,
        functionName: "decimals",
        chainId,
      },
    ],
  });

  let { 0: balance, 1: decimals } = data || {};

  if (balance?.status === "success" && decimals?.status === "success") {
    const formattedBalance = formatUnits(balance.result, decimals.result); // USDC has 6 decimal places

    balance = formatPrice(formattedBalance);
  }

  return (
    <Container>
      <WalletContainer>
        <LeftContainer>
          <div>
            <Wallet height={16} width={16} stroke="white" />
          </div>
          <div
            style={{
              position: "relative",
              bottom: "-1px",
              display: "flex",
              alignItems: "center",
              gap: 12,
            }}
          >
            <BodyText>Wallet</BodyText>
            <BodyText
              secondary
              onClick={() => handleCopyText(address)}
              style={{
                cursor: "pointer",
              }}
            >
              {trimmedAddress || "No wallet"}
            </BodyText>
          </div>
        </LeftContainer>
        {isLoading ? (
          <SkeletonElement height="30px" width="56px" radius="8px" />
        ) : (
          <BalanceContainer>
            <BodyText weight="600">${balance[0]}</BodyText>
            <USDCIcon height={14} width={14} />
          </BalanceContainer>
        )}
      </WalletContainer>
      <ButtonRectSm primary onClick={() => navigate("/profile/wallets")}>
        Manage
      </ButtonRectSm>
    </Container>
  );
}
