import {
  ButtonRectSm,
  Button,
  FooterText,
  ButtonRect,
  BodyText,
} from "../GlobalStyles";
import PropTypes from "prop-types";
import styled from "styled-components";
import UserCheck from "../assets/svg/user-check.svg?react";
import UserPlus from "../assets/svg/user-plus.svg?react";
import { BUTTON_SIZES, BUTTON_TYPES } from "../constants/constants";

const SquareButton = styled(Button)`
  background: ${({ isFollowing, theme }) =>
    isFollowing ? theme.colors.blackTransparent : theme.colors.greyBtn};
  border: ${({ isFollowing, theme }) =>
    isFollowing ? `1px solid ${theme.colors.border}` : "1px solid transparent"};
`;

const ButtonSm = styled(ButtonRectSm)`
  background: ${({ isFollowing, theme }) =>
    isFollowing ? theme.colors.newBtn : theme.colors.greyBtn};
  min-width: 81px;
`;

const ButtonMed = styled(ButtonRect)`
  background: ${({ isFollowing, theme }) =>
    isFollowing ? theme.colors.newBtn : theme.colors.greyBtn};
  min-width: 81px;
`;

export default function FollowButton({
  isFollowing,
  handleClick,
  type = BUTTON_TYPES.RECTANGLE,
  size = BUTTON_SIZES.MEDIUM,
}) {
  if (type === BUTTON_TYPES.SQUARE && size === BUTTON_SIZES.SMALL) {
    return (
      <SquareButton onClick={handleClick} isFollowing={isFollowing}>
        {isFollowing ? (
          <UserCheck stroke="white" />
        ) : (
          <UserPlus stroke="white" />
        )}
      </SquareButton>
    );
  }

  if (type === BUTTON_TYPES.RECTANGLE && size === BUTTON_SIZES.SMALL) {
    return (
      <ButtonSm onClick={handleClick} isFollowing={isFollowing}>
        <FooterText weight="600">
          {isFollowing ? "Following" : "Follow"}
        </FooterText>
      </ButtonSm>
    );
  }

  if (type === BUTTON_TYPES.RECTANGLE && size === BUTTON_SIZES.MEDIUM) {
    return (
      <ButtonMed onClick={handleClick} isFollowing={isFollowing}>
        <BodyText weight="600">{isFollowing ? "Following" : "Follow"}</BodyText>
      </ButtonMed>
    );
  }
}

FollowButton.propTypes = {
  isFollowing: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  size: PropTypes.string,
};
