import { usePrivy } from "@privy-io/react-auth";
import {
  BodyText,
  ButtonRectXs,
  FooterText,
  FooterTextSm,
} from "../../../GlobalStyles";

import {
  Header,
  LeftContainer,
  RightContainer,
} from "./WalletCardHeader-Styles";
import { useTabsStore } from "../../Tabs/tabsStore";
import PropTypes from "prop-types";
import { toTitleCase } from "../../../utils/utils";
import useGlobalModal from "../../../hooks/useGlobalModal";
import SetWalletModal from "../../Modal/SetWalletModal";
import WalletCardIcon from "../WalletCardIcon";
import { usePrimaryWallet } from "../../../hooks/usePrimaryWallet";

export default function WalletCardHeader({ wallet }) {
  const {
    walletClientType,
    connected,
    type,
    primary,
    smartWalletType,
    address,
  } = wallet || {};
  const { primaryWallet, receivingWallet } = usePrimaryWallet();
  const { activeTab } = useTabsStore();
  const { exportWallet, connectWallet } = usePrivy();
  const { setSheetModalContent, toggleSheetModal } = useGlobalModal();

  const isPrivy = walletClientType === "privy";
  const isSmart = type === "smart_wallet";
  const isReceive = activeTab === "receive";

  const handleWallet = async ({ isUnlink }) => {
    setSheetModalContent(
      <SetWalletModal wallet={wallet} isUnlink={isUnlink} />
    );
    toggleSheetModal();
  };

  return (
    <Header>
      <LeftContainer>
        <WalletCardIcon type={walletClientType || smartWalletType} />
        <BodyText
          secondary
          bold
          truncate
          style={{
            textTransform: "capitalize",
            lineHeight: "normal",
          }}
        >
          {toTitleCase(walletClientType || smartWalletType)}
        </BodyText>
        {primary && (
          <FooterText>{isReceive ? "(Receiving)" : "(Payments)"}</FooterText>
        )}
      </LeftContainer>
      <RightContainer style={{ gap: "16px" }}>
        {(isPrivy || isSmart) && primary && (
          <ButtonRectXs onClick={exportWallet}>
            <FooterTextSm weight="600">Export</FooterTextSm>
          </ButtonRectXs>
        )}
        {!primary && (
          <>
            {!isPrivy && !isSmart && (
              <ButtonRectXs
                onClick={() => handleWallet({ isUnlink: true })}
                disabled={
                  primaryWallet?.address === address ||
                  receivingWallet?.address === address
                }
              >
                <FooterTextSm weight="600">Unlink</FooterTextSm>
              </ButtonRectXs>
            )}
            <ButtonRectXs
              primary
              onClick={() => handleWallet({})}
              disabled={!connected}
            >
              <FooterTextSm
                weight="600"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                Make primary
              </FooterTextSm>
            </ButtonRectXs>
          </>
        )}
        {!connected && primary && (
          <ButtonRectXs
            onClick={() => connectWallet({ suggestedAddress: address })}
            primary
          >
            <FooterTextSm weight="600">Connect</FooterTextSm>
          </ButtonRectXs>
        )}
      </RightContainer>
    </Header>
  );
}

WalletCardHeader.propTypes = {
  wallet: PropTypes.object,
};
