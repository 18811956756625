import styled from "styled-components";
import { BottomNavBarHeight } from "../../../constants/constants";

export const Container = styled.div`
  height: calc(100svh - ${BottomNavBarHeight});
  max-height: calc(100svh - ${BottomNavBarHeight});
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999;
  overflow-y: scroll;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  flex: 1;
`;
