import moment from "moment";
import { toast } from "react-toastify";
import { setInviteFriendAction } from "../api/profile";
import { GlassmorphPlaceholder } from "../constants/constants";

export const handleCopyText = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!", {
      toastId: "wallet-copied",
    });
  } catch (err) {
    console.error("Failed to copy: ", err);
    toast.error("Failed to copy!", {
      toastId: "wallet-copied-error",
    });
  }
};

// Insert Wallet to DB
export const insertWallet = async (discordId, wallet) => {
  try {
    await fetch(
      `${import.meta.env.VITE_API_URL}/profile/${discordId}/wallets`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          discordId,
          wallet,
        }),
      }
    );
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export const setDelay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const setImageofMoment = (moment, customImage) => {
  const image =
    customImage ||
    moment?.platform_event_img ||
    moment?.image_url ||
    (moment?.event_image == "None" || !moment?.event_image
      ? null
      : moment?.event_image) ||
    GlassmorphPlaceholder;

  return image;
};

export const setImageOfEvent = (event) => {
  return (
    event?.platform_event_img ??
    event?.image_url ??
    event?.image ??
    GlassmorphPlaceholder
  );
};

export const isProd = window.location.hostname === "app.momentify.xyz";
export const isStaging =
  window.location.hostname === "staging-app.momentify.xyz";

export const checkIfPWAisInstalled = () => {
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return true;
  } else {
    return false;
  }
};

export const urlToFile = async (url) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(
        `Failed to fetch: ${response.status} ${response.statusText}`
      );
    }

    const blob = await response.blob();
    const filename = url.substring(url.lastIndexOf("/") + 1);
    const file = new File([blob], filename);
    return file;
  } catch (error) {
    console.error("Error converting URL to file:", error);
    return null;
  }
};

export const getDistanceFromLatLong = (lat1, lon1, lat2, lon2) => {
  const radius = 6371000; // Radius of the Earth in meters
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = radius * c;

  return distance;
};

export const getLatWithLetter = (lat) => {
  return lat > 0 ? `${lat?.toFixed(3)}° N` : `${Math.abs(lat)?.toFixed(3)}° S`;
};

export const getLonWithLetter = (lon) => {
  return lon > 0 ? `${lon?.toFixed(3)}° E` : `${Math.abs(lon)?.toFixed(3)}° W`;
};

export const formatEvent = (
  eventDateTime,
  eventLat,
  eventLon,
  userLat,
  userLon
) => {
  const eventDate = new Date(eventDateTime);
  const now = new Date();

  // Format event time
  const formattedTime = moment(eventDate).format("LT");

  // Calculate the difference in hours and minutes
  const diffInMilliseconds = eventDate - now;
  const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;

  const distance = Math.max(
    0,
    getDistanceFromLatLong(userLat, userLon, eventLat, eventLon) - 100
  );
  const distanceInM = distance.toFixed(1);

  return {
    formattedTime,
    timeDifference: hours <= 0 ? `Live!` : `${hours}h ${minutes}m`,
    distance:
      distanceInM > 1000
        ? `${(distanceInM / 1000).toFixed(1)} km`
        : `${parseInt(distanceInM).toFixed(0)} m`,
  };
};

export const handleInviteFriend = async (
  inviteLink,
  loggedInUser,
  setInviteLink
) => {
  if (!navigator) {
    return toast.error("Sharing is not supported by your browser");
  }

  // Check if the browser supports sharing links
  if (!navigator?.canShare || !navigator?.share) {
    return toast.error(
      "Woops, looks like your browser doesn't support sharing links."
    );
  }

  let url;
  const title = "Invite a friend";
  const text = "Join me on Momentify > ";
  const files = [];

  if (inviteLink?.id) {
    url = `/profile/invitation/${inviteLink.id}`;
  } else {
    const createdInvite = await setInviteFriendAction({
      user_id: loggedInUser.id,
    });
    setInviteLink(createdInvite?.data?.action);
    url = `/profile/invitation/${createdInvite?.data?.action?.id}`;
  }

  // Set the shared files
  const sharedFiles = files.length ? files : [];
  const shareData = { title, text, url };
  if (sharedFiles.length) shareData.files = sharedFiles;

  // Check if the data can be shared by the browser
  if (!navigator.canShare(shareData)) {
    return toast.error(
      "The data you're trying to share is not supported by your browser"
    );
  }

  // Share the data using the browser's share API
  await navigator.share(shareData).catch((err) => console.warn(err));
};

export const truncateToTwoDecimals = (number) => {
  // Convert the number to a string
  const numberStr = number.toString();

  // Find the index of the decimal point
  const decimalIndex = numberStr.indexOf(".");

  // Extract the part of the string up to two decimal places
  const truncatedStr =
    decimalIndex !== -1
      ? numberStr.slice(0, decimalIndex + 3) // Include decimal point and two digits after it
      : numberStr; // No decimal point found

  // Convert the result back to a number
  return parseFloat(truncatedStr);
};

export const stringToColour = (str) => {
  if (!str) return "#000000";

  let hash = 0;
  str?.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 8) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

export const getCreatedAtAndCreatedAtOffset = () => {
  const TIME_LOCAL_FORMAT = "YYYY-MM-DD HH:mm:ss";
  const TIME_LOCAL_FORMAT2 = "YYYY-MM-DD HH:mm:ssZ";

  const _timestampz = moment().format(TIME_LOCAL_FORMAT2);
  const created_at = moment().format(TIME_LOCAL_FORMAT);
  const created_at_offset = _timestampz.slice(
    _timestampz.indexOf("+") + 1,
    _timestampz.indexOf("+") + 3
  );

  return { created_at, created_at_offset };
};

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  if (minutes > 0) {
    return `${minutes}m`;
  } else {
    return `${remainingSeconds}s`;
  }
};

export const formatPrice = (price, isArray = true) => {
  if (!price || isNaN(price) || price < 0)
    return isArray ? ["0", "00"] : "0.00";

  // Round to 2 decimal places
  price = Number(price);
  let formattedPrice = (Math.round(price * 100) / 100).toFixed(2);

  if (isArray) {
    return formattedPrice ? formattedPrice.toString().split(".") : ["0", "00"];
  }

  return formattedPrice;
};

export const formatVideoLength = (length) => {
  const minutes = Math.floor(length / 60);
  const seconds = Math.floor(length % 60);
  // Format minutes and seconds to ensure they are 2 digits
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const toTitleCase = (str) => {
  if (!str || typeof str !== "string") return "";

  return str
    .toLowerCase()
    .split(/[_-]/) // Split by either underscore or hyphen
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const trimAddress = (address) => {
  if (!address) return "";

  return `${address?.slice(0, 4)}...${address?.slice(-4)}`;
};

export const trimMobileNumber = (address) => {
  if (!address) return "";

  return `${address?.slice(0, 3)}...${address?.slice(-4)}`;
};

export const replaceSpacesToDashes = (str) => {
  return str?.replaceAll(" ", "_")?.replaceAll("/", "_")?.replaceAll(".", "_");
};

export function isBase64(str) {
  if (!str || typeof str !== "string") {
    return false; // Not a string
  }

  const base64Regex =
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

  // Check length is a multiple of 4
  if (str.length % 4 !== 0) {
    return false;
  }

  // Test against regex
  return base64Regex.test(str);
}

export function hasExceeded15Seconds(startTime) {
  const elapsedTime = Date.now() - startTime;
  return elapsedTime > 50000; // 15 seconds in milliseconds
}

export function isValidJsonString(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

export function generateCustomKey() {
  const timestamp = Date.now().toString(36); // Base36 timestamp
  const randomPart = Math.random().toString(36).substring(2, 8); // 6 random chars
  return `${timestamp}${randomPart}`;
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";

  // Also make eveyrthing lowercase except the first letter
  return string
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
