import { useParams } from "react-router-dom";
import TopNav from "../../components/TopNav";
import { NavBarTypes } from "../../constants/constants";
import { Section } from "../../GlobalStyles";
import ErrorPage from "../Error";
import CollectibleHeader from "../../components/CollectiblePage/CollectibleHeader";
import CollectibleArtist from "../../components/CollectiblePage/CollectibleArtist";
import CollectibleDescription from "../../components/CollectiblePage/CollectibleDescription";
import CollectibleAdditionalInfo from "../../components/CollectiblePage/CollectibleAdditionalInfo";
import CollectibleTitle from "../../components/CollectiblePage/CollectibleTitle";
import { useEffect } from "react";
import { useBuyCollectibleStore } from "./stores/buyCollectibleStore";
import CollectiblePurchase from "../../components/CollectiblePage/CollectiblePurchase";
import { Container } from "../Collectible/Collectible-Styles";
import {
  useCheckUserCollectiblesQuery,
  usePaidCollectibleQuery,
} from "./paidCollectiblesQueries";
import BuyCollectibles from "../../components/BuyCollectibles";
import { useCheckoutStore } from "../../components/Checkout/checkoutStore";
import CollectibleAsset from "../../components/CollectiblePage/CollectibleAsset";
import { useBuyTicketStore } from "../../components/BuyTicket/buyTicketStore";
import { useGetGasEstimates } from "../../components/Checkout/hooks/checkoutQueries";
import useAuth from "../../hooks/useAuth";

export default function PaidCollectible() {
  const { collectibleId } = useParams();
  const { loggedInUser } = useAuth();
  const { reset: resetBuyTicketStore } = useBuyTicketStore();
  const { setCollectible, isOpen, reset, setUserCollectibles } =
    useBuyCollectibleStore();
  const { reset: resetCheckoutStore, setEstimatedGas } = useCheckoutStore();
  const { data: gas, isLoading: isGasLoading } = useGetGasEstimates();
  const { data: userCollectibles, isLoading: isUserCollectiblesLoading } =
    useCheckUserCollectiblesQuery({
      userId: loggedInUser?.id,
      subCollectionId: collectibleId,
    });
  const { data, isLoading: isPaidCollectibleLoading } = usePaidCollectibleQuery(
    {
      collectibleId,
    }
  );

  const isLoading =
    isPaidCollectibleLoading || isUserCollectiblesLoading || isGasLoading;

  const {
    image,
    video,
    edition_name,
    artist_id,
    description,
    chain,
    symbol,
    combined_artists,
  } = data || {};

  useEffect(() => {
    if (!data) return;

    reset();
    resetCheckoutStore();
    resetBuyTicketStore();

    setCollectible({
      ...data,
      id: collectibleId,
      token_id: data?.artists_main_collection_mints?.[0]?.token_id,
    });
    setEstimatedGas(gas);
    setUserCollectibles(userCollectibles);
  }, [
    data,
    setCollectible,
    reset,
    resetCheckoutStore,
    collectibleId,
    resetBuyTicketStore,
    gas,
    userCollectibles,
    setUserCollectibles,
    setEstimatedGas,
  ]);

  if (!isLoading && !data) {
    return <ErrorPage description="We can't find this collectible." />;
  }

  if (isOpen) {
    return <BuyCollectibles />;
  }

  return (
    <Section>
      <TopNav type={NavBarTypes.COLLECTIBLES} />
      <Container style={{ padding: "0 20px 16px" }}>
        <CollectibleAsset
          image={image}
          video={video}
          chain={chain}
          isLoading={isLoading}
        />
        <CollectibleTitle edition_name={edition_name} isLoading={isLoading} />
        <CollectibleArtist
          artist={{
            artist_id,
            ...combined_artists,
          }}
          isLoading={isLoading}
        />
        <CollectiblePurchase isLoading={isLoading} />
        {/* <CollectibleEligibility isLoading={isLoading} /> */}
        <CollectibleDescription
          symbol={symbol}
          description={description}
          isLoading={isLoading}
        />
        <CollectibleHeader collectible={data} isLoading={isLoading} />
        <CollectibleAdditionalInfo collectible={data} isLoading={isLoading} />
      </Container>
    </Section>
  );
}
