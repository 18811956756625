import PropTypes from "prop-types";
import { MintsContainer } from "./Mints-Styles";
import MintItem from "../MintItem";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import EmptyState from "../../EmptyState";
import TwoTickets from "../../../assets/svg/two-tickets.svg?react";

export default function Mints({ data, isLoading }) {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");
  const type = searchParams.get("type");

  const mintsToShow = useMemo(() => {
    if (!query && !type) return data;

    if (!query && type) {
      return data?.filter((mint) => {
        return mint?.type?.toLowerCase() === type?.toLowerCase();
      });
    }

    if (query && !type) {
      return data?.filter((mint) => {
        return (
          mint?.users?.username?.toLowerCase().includes(query?.toLowerCase()) ||
          mint?.users?.mobile_number
            ?.toLowerCase()
            .includes(query?.toLowerCase()) ||
          mint?.type?.toLowerCase().includes(query?.toLowerCase())
        );
      });
    }

    if (query && type) {
      return data?.filter((mint) => {
        return (
          (mint?.users?.username
            ?.toLowerCase()
            .includes(query?.toLowerCase()) ||
            mint?.users?.mobile_number
              ?.toLowerCase()
              .includes(query?.toLowerCase())) &&
          mint?.type?.toLowerCase() === type?.toLowerCase()
        );
      });
    }
  }, [data, query, type]);

  if (mintsToShow?.length === 0) {
    return (
      <EmptyState
        icon={<TwoTickets height={24} width={24} stroke="white" />}
        title="No Tickets Found"
        description="Try searching for a username or mobile number."
        style={{
          paddingTop: "50%",
        }}
      />
    );
  }

  return (
    <MintsContainer>
      {isLoading &&
        [...Array(12)].map((_, index) => <MintItem key={index} isLoading />)}
      {mintsToShow?.length > 0 &&
        mintsToShow.map((mint) => <MintItem mint={mint} key={mint.id} />)}
    </MintsContainer>
  );
}

Mints.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};
