import { ImageContainer } from "../../../routes/Collectible/Collectible-Styles";
import PropTypes from "prop-types";

import QRCode from "react-qr-code";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import Monkey from "../../../assets/svg/see-no-evil-monkey.svg?react";

export default function TicketQRImage({ data, error }) {
  return (
    <ImageContainer
      style={{
        maxWidth: "288px",
        maxHeight: "288px",
        minHeight: "288px",
        height: "100%",
        position: "relative",
      }}
    >
      {error ? (
        <Monkey
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          height={40}
          width={40}
        />
      ) : !data ? (
        <SkeletonElement height="100%" width="100%" />
      ) : (
        <QRCode
          style={{
            padding: "23px",
          }}
          size={288}
          value={JSON.stringify(data)}
        />
      )}
    </ImageContainer>
  );
}

TicketQRImage.propTypes = {
  data: PropTypes.object,
  error: PropTypes.any,
};
