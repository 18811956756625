import { create } from "zustand";

export const useTicketTransferStore = create((set) => ({
  ticket: null,
  setTicket: (ticket) => set(() => ({ ticket })),
  selectedUsers: [],
  toggleSelectedUser: (user) =>
    set(({ selectedUsers }) => ({
      selectedUsers: selectedUsers.find((u) => u.id === user.id)
        ? selectedUsers.filter((u) => u.id !== user.id)
        : [...selectedUsers, user],
    })),
  clearSelectedUsers: () => {
    set(() => ({
      selectedUsers: [],
    }));
  },
  reset: () => {
    set(() => ({
      ticket: null,
      selectedUsers: [],
    }));
  },
}));
