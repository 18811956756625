import PropTypes from "prop-types";
import {
  Column,
  Container,
  UserImage,
} from "../../BackstageManualCheckin/MintItem/MintItem-Styles";
import { BodyText, FooterText, IconWithText } from "../../../GlobalStyles";
import { AvatarPlaceholder } from "../../../constants/constants";
import Phone from "../../../assets/svg/phone.svg?react";
import { capitalizeFirstLetter, trimMobileNumber } from "../../../utils/utils";
import { theme } from "../../../utils/theme";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import moment from "moment";

const { colors } = theme;

export default function ScanItem({ scan, isLoading }) {
  const { created_at, status, users, tickets_contracts_mints } = scan || {};
  const { avatar, username, mobile_number } = users || {};
  const { type, token_id, is_transferred } = tickets_contracts_mints || {};

  if (isLoading) {
    return (
      <Container>
        <Column>
          <SkeletonElement height="17px" />
          <SkeletonElement height="17px" />
        </Column>
        <Column>
          <SkeletonElement height="17px" />
          <SkeletonElement height="17px" />
        </Column>
        <Column>
          <SkeletonElement height="17px" />
          <SkeletonElement height="17px" />
        </Column>
      </Container>
    );
  }

  return (
    <Container>
      <Column>
        <BodyText bold secondary truncate>
          {type}
        </BodyText>
        <BodyText truncate>#{token_id}</BodyText>
      </Column>
      <Column>
        <IconWithText gap="8px">
          <UserImage
            src={avatar || AvatarPlaceholder}
            onError={(e) => (e.target.src = AvatarPlaceholder)}
          />
          <FooterText truncate>{username}</FooterText>
        </IconWithText>
        <IconWithText gap="8px">
          <Phone height={14} width={14} opacity={0.6} />
          <FooterText secondary truncate>
            {mobile_number ? trimMobileNumber(mobile_number) : "N/A"}
          </FooterText>
        </IconWithText>
        <FooterText secondary>
          {is_transferred ? "Transferred" : "Purchased"}
        </FooterText>
      </Column>
      <Column style={{ alignItems: "flex-end" }}>
        <FooterText secondary>{moment(created_at).format("h:mm A")}</FooterText>
        <FooterText
          style={{
            color:
              status?.toLowerCase() === "success" ? colors.green : colors.red,
          }}
        >
          {capitalizeFirstLetter(status)}
        </FooterText>
      </Column>
    </Container>
  );
}

ScanItem.propTypes = {
  scan: PropTypes.object,
  isLoading: PropTypes.bool,
};
