import PropTypes from "prop-types";
import { BodyText } from "../../GlobalStyles";
import { Container } from "./BackstageEvents-Styles";
import EventItem from "../Events/EventItem";
import { SearchSkeleton } from "../../routes/Search/Search-Styles";
import { useBackstageSearchStore } from "../../routes/BackstageSearch/stores/backstageSearchStore";
import { useMemo } from "react";
import EmptyState from "../EmptyState";
import Bookmark from "../../assets/svg/bookmark.svg?react";

export default function BackstageEvents({ type, events, isLoading }) {
  const { type: selectedType } = useBackstageSearchStore();
  const isAll = selectedType === "all";

  const eventsToRender = useMemo(() => {
    if (isAll) {
      return events?.slice(0, 3);
    }
    return events;
  }, [events, isAll]);

  if (!events?.length && !isLoading) {
    return (
      <Container>
        <BodyText
          style={{
            textTransform: "capitalize",
          }}
          bold
          secondary
        >
          {type} events
        </BodyText>
        <EmptyState
          icon={<Bookmark height={24} width={24} stroke="white" />}
          title={`No ${type} events`}
          description={`No ${type} events found.`}
          style={{
            padding: isAll ? "16px 20px" : "50% 20px 16px",
            flex: isAll ? 0 : 1,
          }}
        />
      </Container>
    );
  }

  return (
    <Container>
      <BodyText
        style={{
          textTransform: "capitalize",
        }}
        bold
        secondary
      >
        {type} events
      </BodyText>
      {isLoading ? (
        <SearchSkeleton style={{ padding: "0" }} count={isAll ? 3 : 12} />
      ) : (
        eventsToRender?.map((event) => (
          <EventItem key={event?.id} event={event} isBackstage />
        ))
      )}
    </Container>
  );
}

BackstageEvents.propTypes = {
  type: PropTypes.string,
  events: PropTypes.array,
  isLoading: PropTypes.bool,
};
