import moment from "moment";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import BookmarkIcon from "../../../assets/svg/bookmark.svg?react";
import MapIcon from "../../../assets/svg/map.svg?react";
import { BodyText, IconWithText, OneLineText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import EventCountdown from "../../Events/EventCountdown";
import InterestedButton from "../../Events/InterestedButton";
import { DivisionContainer } from "./EventInfo-Styles";
import useAuth from "../../../hooks/useAuth";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

const { colors } = theme;

export default function EventInfo({ event, isLoading }) {
  const { loggedInUser } = useAuth();
  const location = useLocation();
  const eventDate = location.pathname.includes("backstage")
    ? moment(event?.event_start_time ?? event?.date).utc()
    : moment(event?.event_start_time ?? event?.date);

  const handleInterestedButtonVisibility = () => {
    const presentDate = new Date();
    const dateEvent = new Date(eventDate);

    if (presentDate > dateEvent) return false;

    if (loggedInUser) return true;

    return false;
  };

  if (isLoading) {
    return (
      <DivisionContainer gap="8px">
        <IconWithText>
          <SkeletonElement height="22px" width="22px" radius="50%" />
          <SkeletonElement height="18px" width="200px" />
        </IconWithText>
        <IconWithText>
          <SkeletonElement height="22px" width="22px" radius="50%" />
          <SkeletonElement height="18px" width="90px" />
          <SkeletonElement height="18px" width="80px" />
          <SkeletonElement height="18px" width="75px" />
        </IconWithText>
      </DivisionContainer>
    );
  }

  return (
    <DivisionContainer direction="row" gap="16px" justify="space-between">
      {/* Venue and Date */}
      <DivisionContainer gap="4px">
        <IconWithText
          as={event?.combined_venue_id ? Link : "div"}
          to={`/venues/${event?.combined_venue_id}/${event?.venue}`}
        >
          <div>
            <MapIcon fill={colors.white} height={16} width={16} />
          </div>
          <OneLineText>{`${event?.venue || event?.venue_name}, ${event?.city || event?.city_name}`}</OneLineText>
        </IconWithText>
        <DivisionContainer direction="row" gap="12px" align="center">
          <IconWithText>
            <BookmarkIcon
              stroke={colors.white}
              fill={colors.white}
              height={16}
              width={16}
            />
            <BodyText color={colors.softGreen}>
              {eventDate.format("ddd D MMM")}
            </BodyText>
            <BodyText color={colors.grey}>{eventDate.format("LT")}</BodyText>
          </IconWithText>
          <DivisionContainer align="flex-end">
            <EventCountdown date={eventDate} />
          </DivisionContainer>
        </DivisionContainer>
      </DivisionContainer>
      {handleInterestedButtonVisibility() && (
        <DivisionContainer>
          <InterestedButton event={event} size={20} />
        </DivisionContainer>
      )}
    </DivisionContainer>
  );
}

EventInfo.propTypes = {
  event: PropTypes.object,
  isLoading: PropTypes.bool,
};
