import styled from "styled-components";
import { Button } from "../../../GlobalStyles";

export const Container = styled.div`
  position: relative;
`;

export const Video = styled.video`
  max-height: calc(350px - 32px);
  max-width: calc(350px - 32px);
  object-fit: cover;
  align-self: center;
  border-radius: 16px;
`;

export const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  cursor: pointer;
`;

export const MuteButton = styled(Button)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  border: none;
  cursor: pointer;
`;
