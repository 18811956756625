import styled from "styled-components";

export const SearchResultsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  filter: ${({ blurred }) => (blurred ? "blur(10px) brightness(70%)" : "none")};
  pointer-events: ${({ blurred }) => (blurred ? "none" : "auto")};
  padding: 16px 20px;
`;

export const FilterButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;
