import PropTypes from "prop-types";
import { BodyText, HeaderL } from "../../../GlobalStyles";
import {
  DetailsContainer,
  DetailsContentContainer,
  VerticalLine,
} from "./BackstageEventDetails-Styles";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export default function BackstageEventDetails({ details, isLoading }) {
  if (isLoading) {
    return <SkeletonElement height="98.5px" radius="8px" />;
  }

  if (details?.length) {
    return (
      <DetailsContainer>
        <DetailsContentContainer>
          <BodyText bold secondary>
            {details?.[0]?.header}
          </BodyText>
          <HeaderL>{details?.[0]?.content}</HeaderL>
        </DetailsContentContainer>
        <VerticalLine />
        <DetailsContentContainer>
          <BodyText bold secondary>
            {details?.[1]?.header}
          </BodyText>
          <HeaderL>{details?.[1]?.content}</HeaderL>
        </DetailsContentContainer>
      </DetailsContainer>
    );
  }

  const { header, content } = details || {};

  return (
    <DetailsContentContainer isContainer>
      <BodyText bold secondary>
        {header}
      </BodyText>
      <HeaderL>{content}</HeaderL>
    </DetailsContentContainer>
  );
}

BackstageEventDetails.propTypes = {
  details: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool,
};
