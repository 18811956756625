import AnimatedDropdown from "../../Dropdown/AnimatedDropdown";
import { theme } from "../../../utils/theme";
import { Content, Header, Item } from "./TotalSummary-Styles";
import { BodyText, HeaderL, HeaderSm } from "../../../GlobalStyles";
import { formatPrice } from "../../../utils/utils";
import { useCheckoutStore } from "../checkoutStore";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";

const { colors } = theme;

export default function TotalSummary() {
  const { total, ticketsTotal, collectiblesTotal, momentifyFee } =
    useCheckoutStore();
  const { ticketsSelected, isOpen: ticketsOpen } = useBuyTicketStore();
  const { collectiblesSelected } = useBuyCollectibleStore();

  const formattedTotal = formatPrice(total);
  const formattedSelectedTotal = formatPrice(
    (ticketsOpen ? ticketsTotal : collectiblesTotal) + momentifyFee,
    false
  );
  const free =
    total === 0 &&
    (ticketsOpen
      ? ticketsSelected?.length > 0
      : collectiblesSelected?.length > 0);

  return (
    <AnimatedDropdown
      header={
        <Header>
          <BodyText>Purchase total</BodyText>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            {free ? (
              <HeaderL lineHeight="1">FREE!</HeaderL>
            ) : (
              <>
                <HeaderL lineHeight="1">${formattedTotal[0]}</HeaderL>
                <HeaderSm>.{formattedTotal[1]}</HeaderSm>
              </>
            )}
          </div>
        </Header>
      }
      content={
        <Content>
          <Item>
            <BodyText secondary>
              {ticketsOpen ? "Tickets" : "Collectibles"} + fees
            </BodyText>
            <BodyText>
              {free ? "it's FREE!" : `$${formattedSelectedTotal}`}
            </BodyText>
          </Item>
        </Content>
      }
      bg={colors.glass}
      isHeaderCenter
    />
  );
}
