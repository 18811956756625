import PropTypes from "prop-types";
import { Container } from "./TicketFilter-Styles";
import { useSearchParams } from "react-router-dom";
import { BodyText } from "../../../GlobalStyles";
import { useTicketFiltersStore } from "../../../routes/BackstageEvent/stores/ticketFiltersStore";
import { capitalizeFirstLetter } from "../../../utils/utils";

export default function TicketFilter({ type }) {
  const { setIsOpen, filterType } = useTicketFiltersStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const typeParam = searchParams.get("type");
  const statusParam = searchParams.get("status");
  const selected = typeParam === type || statusParam === type;

  const text = filterType === "status" ? capitalizeFirstLetter(type) : type;

  const handleSelect = () => {
    if (selected) {
      searchParams.delete(filterType);
    } else {
      searchParams.set(filterType, type);
    }

    setSearchParams(searchParams);

    setIsOpen(false);
  };

  return (
    <Container onClick={handleSelect} selected={selected}>
      <BodyText>{text}</BodyText>
    </Container>
  );
}

TicketFilter.propTypes = {
  type: PropTypes.string,
};
