import { useEffect } from "react";
import { BodyText, ButtonGhost, ButtonRect, FooterText, HeaderSm, HeaderXxl, IconWithText, Subheader } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import {
  Container,
  HeaderContainer,
  RecordsContainer,
  UserIcon,
} from "./ScannedTicketModal-Styles";
import PropTypes from "prop-types";
import useGlobalModal from "../../../hooks/useGlobalModal";
import CheckBoxIcon from "../../../assets/svg/checkbox2.svg?react";
import CloseBoxIcon from "../../../assets/svg/closebox.svg?react";
import EventItem from "../../Events/EventItem";
import { useNavigate } from "react-router-dom";
import { AvatarPlaceholder } from "../../../constants/constants";


export default function ScannedTicketModal({ ticket, isValid, setScanned, isEventDateIncorrect, isScannedAlready, isExpired, isInvalidKey }) {
  const navigate = useNavigate();
  const { toggleSheetModal, isSheetModalClosed } = useGlobalModal();

  const handleClose = async () => {
    setScanned()
    toggleSheetModal();
  };

  useEffect(() => {
    if (isSheetModalClosed) {
      setScanned()
      return
    }

  }, [isSheetModalClosed])

  const ticketDetails = [
    {
      label: "Ticket type",
      value: ticket?.type ?? ''
    },
    {
      label: "Ticket owner",
      value: {
        avatar: ticket?.user_avatar ?? '',
        username: ticket?.username ?? ''
      }
    },
    {
      label: "Wallet",
      value: ticket?.user_wallet ? `${ticket.user_wallet.slice(0, 6)}...${ticket.user_wallet.slice(-4)}` : ''
    },
    {
      label: "Ticket no.",
      value: ticket?.token_id ?? ''
    },
  ]

  return (
    <Container>
      <RecordsContainer>
        <HeaderContainer>
          {
            isValid ? <CheckBoxIcon width={40} height={40} stroke={theme.colors.green} /> : <CloseBoxIcon width={40} height={40} stroke={theme.colors.red} />
          }
          <HeaderXxl style={{ textTransform: "uppercase" }}>{isValid ? "ticket scanned" : "scanning failed"}</HeaderXxl>
        </HeaderContainer>
        {
          ticket && (
            <div style={{ margin: '0 auto' }}>
              <EventItem key={ticket?.event_id} event={{
                date: ticket?.detail?.date,
                image: ticket?.detail?.image,
                venue_name: ticket?.detail?.venue_name,
                headline_artist: ticket?.detail?.headline_artist,
                country_name: ticket?.detail?.country_name
              }} isBackstage />
            </div>
          )
        }
        {
          isValid ? (
            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              width: '100%'
            }}>
              {
                ticketDetails.map((item, index) => (
                  <div key={index} style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: '100%'
                  }}>
                    <BodyText secondary>{item.label}</BodyText>
                    {
                      index == 0
                        ? <HeaderSm style={{ fontSize: 21.7 }}> {item.value?.toUpperCase()} </HeaderSm>
                        : index == 1
                          ? (
                            <IconWithText gap="8px">
                              <UserIcon
                                src={item.value.avatar || AvatarPlaceholder}
                                onError={(e) => (e.target.src = AvatarPlaceholder)}
                              />
                              <FooterText>{item.value.username}</FooterText>
                            </IconWithText>
                          )
                          : <BodyText secondary>{item.value}</BodyText>
                    }
                  </div>
                ))
              }
            </div>
          )
            : (
              <Subheader secondary>
                {
                  !ticket ? "This qr code is invalid." : isInvalidKey ? "This qr code is invalid." : isScannedAlready ? "This ticket has already been scanned." : isEventDateIncorrect ? "This event is not today." : isExpired ? "This qr code has expired." : "This qr code is invalid."
                }
              </Subheader>
            )
        }
        <div style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          alignItems: "center"
        }}>
          <ButtonRect primary style={{
            margin: "0 auto 0 auto",
            width: "220px",
            height: "37px"
          }} onClick={() => handleClose()}>{isValid ? "Finish" : "Try again"}</ButtonRect>
          <ButtonGhost onClick={() => {
            if (!isValid) {
              handleClose();
              navigate('/backstage/events')
            }
          }} style={{
            margin: "0 auto 0 auto",
            width: "220px",
            height: "37px"
          }} >{isValid ? "" : "Go back to Events"}</ButtonGhost>
        </div>
      </RecordsContainer>
    </Container>
  );
}

ScannedTicketModal.propTypes = {
  ticket: PropTypes.object,
  isValid: PropTypes.bool,
  setScanned: PropTypes.func,
  isEventDateIncorrect: PropTypes.bool,
  isScannedAlready: PropTypes.bool,
  isExpired: PropTypes.bool,
  isInvalidKey: PropTypes.bool,
};
