import PropTypes from "prop-types";
import { Container } from "../../../routes/Collectible/Collectible-Styles";
import { BodyText } from "../../../GlobalStyles";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export default function CollectibleDescription({
  description,
  symbol,
  isLoading,
}) {
  const isFP = symbol === "FP";

  const foundersPassDesc =
    "Holders of the Founder's Pass have a share in the ownership of Momentify Ltd. Only 500 will ever be claimable. Entitles holders to additional benefits and including $MOMENTS token airdrop multipliers.";

  if (isLoading) {
    return (
      <Container style={{ gap: "8px" }}>
        <SkeletonElement height="14px" width="100px" />
        <SkeletonElement height="14px" width="100%" />
        <SkeletonElement height="14px" width="100%" />
        <SkeletonElement height="14px" width="50%" />
      </Container>
    );
  }
  return (
    <Container style={{ gap: "8px" }}>
      <BodyText bold>Description</BodyText>
      <BodyText secondary>
        {isFP ? foundersPassDesc : description || "No description"}
      </BodyText>
    </Container>
  );
}

CollectibleDescription.propTypes = {
  description: PropTypes.string,
  symbol: PropTypes.string,
  isLoading: PropTypes.bool,
};
