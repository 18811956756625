import { useQuery } from "@tanstack/react-query";
import { getBackstageEventScans } from "../../../api/backstage";

export const useBackstageEventScansQuery = ({ eventId }) => {
  return useQuery({
    queryKey: ["backstage_event_scans", { eventId }],
    queryFn: () => {
      return getBackstageEventScans({ eventId });
    },
    enabled: !!eventId,
  });
};
