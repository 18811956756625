import styled from "styled-components";
import PropTypes from "prop-types";
import { BottomNavBarHeight } from "../../constants/constants";
import {
  ColumnFlex,
  RowFlex,
} from "../../components/Events/YourEvent/YourEvent-Style";
import { SkeletonElement } from "../../components/Skeleton/Skeleton-Style";

export const SearchContainer = styled.div`
  height: calc(100dvh - ${BottomNavBarHeight});
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: ${({ theme }) => theme.colors.backgroundOverlay};
  background-color: ${({ theme }) => theme.colors.background};
  background-blend-mode: multiply;
  z-index: 998;
  overflow: hidden;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px 20px 0 20px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  position: sticky;
  top: 0;
  left: 0;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 12px;
  gap: 12px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.searchGrey};
  align-items: center;
  justify-content: space-between;
`;

export const SearchInput = styled.input`
  display: flex;
  border: none;
  background: transparent;
  width: 100%;
  color: white;

  &:focus,
  &:active {
    outline: none;
  }
`;

export const SearchSkeleton = ({ style, count = 12 }) => {
  return (
    <ColumnFlex style={{ padding: "0 20px 16px 20px", ...style }}>
      {Array.from({ length: count }).map((_, index) => (
        <RowFlex key={index}>
          <SkeletonElement
            width="58px"
            height="58px"
            radius="10px"
            backgroundColor="#ffffff14"
          />
          <ColumnFlex style={{ gap: "6px" }}>
            <SkeletonElement
              style={{ marginBottom: "12px" }}
              width="80px"
              height="10px"
              backgroundColor="#ffffff14"
            />
            <SkeletonElement
              backgroundColor="#ffffff14"
              width="200px"
              height="10px"
            />
            <SkeletonElement
              backgroundColor="#ffffff14"
              width="120px"
              height="10px"
            />
          </ColumnFlex>
        </RowFlex>
      ))}
    </ColumnFlex>
  );
};

SearchSkeleton.propTypes = {
  style: PropTypes.object,
  count: PropTypes.number,
};
