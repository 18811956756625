import { useQuery } from "@tanstack/react-query";
import { getBackstageEvent } from "../../../api/backstage";

export const useBackstageEventQuery = ({ eventId, date }) => {
  return useQuery({
    queryKey: ["backstage_event", { eventId, date }],
    queryFn: () => {
      return getBackstageEvent({ eventId, date });
    },
    enabled: !!eventId,
  });
};
