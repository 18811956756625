import styled from "styled-components";

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  background: ${({ theme }) => theme.colors.blue};
  padding: 16px 24px;
  border-radius: 8px;
`;

export const DetailsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  background: ${({ theme, isContainer }) =>
    isContainer ? theme.colors.blue : "transparent"};
  padding: ${({ isContainer }) => (isContainer ? "16px 24px" : "0")};
  border-radius: 8px;
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 53px;
  background: #ffffff26;
`;
