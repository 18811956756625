import PropTypes from "prop-types";
import styled from "styled-components";
import {
  BodyText,
  ButtonGhost,
  ButtonRectSm,
  FooterText,
  FooterTextSm,
} from "../../GlobalStyles";
import { theme } from "../../utils/theme";
import moment from "moment";
import ThreeDots from "../../assets/svg/three-dots.svg?react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import NotificationDropdownContent from "../../components/Dropdown/NotificationDropdownContent";
import { useEffect, useRef, useState } from "react";
import { readUserMessage } from "../../api/profile";
import useCheckIn from "../../hooks/useCheckIn";
import { getOneEvent, getOneEventWithLocation } from "../../api/events";
import BookmarkIcon from "../../assets/svg/bookmark.svg?react";
import useCheckInModalToggle from "../../hooks/useCheckInModalToggle";
import { SkeletonElement } from "../Skeleton/Skeleton-Style";
import { EventPlaceholder, MomentifyLogo } from "../../constants/constants";
import { toast } from "react-toastify";

const { colors } = theme;

const Container = styled.div`
  background: ${({ isUnread }) => (isUnread ? theme.colors.blueShade : "none")};
  border-left: ${({ isUnread }) =>
    isUnread ? `2px solid ${theme.colors.green}` : "none"};
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 8px 20px;
`;

const ContainerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  padding: 0 6px;
  width: 100%;
`;

const BadgeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const BadgeImageWrapper = styled.div`
  height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 50%;
  align-self: start;
  overflow: hidden;
`;

const BadgeWrapper = styled.div`
  position: relative;
  align-self: start;
`;

const BadgeTinyIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100px;
  padding: 4px;
  position: absolute;
  right: -8px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.red};
  z-index: 1;
`;

const BadgeInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  position: relative;
`;

const BadgeNameAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BadgeDate = styled.div`
  display: flex;
  align-items: center;
  min-width: 75px;
`;

const DotsButton = styled(ButtonGhost)`
  border: none;
  background: transparent;
  padding: 0 3px 3px 3px;
  justify-self: start;
`;

const ActionButton = styled(ButtonRectSm)`
  height: 26px;
  width: 74px;
`;

const MessageSpan = styled(BodyText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 21px;
  font-weight: 600;
`;

export default function NotificationItem({
  notification,
  handlePopSelectedItem,
  index,
  isLoading,
  refetch,
}) {
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  let {
    notification_type,
    title,
    img,
    created_at,
    created_at_offset,
    id,
    read_at,
    subject_type,
    subject_id,
    subject_label,
    extra,
  } = notification;

  title = title.replace(`${subject_label}.!`, "");

  const { setCurrentEvent, handleBlindCheckinEvent } = useCheckIn();
  const { handleToggle } = useCheckInModalToggle();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();
  const [isItemRead, setIsItemRead] = useState(!!read_at);

  const handleDropdownItemClick = (event) => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      return;
    }

    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleViewMessageClick = async () => {
    await readUserMessage(id);
    refetch();
    navigate(`/profile/${loggedInUser?.id}/messages`);
  };

  const handleReadClick = async () => {
    await readUserMessage(id);
    refetch();
    if (!isItemRead) {
      setIsItemRead(true);
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const setEventWithLocation = async (eventId) => {
    const date_filter = extra?.subject_date
      ? moment(extra?.subject_date).format("YYYY-MM-DDTHH:mm:ss")
      : moment(created_at).format("YYYY-MM-DDTHH:mm:ss");

    const event = await getOneEvent(eventId, loggedInUser?.id, date_filter);

    const eventWithLocation = await getOneEventWithLocation(
      eventId,
      event.latitude,
      event.longitude,
      date_filter
    );

    await setCurrentEvent(eventWithLocation);

    handleToggle();

    if (!eventWithLocation) {
      toast.error(
        "An error occurred when trying to check in. Please try again later."
      );
      return;
    }

    // handleToggle();
    await handleBlindCheckinEvent(eventWithLocation);
  };

  const _timestamp = created_at_offset
    ? moment.parseZone(`${created_at}+${created_at_offset}`).local()
    : null;
  const eventFinished = created_at_offset
    ? _timestamp.format("DD") == moment().format("DD") &&
      moment().format("HH") < 23
    : moment(created_at).format("DD") == moment().format("DD") &&
      moment().format("HH") < 23;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Container index={index}>
      <ContainerContent>
        {isLoading ? (
          <BadgeWrapper>
            <SkeletonElement
              width="40px"
              height="40px"
              radius="50%"
              style={{ minWidth: "40px", minHeight: "40px" }}
            />
          </BadgeWrapper>
        ) : (
          <BadgeWrapper>
            {subject_type == "interested_event" && (
              <BadgeTinyIconWrapper>
                <BookmarkIcon
                  width={7}
                  height={10}
                  stroke={theme.colors.white}
                />
              </BadgeTinyIconWrapper>
            )}
            <BadgeImageWrapper>
              <BadgeImage
                src={subject_label == "report" ? MomentifyLogo : img}
                onError={(e) => (e.target.src = EventPlaceholder)}
              />
            </BadgeImageWrapper>
          </BadgeWrapper>
        )}

        {isLoading ? (
          <BadgeInfo>
            <BadgeNameAndDescription
              style={{
                paddingRight: "30px",
              }}
            ></BadgeNameAndDescription>
            <MessageSpan>
              <SkeletonElement
                width="100%"
                height="16px"
                // radius="50%"
                style={{ minWidth: "40px", minHeight: "16px" }}
              />
            </MessageSpan>
            <BadgeDate>
              <FooterText>
                <SkeletonElement
                  width="100%"
                  height="10px"
                  // radius="50%"
                  style={{ minWidth: "80px", minHeight: "10px" }}
                />
              </FooterText>
            </BadgeDate>
          </BadgeInfo>
        ) : (
          <BadgeInfo>
            <BadgeNameAndDescription
              style={{
                paddingRight: "30px",
              }}
            >
              <MessageSpan>
                {subject_type == "interested_event" ? (
                  eventFinished ? (
                    <>
                      <span
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        {title}
                      </span>
                      {subject_label}
                    </>
                  ) : (
                    <>
                      {subject_label}'s
                      <span
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        event has ended.
                      </span>
                    </>
                  )
                ) : subject_label == "report" ? (
                  <span
                    style={{
                      fontWeight: 400,
                    }}
                  >
                    {title}{" "}
                    <span style={{ color: theme.colors.green }}>
                      {extra?.xp_reward}
                    </span>
                  </span>
                ) : subject_type == "airdrop" ? (
                  <>
                    <span
                      style={{
                        fontWeight: 400,
                      }}
                    >
                      {title}
                    </span>
                  </>
                ) : subject_type == "admin" ? (
                  <>
                    <span
                      style={{
                        fontWeight: 400,
                      }}
                    >
                      {title}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      style={{
                        fontWeight: 400,
                      }}
                    >
                      {title}
                    </span>
                    {subject_label}
                  </>
                )}
              </MessageSpan>
            </BadgeNameAndDescription>
            <BadgeDate>
              <FooterText color={colors.grey}>
                {created_at_offset
                  ? _timestamp.format("D MMM YYYY")
                  : moment(created_at).utc().format("D MMM YYYY")}
                <span
                  style={{
                    padding: "0 4px",
                  }}
                >
                  •
                </span>
                {created_at_offset
                  ? _timestamp.format("h:mm A")
                  : moment(created_at).utc().format("h:mm A")}
              </FooterText>
            </BadgeDate>
            {notification_type != "system" && !isItemRead && (
              <ActionButton onClick={handleViewMessageClick}>
                <BodyText>View message</BodyText>
              </ActionButton>
            )}
            {subject_type == "interested_event" ? (
              eventFinished ? (
                <ActionButton
                  background={theme.colors.green}
                  onClick={() => setEventWithLocation(subject_id)}
                  disabled={!subject_id}
                >
                  <FooterTextSm
                    weight="600"
                    color={theme.colors.white}
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Check in
                  </FooterTextSm>
                </ActionButton>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </BadgeInfo>
        )}
      </ContainerContent>
      {isLoading ? (
        <DotsButton>
          <SkeletonElement
            width="16px"
            height="16px"
            radius="50%"
            style={{ minWidth: "16px", minHeight: "16px" }}
          />
        </DotsButton>
      ) : (
        <DotsButton onClick={handleDropdownItemClick} index={index}>
          <ThreeDots height={16} width={16} />
          <span
            ref={dropdownRef}
            style={{
              position: "relative",
            }}
          >
            <NotificationDropdownContent
              isOpen={isDropdownOpen}
              isSystem={false}
              isRead={isItemRead}
              setIsDropdownOpen={setIsDropdownOpen}
              notification_id={notification?.id}
              handlePopSelectedItem={handlePopSelectedItem}
              handleReadClick={handleReadClick}
              index={index}
              refetch={refetch}
            />
          </span>
        </DotsButton>
      )}
    </Container>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
  index: PropTypes.number,
  handlePopSelectedItem: PropTypes.func,
  isLoading: PropTypes.bool,
  refetch: PropTypes.func,
};
