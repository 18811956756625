import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  max-height: 498px;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.black};
  position: relative;
  gap: 16px;
`;

export const FloatingFooter = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50px;
  margin: 0 auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: sticky;
  top: 0;
  left: 0;
  padding: 16px 16px 0px 16px;
  width: 100%;
`;

export const Line = styled.hr`
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  opacity: 0.5;
  width: calc(100% - 32px);
  align-self: center;
`;

export const RecordsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px;
  padding-top: 0;
  flex: 1;
`;

export const RecordContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
`;

export const RecordActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: ${({ theme }) => theme.fontStyles.suisse};
  font-size: ${({ theme }) => theme.fontSizes.mobile.body};
  color: ${({ theme }) => theme.colors.grey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 230px;

  svg {
    stroke: ${({ theme }) => theme.colors.grey};
  }

  span {
    font-family: ${({ theme }) => theme.fontStyles.suisse};
    font-size: ${({ theme }) => theme.fontSizes.mobile.body};
    color: ${({ theme }) => theme.colors.grey};
  }
`;
