import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { BodyText } from "../../../GlobalStyles";
import TicketTransferInput from "../TicketTransferInput";
import { Container } from "./TicketTransferUsers-Styles";
import { useSearchUsers } from "../../../routes/TicketTransfer/hooks/ticketTransferQueries";
import TicketTransferUsers from "../TicketTransferUsers";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";
import useAuth from "../../../hooks/useAuth";

export default function TicketTransferSearch({ isLoading: isPageLoading }) {
  const { loggedInUser } = useAuth();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");

  const { data, isLoading } = useSearchUsers({
    query: query || "",
    ownerId: loggedInUser?.id,
  });

  return (
    <Container>
      {isPageLoading ? (
        <SkeletonElement
          height="21px"
          width="70%"
          style={{ margin: "0 auto" }}
        />
      ) : (
        <BodyText
          bold
          style={{
            alignSelf: "center",
          }}
        >
          Who do you want to send it to?
        </BodyText>
      )}
      <TicketTransferInput isLoading={isPageLoading} />
      <TicketTransferUsers users={data} isLoading={isLoading} />
    </Container>
  );
}

TicketTransferSearch.propTypes = {
  isLoading: PropTypes.bool,
};
