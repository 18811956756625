import PropTypes from "prop-types";
import { ButtonRectSm, IconWithText } from "../../../GlobalStyles";
import { useTicketFiltersStore } from "../../../routes/BackstageEvent/stores/ticketFiltersStore";
import TicketIcon from "../../../assets/svg/ticket.svg?react";
import StatusIcon from "../../../assets/svg/status.svg?react";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { useSearchParams } from "react-router-dom";
import { X } from "@phosphor-icons/react";

export default function FilterButton({ filter, type }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setIsOpen, setFilterType } = useTicketFiltersStore();
  const isStatus = type === "status";

  const handleOpen = (filter) => {
    setIsOpen(true);
    setFilterType(filter);
  };

  const handleRemoveFilter = () => {
    searchParams.delete(type);
    setSearchParams(searchParams);
  };

  return (
    <ButtonRectSm
      primary={filter}
      style={{
        textTransform: filter ? "none" : "capitalize",
      }}
    >
      <IconWithText gap="8px" onClick={() => handleOpen(type)}>
        {isStatus ? (
          <StatusIcon height={16} width={16} stroke="white" />
        ) : (
          <TicketIcon height={16} width={16} stroke="white" />
        )}
        {filter ? (isStatus ? capitalizeFirstLetter(filter) : filter) : type}
      </IconWithText>
      {filter && <X height={14} width={14} onClick={handleRemoveFilter} />}
    </ButtonRectSm>
  );
}

FilterButton.propTypes = {
  filter: PropTypes.string,
  type: PropTypes.string,
};
