import PropTypes from "prop-types";
import { FooterText } from "../../../GlobalStyles";
import { theme } from "../../../utils/theme";
import { HeaderContainer } from "./CollectibleThumbnail-Styles";
import AirdropIcon from "../../../assets/svg/airdrop-icon.svg?react";
import EditionIcon from "../../../assets/svg/edition-icon.svg?react";
import CollectibleIcon from "../../CollectiblePage/CollectibleIcon";

const { colors } = theme;

export default function CollectibleThumbnailHeader({
  collectible,
  isExternal,
  notLink,
}) {
  const { id, type, edition_type, collectible_type, token_id, artist, price } =
    collectible || {};

  const isPaid = price >= 0 && !notLink;

  return (
    <HeaderContainer>
      <FooterText bold truncate>
        {isPaid ? artist : `#${token_id || id}`}
      </FooterText>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <CollectibleIcon type={type} isThumbnail />
        {edition_type === "edition" ||
        collectible_type === "edition" ||
        isExternal ? (
          <EditionIcon stroke={colors.grey} height={14} width={14} />
        ) : (
          <AirdropIcon stroke={colors.grey} />
        )}
      </div>
    </HeaderContainer>
  );
}

CollectibleThumbnailHeader.propTypes = {
  collectible: PropTypes.object,
  isExternal: PropTypes.bool,
  notLink: PropTypes.bool,
};
