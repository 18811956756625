import { Section } from "../../GlobalStyles";
import BackstageProfileFooter from "../../components/BackstageProfile/BackstageProfileFooter";
import BackstageProfileHeader from "../../components/BackstageProfile/BackstageProfileHeader";
import BackstageProfileStatus from "../../components/BackstageProfile/BackstageProfileStatus";
import BackstageProfileContent from "../../components/BackstageProfile/BackstageProfileContent";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useBackstageAccessQuery } from "./hooks/backstageProfileQueries";

export default function BackstageProfile() {
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();

  useBackstageAccessQuery({
    userId: loggedInUser?.id ?? null,
  });

  useEffect(() => {
    if (loggedInUser?.user_role === "fan") {
      navigate(`/profile/${loggedInUser?.id}`);
    }
  }, [loggedInUser, navigate]);

  return (
    <Section
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BackstageProfileHeader />
      <BackstageProfileStatus />
      <BackstageProfileContent />
      <BackstageProfileFooter />
    </Section>
  );
}
