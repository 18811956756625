import { create } from "zustand";
import { persist } from "zustand/middleware";

import { PAYMENT_METHODS } from "../../constants/constants";

export const useCheckoutStore = create(
  persist(
    (set) => ({
      event: null,
      setEvent: (event) => set({ event }),

      paymentMethod: null,
      setPaymentMethod: (method) => set({ paymentMethod: method }),

      cardPaymentMethod: null,
      setCardPaymentMethod: (method) => set({ cardPaymentMethod: method }),

      hasEnoughWalletBalance: false,
      setHasEnoughWalletBalance: (hasEnough) =>
        set({ hasEnoughWalletBalance: hasEnough }),

      momentifyFee: 0,
      handleMomentifyFee: (fee) => {
        set((state) => ({ momentifyFee: state.momentifyFee + fee }));
      },

      ticketsTotal: 0,
      handleTicketsTotal: (price) => {
        set((state) => ({ ticketsTotal: state.ticketsTotal + price }));
      },

      collectiblesTotal: 0,
      handleCollectiblesTotal: (price) => {
        set((state) => ({
          collectiblesTotal: state.collectiblesTotal + price,
        }));
      },

      total: 0,
      handleTotal: (price) => {
        // Round to 2 decimal places
        set((state) => ({
          total: Math.round((state.total + price) * 100) / 100,
        }));
      },

      isMinting: false,
      setIsMinting: (isMinting) => set({ isMinting }),

      isWertPaymentPending: false,
      setIsWertPaymentPending: (isWertPaymentPending) =>
        set({ isWertPaymentPending }),

      txnHash: null,
      setTxnHash: (hash) => set({ txnHash: hash }),

      boughtTickets: [],
      setBoughtTickets: (tickets) => set({ boughtTickets: tickets }),

      boughtCollectibles: [],
      setBoughtCollectibles: (collectibles) =>
        set({ boughtCollectibles: collectibles }),

      error: null,
      setError: (err) => set({ error: err }),

      pendingTxn: null,
      setPendingTxn: (txn) => set({ pendingTxn: txn }),

      pendingBoughtTickets: null,
      setPendingBoughtTickets: (tickets) =>
        set({ pendingBoughtTickets: tickets }),

      wertClosed: false,
      setWertClosed: (closed) => set({ wertClosed: closed }),

      isCheckingPendingTxn: false,
      setIsCheckingPendingTxn: (checking) =>
        set({ isCheckingPendingTxn: checking }),

      pendingEvent: null,
      setPendingEvent: (event) => set({ pendingEvent: event }),

      deployingMetadata: false,
      setDeployingMetadata: (deploying) =>
        set({ deployingMetadata: deploying }),

      estimatedGas: 0,
      setEstimatedGas: (gas) => set({ estimatedGas: gas }),

      reset: () =>
        set({
          event: null,
          paymentMethod: PAYMENT_METHODS.CREDIT_CARD,
          cardPaymentMethod: null,
          total: 0,
          ticketsTotal: 0,
          collectiblesTotal: 0,
          momentifyFee: 0,
          txnHash: null,
          isMinting: false,
          boughtTickets: [],
          error: null,
          deployingMetadata: false,
          isWertPaymentPending: false,
          gas: 0,
        }),

      resetPending: () =>
        set({
          pendingTxn: null,
          pendingBoughtTickets: null,
          wertClosed: false,
          isCheckingPendingTxn: false,
          deployingMetadata: false,
          isWertPaymentPending: false,
        }),

      resetTotal: () =>
        set({
          total: 0,
          ticketsTotal: 0,
          collectiblesTotal: 0,
          momentifyFee: 0,
        }),
    }),
    {
      name: "checkout",
    }
  )
);
