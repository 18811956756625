import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  background: ${({ theme, primary }) =>
    primary ? theme.colors.blue : "#F7F7F70A"};
  min-height: ${({ primary }) => (primary ? "145px" : "135px")};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const BalanceContainer = styled.div`
  padding: 16px 0 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
