import PropTypes from "prop-types";
import {
  BodyText,
  Button,
  ButtonRectSm,
  FooterText,
} from "../../../GlobalStyles";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { theme } from "../../../utils/theme";
import { DescriptionItem } from "../../Collectibles/CollectibleDescriptionItem/CollectibleDescriptionItem-Styles";
import QrModal from "../../Modal/QrModal";
import PlaneIcon from "../../../assets/svg/plane.svg?react";
import QRIcon from "../../../assets/svg/qr.svg?react";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const { colors } = theme;
export default function TicketToken({ ticket }) {
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();
  const { token_id, user_id, id, tier_id } = ticket || {};
  const { setCenterModalContent, toggleCenterModal } = useGlobalModal();

  const handleTransfer = () => {
    if (loggedInUser?.id !== user_id) {
      return toast.error("You can't transfer tickets of other users");
    }

    if (!tier_id) {
      return toast.error("You can't transfer old tickets");
    }

    navigate(`/profile/${user_id}/tickets/${id}/transfer`);
  };

  const handleShowQR = () => {
    if (loggedInUser?.id !== user_id) {
      return toast.error("You can't show QR codes of other users");
    }
    toggleCenterModal();
    setCenterModalContent(<QrModal ticket={ticket} />);
  };

  return (
    <DescriptionItem
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <BodyText weight="600">#{token_id}</BodyText>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <ButtonRectSm onClick={handleTransfer}>
          <PlaneIcon stroke={colors.white} />
          <FooterText weight="600">Transfer</FooterText>
        </ButtonRectSm>
        <Button onClick={() => handleShowQR(ticket)}>
          <QRIcon stroke={colors.white} />
        </Button>
      </div>
    </DescriptionItem>
  );
}

TicketToken.propTypes = {
  ticket: PropTypes.object,
};
