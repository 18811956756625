import { Minus, Plus } from "@phosphor-icons/react";
import { BodyText, ButtonSm, FooterText } from "../../../GlobalStyles";
import { ButtonsContainer } from "./CollectibleItem-Styles";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";
import { useMemo } from "react";
import { useCheckoutStore } from "../../Checkout/checkoutStore";
import { theme } from "../../../utils/theme";

export default function CollectibleItemButtons() {
  const {
    collectiblesSelected,
    updateCollectibleSelected,
    collectible,
    userCollectibles,
  } = useBuyCollectibleStore();
  const { handleCollectiblesTotal, handleMomentifyFee, handleTotal } =
    useCheckoutStore();
  const { price, fees } = collectible || {};
  const { mintFee, isPercentage, enabled } = fees || {};
  const { remaining, soldOut, userMints, maxCount } = userCollectibles || {};

  const fee = useMemo(() => {
    if (enabled && isPercentage && price > 0) {
      const percentageFee = mintFee / 100;
      return Number((price * percentageFee).toFixed(3));
    }

    if (enabled && price > 0) {
      return Number(mintFee);
    }

    if (!enabled || price === 0) return 0;

    return 0;
  }, [enabled, price, mintFee, isPercentage]);

  const handleQuantity = (isRemove) => {
    updateCollectibleSelected(collectible, isRemove);

    const convertedFee = price > 0 ? Number(fee) : 0;
    const priceWithFee = price + convertedFee;

    handleCollectiblesTotal(isRemove ? -price : price);
    handleMomentifyFee(isRemove ? -convertedFee : convertedFee);
    handleTotal(isRemove ? -priceWithFee : priceWithFee);
  };

  const isMaxQuantity = useMemo(() => {
    return (
      collectiblesSelected.length >= maxCount - userMints ||
      collectiblesSelected.length >= remaining ||
      collectiblesSelected.length >= maxCount
    );
  }, [collectiblesSelected, maxCount, remaining, userMints]);

  if (soldOut) {
    return <FooterText color={theme.colors.red}>Sold out</FooterText>;
  }

  return (
    <ButtonsContainer>
      <ButtonSm
        onClick={() => handleQuantity(true)}
        disabled={!collectiblesSelected?.length}
      >
        <Minus size={16} />
      </ButtonSm>
      <BodyText>{collectiblesSelected?.length || 0}</BodyText>
      <ButtonSm
        primary
        onClick={() => handleQuantity(false)}
        disabled={isMaxQuantity}
      >
        <Plus size={16} />
      </ButtonSm>
    </ButtonsContainer>
  );
}
