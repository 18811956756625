import { useParams } from "react-router-dom";
import ArtistHeader from "../../components/Artist/ArtistHeader";
import {
  useArtistCollectiblesQuery,
  useArtistMomentsQuery,
  useArtistPaidCollectiblesQuery,
  useArtistQuery,
} from "./hooks/artistQueries";
import useAuth from "../../hooks/useAuth";
import { ArtistDetailsContainer, ArtistSection } from "./Artist-Styles";
import useGeolocation from "../../hooks/useGeolocation";
import useCheckIn from "../../hooks/useCheckIn";
import withCheckInContextProvider from "../../hoc/withCheckInContextProvider";
import CheckInToast from "../../components/CheckIn/CheckInToast";
import useCheckInModalToggle from "../../hooks/useCheckInModalToggle";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useOneEvent } from "../Events/hooks/eventsQueries";
import CheckInEvent from "../../components/CheckIn/CheckInEvent";
import ErrorPage from "../Error";
import ArtistTabs from "../../components/Artist/ArtistTabs";
import ArtistDetails from "../../components/Artist/ArtistDetails";
import TopNavV2 from "../../components/TopNavV2";

/**
 * Represents the Artist component.
 *
 * @return {React.JSX.Element} The rendered Artist component
 */
function Artist() {
  const { id: artistId } = useParams();
  const { loggedInUser, fetchSessionCookie } = useAuth();
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useArtistQuery({
    artistId,
    userId: loggedInUser?.id,
  });

  const { data: moments, isLoading: momentsLoading } = useArtistMomentsQuery({
    artistId,
    userId: loggedInUser?.id,
  });

  const { data: collectibles, isLoading: collectiblesLoading } =
    useArtistCollectiblesQuery({ artistId });

  const { data: paidCollectibles, isLoading: paidCollectiblesLoading } =
    useArtistPaidCollectiblesQuery({ artistId });

  const collectiblesData = {
    collectibles,
    paidCollectibles,
  };
  const isCollectiblesLoading = collectiblesLoading || paidCollectiblesLoading;

  const { handleToggle, isOpen } = useCheckInModalToggle();
  const {
    handleLocationPermission,
    currentLocation,
    resetLocation,
    isGeolocationPermitted,
    getCurrentPosition,
  } = useGeolocation();
  const {
    currentEvent,
    setCurrentEvent,
    handleCheckInCurrentEvent,
    hasMomentExisted,
  } = useCheckIn();

  const artist = data?.length > 0 ? data[0] : null;
  const noEvents =
    (data?.length === 0 && !isLoading) ||
    (data?.length > 0 && !data[0].event_id);

  const oneEventQuery = useOneEvent({
    latitude: currentLocation?.coords?.latitude,
    longitude: currentLocation?.coords?.longitude,
    event_id: artist?.event_id,
  });

  const handleOpenCheckInModal = () => {
    if (!navigator.geolocation) return;

    handleToggle();
    handleCheckInCurrentEvent();
  };

  // Use effect for getting current postion
  useEffect(() => {
    if (isGeolocationPermitted) {
      // Get current location every 10 seconds
      const intervalId = setInterval(() => {
        getCurrentPosition();
        queryClient.refetchQueries({
          queryKey: ["one_event"],
          type: "active",
        });
      }, 20000);

      // Clear interval on component unmount
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [getCurrentPosition, isGeolocationPermitted]);

  useEffect(() => {
    if (isOpen) return;

    if (!isLoading) {
      setCurrentEvent(oneEventQuery?.data ?? null);
    }
  }, [oneEventQuery.data, oneEventQuery.isLoading]);

  // Get location and session on load
  useEffect(() => {
    if (!data || data?.length === 0 || isLoading) return;

    handleLocationPermission();
    fetchSessionCookie();

    // Reset location when unmounting
    return () => {
      resetLocation();
      setCurrentEvent(null);
    };
  }, [data, isLoading]);

  // Render PageNotFound if data is not available
  if (data?.length === 0 || error)
    return <ErrorPage description="We can't find this artist." />;

  if (data && isOpen) {
    return (
      <CheckInEvent
        onClose={handleToggle}
        onRefresh={handleCheckInCurrentEvent}
      />
    );
  }

  // Render the Artist component
  return (
    <ArtistSection>
      <TopNavV2 icon={artist?.profile_img} title={artist?.artist_name} />
      <ArtistHeader artist={artist} isLoading={isLoading} />
      <ArtistDetailsContainer>
        <ArtistDetails artist={artist} isLoading={isLoading} />
        <ArtistTabs
          events={noEvents ? [] : data}
          moments={moments}
          collectibles={collectiblesData}
          isEventsLoading={isLoading}
          isMomentsLoading={momentsLoading}
          isCollectiblesLoading={isCollectiblesLoading}
        />
      </ArtistDetailsContainer>
      <CheckInToast
        event={currentEvent}
        onClick={handleOpenCheckInModal}
        hasMomentExisted={hasMomentExisted}
      />
    </ArtistSection>
  );
}

export default withCheckInContextProvider(Artist);
