import PropTypes from "prop-types";
import { FooterTextSm } from "../../../GlobalStyles";
import { Container } from "./Filters-Styles";
import { useLocation, useSearchParams } from "react-router-dom";
import FilterButton from "../FilterButton";

export default function BackstageEventFilters({ style }) {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get("type");
  const status = searchParams.get("status");

  const handleClear = () => {
    searchParams.delete("type");
    searchParams.delete("status");
    setSearchParams(searchParams);
  };

  return (
    <Container style={style}>
      <FilterButton filter={type} type="type" />
      {pathname.includes("scan-history") && (
        <FilterButton filter={status} type="status" />
      )}
      {(type || status) && (
        <FooterTextSm secondary onClick={handleClear}>
          clear filter
        </FooterTextSm>
      )}
    </Container>
  );
}

BackstageEventFilters.propTypes = {
  style: PropTypes.object,
};
