import { useNavigate } from "react-router-dom";
import { ButtonRect } from "../../../GlobalStyles";
import { Container } from "./BackstageProfileFooter-Styles";
import useAuth from "../../../hooks/useAuth";

export default function BackstageProfileFooter() {
  const { loggedInUser } = useAuth();
  const navigate = useNavigate();

  return (
    <Container>
      <ButtonRect
        primary
        onClick={() => navigate(`/profile/${loggedInUser?.id}`)}
      >
        Go back to fan app
      </ButtonRect>
    </Container>
  );
}
