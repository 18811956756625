import { DEV_SEPOLIA_URL } from "../constants/constants";
import { isProd } from "../utils/utils";

const BLOCKCHAIN_URL = isProd
  ? import.meta.env.VITE_BLOCKCHAIN_API_URL
  : DEV_SEPOLIA_URL;

export const fetchCollectibles = async (userId, signal) => {
  if (!userId) return false;

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/user/${userId}/collectibles`,
    {
      signal,
    }
  ).catch((err) => {
    if (err.code === 20) return false;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return [];
  }

  const collectibles = await response.json().catch((err) => {
    console.error(err);
  });

  if (!collectibles || !collectibles.success) return [];

  return collectibles?.data || [];
};

export const fetchSingleCollectible = async (
  userId,
  collectibleId,
  type = "airdrop"
) => {
  if (!userId || !collectibleId) return null;

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/user/${userId}/collectible/${collectibleId}/${type}`
  ).catch((err) => {
    if (err.code === 20) return null;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return null;
  }

  const collectible = await response.json().catch((err) => {
    console.error(err);
  });

  if (!collectible || !collectible.success) return null;

  return collectible?.data || null;
};

export const fetchSinglePaidCollectible = async (collectibleId) => {
  if (!collectibleId) return null;

  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/collectibles/${collectibleId}`
  ).catch((err) => {
    if (err.code === 20) return null;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return null;
  }

  const collectible = await response.json().catch((err) => {
    console.error(err);
  });

  if (!collectible || !collectible.success) return null;

  return collectible?.data || null;
};

export const fetchSingleExternalCollectible = async (
  userId,
  collectibleId,
  tokenId
) => {
  if (!userId || !tokenId || !collectibleId) return null;
  console.log(userId, tokenId, collectibleId);
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/external-nfts/${userId}/${collectibleId}/${tokenId}`
  ).catch((err) => {
    if (err.code === 20) return null;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return null;
  }

  const collectible = await response.json().catch((err) => {
    console.error(err);
  });

  if (!collectible || !collectible.success) return null;

  return collectible?.data || null;
};

export const fetchExternalNFTs = async () => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/external-nfts`
  ).catch((err) => {
    if (err.code === 20) return null;
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return null;
  }

  const nfts = await response.json().catch((err) => {
    console.error(err);
  });

  return nfts?.data || null;
};

export const fetchExternalMints = async (owner, contractAddress) => {
  const response = await fetch(
    `${import.meta.env.VITE_BLOCKCHAIN_API_URL}/onchain/mints`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ owner, contractAddress }),
    }
  ).catch((err) => {
    console.error(err);
  });

  if (!response || response?.status != 200) {
    return null;
  }

  const mints = await response.json().catch((err) => {
    console.error(err);
  });

  return mints?.data || null;
};

export const insertExternalMints = async (userId, externalMints) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/insert-external-nfts`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, externalMints }),
    }
  ).catch((err) => {
    console.error(err);
    return null;
  });

  if (!response || response.status !== 200) {
    return null;
  }

  const result = await response.json().catch((err) => {
    console.error(err);
    return null;
  });

  return result?.data || null;
};

export const getFIDMints = async (fid) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/get-fid-mints`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fid }),
    }
  ).catch((err) => {
    console.error(err);
    return null;
  });

  if (!response || response.status !== 200) {
    return null;
  }

  const result = await response.json().catch((err) => {
    console.error(err);
    return null;
  });

  return result?.data || null;
};

export const updateExternalMint = async (transactionHash) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/update-external-mint`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ transactionHash }),
    }
  ).catch((err) => {
    console.error(err);
    return null;
  });

  if (!response || response.status !== 200) {
    return null;
  }

  const result = await response.json().catch((err) => {
    console.error(err);
    return null;
  });

  return result?.data || null;
};

export const deployCollectibleMetadata = async ({ metadata }) => {
  if (!metadata) {
    throw new Error("Missing required parameters");
  }

  const {
    artistId,
    contractAddress,
    name,
    symbol,
    image,
    description,
    attributes,
  } = metadata || {};

  const requiredParams = {
    artistId,
    contractAddress,
    name,
    symbol,
    description,
    attributes,
  };
  const missingParams = Object.entries(requiredParams)
    .filter(([_, value]) => !value)
    .map(([key]) => key);

  if (missingParams.length > 0) {
    throw new Error(`Missing required parameters: ${missingParams.join(", ")}`);
  }

  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/${artistId}/airdrop/deployMetadata`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          artistId,
          contract_address: contractAddress,
          image,
          name,
          symbol,
          description,
          attributes,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to deploy metadata");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getMintByTxn = async ({ txn }) => {
  if (!txn) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/events/collectibles/checkMintedCollectible/${txn}`
    );

    if (!response.ok) {
      throw new Error("Failed to get minted collectibles");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getMintedCollectiblesByTxn = async ({ txn }) => {
  if (!txn) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/collectibles/checkMintedCollectibles/${txn}`
    );

    if (!response.ok) {
      throw new Error("Failed to get minted tickets");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getMintedCollectiblesById = async ({ id }) => {
  if (!id) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/collectibles/checkMintedCollectiblesById/${id}`
    );

    if (!response.ok) {
      throw new Error("Failed to get minted tickets");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const insertCollectiblesMinted = async ({
  collectibles,
  userId,
  txnHash,
}) => {
  if (!collectibles || !userId || !txnHash) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/collectibles/saveCollectiblesMints`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ collectibles, userId, txnHash }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to insert minted collectibles");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getGasEstimates = async () => {
  try {
    const response = await fetch(`${BLOCKCHAIN_URL}/onchain/getGasEstimates`);

    if (!response.ok) {
      throw new Error("Failed to get gas estimates");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const checkUserCollectibles = async ({ userId, subCollectionId }) => {
  if (!userId || !subCollectionId) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(
      `${BLOCKCHAIN_URL}/onchain/collectibles/checkUserCollectibles`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, sub_collection_id: subCollectionId }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to get minted tickets");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getCollectibleTokensMinted = async ({
  owner,
  contractAddress,
  txn,
}) => {
  if (!owner || !contractAddress || !txn) {
    throw new Error("Missing required parameters");
  }

  try {
    const response = await fetch(`${BLOCKCHAIN_URL}/onchain/mints`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ owner, contractAddress, txn }),
    });

    if (!response.ok) {
      throw new Error("Failed to get minted tickets");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
