import PropTypes from "prop-types";
import { GlassmorphPlaceholder } from "../../../constants/constants";
import { setImageOfEvent } from "../../../utils/utils";
import {
  BottomContainer,
  ButtonContainer,
  EventImage,
  HeaderContainer,
  NameContainer,
  Overlay,
} from "./EventHeader-Styles";
import ShareButton from "../../ShareButton";
import moment from "moment";
import { SkeletonElement } from "../../Skeleton/Skeleton-Style";

export default function EventHeader({ event, isLoading }) {
  const { headline_artist, event_name, city, date } = event || {};

  if (isLoading) {
    return <SkeletonElement height="268px" backgroundColor="#ffffff14" />;
  }

  return (
    <HeaderContainer>
      <EventImage
        src={setImageOfEvent(event)}
        onError={(e) => (e.target.src = GlassmorphPlaceholder)}
      />
      <Overlay />
      <BottomContainer>
        <NameContainer>{headline_artist ?? event_name}</NameContainer>
        <ButtonContainer>
          <ShareButton
            url={window.location.href}
            title={event_name ?? headline_artist}
            text={`Check out this event - ${headline_artist} - ${city} - ${moment(date).format("DD/MM/YY")}`}
            noPadding
          />
        </ButtonContainer>
      </BottomContainer>
    </HeaderContainer>
  );
}

EventHeader.propTypes = {
  event: PropTypes.object,
  isLoading: PropTypes.bool,
};
