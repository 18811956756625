export const getIpInfo = async () => {
  try {
    const response = await fetch("https://ipinfo.io/json", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${import.meta.env.VITE_IPINFO_TOKEN}`,
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error fetching IP info:", error);
  }
};

export const logLocation = async (
  user_id,
  latitude,
  longitude,
  accuracy = undefined
) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/log-location`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${import.meta.env.VITE_IPINFO_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user_id,
          latitude: latitude,
          longitude: longitude,
          accuracy: accuracy,
        }),
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error logging location:", error);
  }
};

export const getUnrewardedGuessXP = async (fid) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/get-unclaimed-guess-xp`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fid }),
    }
  ).catch((err) => {
    console.error(err);
    return null;
  });

  if (!response || response.status !== 200) {
    return null;
  }

  const result = await response.json().catch((err) => {
    console.error(err);
    return null;
  });

  return result?.data || null;
};

export const updateUserXPStatus = async (fid) => {
  const response = await fetch(
    `${import.meta.env.VITE_API_URL}/update-guess-xp-status`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fid }),
    }
  ).catch((err) => {
    console.error(err);
    return null;
  });

  if (!response || response.status !== 200) {
    return null;
  }

  const result = await response.json().catch((err) => {
    console.error(err);
    return null;
  });

  return result?.data || null;
};

export const getSuccessfulInvites = async (inviteId) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/invites/${inviteId}/successful-invites`
    );

    if (!response.ok) {
      throw new Error("Failed to get successful invites");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getUserWallet = async (userId) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/users/${userId}/wallets`
    );

    if (!response.ok) {
      throw new Error("Failed to get user wallet");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const searchUsers = async ({ query, ownerId }) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/search/users?query=${query}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ownerId }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to search users");
    }

    const data = await response.json();

    if (!data?.success) {
      return [];
    }
    return data?.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
