import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background: #11111fe5;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;
