import { useMemo } from "react";
import { BodyText, Line } from "../../../GlobalStyles";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";
import { formatPrice } from "../../../utils/utils";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import { useCheckoutStore } from "../checkoutStore";
import {
  Content,
  LineItem,
  LineLeftItem,
} from "../OrderSummary/OrderSummary-Styles";

export default function OrderSummaryContent() {
  const { ticketsSelected, isOpen: ticketsOpen } = useBuyTicketStore();
  const { collectiblesSelected } = useBuyCollectibleStore();
  const { momentifyFee } = useCheckoutStore();

  const tickets = useMemo(() => {
    if (!ticketsSelected?.length) return [];

    return Object.values(
      ticketsSelected.reduce((acc, ticket) => {
        if (acc[ticket.id]) {
          acc[ticket.id].quantity += 1;
        } else {
          acc[ticket.id] = { ...ticket, quantity: 1 };
        }
        return acc;
      }, {})
    ).map((ticket) => {
      const formattedPrice = formatPrice(ticket.price * ticket.quantity, false);

      return (
        <LineItem key={ticket.id}>
          <LineLeftItem>
            <BodyText>{ticket.type} </BodyText>
            <BodyText secondary> ({ticket.quantity})</BodyText>
          </LineLeftItem>
          <BodyText>
            {formattedPrice === "0.00" ? "FREE!" : `$${formattedPrice}`}
          </BodyText>
        </LineItem>
      );
    });
  }, [ticketsSelected]);

  const collectibles = useMemo(() => {
    if (!collectiblesSelected?.length) return [];

    return Object.values(
      collectiblesSelected.reduce((acc, collectible) => {
        if (acc[collectible.id]) {
          acc[collectible.id].quantity += 1;
        } else {
          acc[collectible.id] = { ...collectible, quantity: 1 };
        }
        return acc;
      }, {})
    ).map((collectible) => {
      const formattedPrice = formatPrice(
        collectible.price * collectible.quantity,
        false
      );

      return (
        <LineItem key={collectible.id}>
          <LineLeftItem>
            <BodyText>{collectible?.edition_name} </BodyText>
            <BodyText secondary> ({collectible.quantity})</BodyText>
          </LineLeftItem>
          <BodyText>
            {formattedPrice === "0.00" ? "FREE!" : `$${formattedPrice}`}
          </BodyText>
        </LineItem>
      );
    });
  }, [collectiblesSelected]);

  return (
    <Content>
      <Line style={{ margin: "16px 0 0 0" }} />
      {ticketsOpen ? tickets : collectibles}
      {momentifyFee > 0 && (
        <LineItem>
          <LineLeftItem>
            <BodyText>Momentify fees</BodyText>
          </LineLeftItem>
          <BodyText>+${formatPrice(momentifyFee, false)}</BodyText>
        </LineItem>
      )}
    </Content>
  );
}
