import { useQuery } from "@tanstack/react-query";
import {
  checkUserCollectibles,
  fetchSinglePaidCollectible,
} from "../../api/collectibles";

export const usePaidCollectibleQuery = ({ collectibleId }) => {
  return useQuery({
    queryKey: ["paid-collectible", collectibleId],
    queryFn: () => {
      return fetchSinglePaidCollectible(collectibleId);
    },
  });
};

export const useCheckUserCollectiblesQuery = ({ userId, subCollectionId }) => {
  return useQuery({
    queryKey: ["user-collectibles", { userId, subCollectionId }],
    queryFn: () => {
      return checkUserCollectibles({ userId, subCollectionId });
    },
  });
};
