import { Scanner } from "@yudiel/react-qr-scanner";
import { Section } from "../../GlobalStyles";
import {
  hasExceeded15Seconds,
  isBase64,
  isValidJsonString,
} from "../../utils/utils";
import {
  getTicketByKey,
  logScannedTicketKey,
  scanTicketKey,
} from "../../api/ticketing";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import useGlobalModal from "../../hooks/useGlobalModal";
import ScannedTicketModal from "../../components/Modal/ScannedTicketModal";
import moment from "moment";

const scannerStyles = `
  .momentify-scanner svg path {
    stroke: rgb(255, 255, 255)
  }
`;

export default function BackstageScanner() {
  const { toggleSheetModal, setSheetModalContent } = useGlobalModal();
  const [scanned, setScanned] = useState(false);
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const [videoDeviceId, setVideoDeviceId] = useState(null);

  async function handleScan(result) {
    if (scanned) return;
    const _result = result[0].rawValue;

    setScanned(true);

    const dataResult = _result;

    if (!isValidJsonString(dataResult)) {
      console.error("incorrect json encoding");
      setSheetModalContent(
        <ScannedTicketModal
          ticket={null}
          isValid={false}
          setScanned={() => setScanned(false)}
        />
      );
      toggleSheetModal();
      return;
    }

    const { d: mintId, k: key, t: timestamp } = JSON.parse(dataResult);
    const res = await getTicketByKey({ mintId: mintId });

    const isValidKey = !!res?.data && res?.data?.key === key;
    // const isEventDateCorrect = !!res?.data && moment(res?.data?.detail?.date).utc().format("YYYY-MM-DD") == moment().utc().format("YYYY-MM-DD")
    const isEventDateCorrect =
      !!res?.data &&
      Math.abs(
        moment(res?.data?.detail?.date).utc().diff(moment().utc(), "hours")
      ) <= 48;
    const isUnscanned = !!res?.data && !res?.data?.is_scanned;
    const isNotExpired = !!res?.data && !hasExceeded15Seconds(timestamp);
    const isValid =
      !!res?.data && isUnscanned && isNotExpired && isEventDateCorrect;

    if (isValid) {
      console.log("valid qr");
      await scanTicketKey({ mintId: mintId });
    } else {
      console.error("invalid qr");
    }
    if (!!res?.data) {
      await logScannedTicketKey({
        mintId: mintId,
        status: isValid ? "SUCCESS" : "FAILED",
        fail_reason:
          !isValid && !isValidKey
            ? "INVALID_KEY"
            : !isValid && !isNotExpired
              ? "EXPIRED"
              : !isValid && !isUnscanned
                ? "SCANNED_ALREADY"
                : !isValid && !isEventDateCorrect
                  ? "WRONG_DATE"
                  : null,
      });
    }

    setSheetModalContent(
      <ScannedTicketModal
        ticket={res?.data}
        isValid={isValid}
        setScanned={() => setScanned(false)}
        isEventDateIncorrect={!isEventDateCorrect}
        isScannedAlready={!isUnscanned}
        isExpired={!isNotExpired}
        isInvalidKey={!isValidKey}
      />
    );
    toggleSheetModal();
  }

  // useEffect(() => {
  //   const requestCameraPermission = async () => {
  //     try {
  //       // Request permission to access the camera
  //       const permissionStatus = await navigator.permissions.query({ name: 'camera' });
  //       setIsPermissionGranted(permissionStatus.state == 'granted'); // Permission granted
  //     } catch (error) {
  //       console.error('Camera permission denied:', error);
  //       setIsPermissionGranted(false); // Permission denied
  //     }
  //   };
  //   requestCameraPermission();
  // }, [navigator]);

  // useEffect(() => {
  //   const updateVideoDevice = async () => {
  //     if (!isPermissionGranted) return

  //     try {
  //       // Get the list of video devices
  //       const devices = await navigator.mediaDevices.enumerateDevices();
  //       const videoDevices = devices.filter(device => device.kind === 'videoinput');

  //       if (videoDevices.length <= 0) return

  //       // Detect the orientation
  //       const isPortrait = window.matchMedia('(orientation: portrait)').matches;

  //       // Filter for back cameras based on device labels
  //       const backCameras = videoDevices.filter(device =>
  //         device.label.toLowerCase().includes('back') ||
  //         device.label.toLowerCase().includes('rear')
  //       );

  //       // Choose a device based on orientation and availability
  //       const selectedDevice = isPortrait
  //         ? backCameras[0] || videoDevices[0] // Use the first back camera for portrait mode
  //         : backCameras[1] || backCameras[0] || videoDevices[0]; // Optionally adjust for landscape mode

  //       setVideoDeviceId(selectedDevice?.deviceId || null);
  //     } catch (error) {
  //       console.error('Error accessing video devices:', error);
  //     }
  //   };

  //   // Initial setup
  //   updateVideoDevice();

  //   // Update device on orientation change
  //   window.addEventListener('resize', updateVideoDevice);
  //   return () => window.removeEventListener('resize', updateVideoDevice);
  // }, [isPermissionGranted]);

  return (
    <Section
      style={{
        display: "flex",
        position: "relative",
      }}
    >
      <style>{scannerStyles}</style>
      {!scanned && (
        <Scanner
          classNames={{ container: "momentify-scanner" }}
          styles={{
            container: { width: "100%", margin: "auto 0", minHeight: "380px" },
            video: {
              width: "100%",
              height: "100%",
              objectFit: "cover",
              margin: "auto 0",
            },
            finderBorder: 75,
          }}
          scanDelay={100}
          onError={(err) => {
            setScanned(false);
            console.log(err);
          }}
          onScan={(result) => {
            if (scanned) return;
            else handleScan(result);
          }}
        />
      )}
      {scanned && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner />
        </div>
      )}
    </Section>
  );
}
