import { create } from "zustand";

export const useBuyCollectibleStore = create((set) => ({
  collectible: {},
  setCollectible: (collectible) => set({ collectible }),
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),
  quantity: 0,
  setQuantity: (quantity) => set({ quantity }),
  collectiblesSelected: [],
  updateCollectibleSelected: (collectible, isRemove) => {
    set((state) => ({
      collectiblesSelected: isRemove
        ? state.collectiblesSelected.filter((c, index) => {
            const collectibleIndex = state.collectiblesSelected.findIndex(
              (item) => item.id === collectible.id
            );
            return index !== collectibleIndex;
          })
        : [...state.collectiblesSelected, collectible],
    }));
  },
  resetCollectiblesSelected: () => {
    set(() => ({ collectiblesSelected: [] }));
  },
  totalFees: 0,
  setTotalFees: (totalFees) => set({ totalFees }),
  totalCollectiblePrice: 0,
  setTotalCollectiblePrice: (totalCollectiblePrice) =>
    set({ totalCollectiblePrice }),
  totalPrice: 0,
  setTotalPrice: (totalPrice) => set({ totalPrice }),
  isEligible: false,
  setIsEligible: (isEligible) => set({ isEligible }),
  isLoading: false,
  setIsLoading: (isLoading) => set({ isLoading }),
  isError: false,
  setIsError: (isError) => set({ isError }),
  userCollectibles: {},
  setUserCollectibles: (userCollectibles) => set({ userCollectibles }),

  reset: () => {
    set({
      collectible: {},
      isOpen: false,
      quantity: 0,
      collectiblesSelected: [],
      totalFees: 0,
      totalCollectiblePrice: 0,
      totalPrice: 0,
      isEligible: false,
      isLoading: false,
      isError: false,
      userCollectibles: {},
    });
  },
}));
