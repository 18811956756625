import { encodeFunctionData } from "viem";

export const transferTicketInBlockchain = async ({
  alchemyProvider,
  abi,
  args,
}) => {
  try {
    // Send the user operation
    const userOperation = await alchemyProvider.sendUserOperation({
      uo: {
        target: import.meta.env.VITE_TICKETS_MANAGER_ADDRESS, // The desired target contract address
        data: encodeFunctionData({
          abi,
          functionName: "transferTicket",
          args,
        }),
      },
    });

    // Wait for the user operation to be mined
    const txnHash = await alchemyProvider.waitForUserOperationTransaction({
      hash: userOperation.hash,
    });

    console.log("Transaction Hash:", txnHash);

    if (!txnHash) {
      console.warn("Failed to transfer tickets");
      return { success: false, error: "Failed to transfer tickets" };
    }

    return { success: true };
  } catch (error) {
    console.error(error);
    return { success: false, error: "Failed to transfer tickets" };
  }
};
