import moment from "moment";
import PropTypes from "prop-types";
import Countdown from "react-countdown";
import Clock from "../../assets/svg/clock.svg?react";
import { theme } from "../../utils/theme";
import IconWithText from "../IconWithText";

const { colors } = theme;

export default function EventCountdown({ date }) {
  return (
    <Countdown
      date={date?.toString()}
      renderer={({ days, hours, minutes, completed }) => {
        if (completed) {
          return (
            <IconWithText
              icon={<Clock height={14} width={14} fill={colors.green} />}
              text={
                moment(date).format("DD") == moment().format("DD") &&
                moment().format("HH") < 23
                  ? "Live!"
                  : "Event has ended"
              }
            />
          );
        } else {
          return (
            <IconWithText
              icon={<Clock height={14} width={14} fill={colors.green} />}
              text={days ? `${days}d ${hours}h` : `${hours}h ${minutes}m`}
            />
          );
        }
      }}
    />
  );
}

EventCountdown.propTypes = {
  date: PropTypes.any,
};
