import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Container, NavLink, ProfileImage } from "./BackstageNav-Styles";
import Bookmark from "../../assets/svg/bookmark.svg?react";
import Scan from "../../assets/svg/scan.svg?react";
import { AvatarPlaceholder } from "../../constants/constants";

export default function BackstageNav() {
  const { loggedInUser } = useAuth();
  const location = useLocation();

  return (
    <Container>
      <NavLink
        to={`/backstage/events`}
        active={location.pathname.includes("/events") ? true : false}
      >
        <Bookmark
          stroke="white"
          fill={location.pathname.includes("/events") ? "white" : "none"}
          height={20}
          width={20}
        />
      </NavLink>
      <NavLink
        to={`/backstage/scanner`}
        active={location.pathname.includes("/scanner") ? true : false}
      >
        <Scan
          stroke="white"
          height={20}
          width={20}
          fill={location.pathname.includes("/scanner") ? "white" : "none"}
        />
      </NavLink>
      <NavLink
        to={"/backstage/profile"}
        active={location.pathname.includes("/profile") ? true : false}
      >
        <ProfileImage
          src={loggedInUser?.avatar ?? AvatarPlaceholder}
          onError={(e) => (e.target.src = AvatarPlaceholder)}
        />
      </NavLink>
    </Container>
  );
}
