import { createContext, useState } from "react";
import { GlobalModalDispatchEvents } from "../constants/dispatchEvents";

export const GlobalModalContext = createContext({});

const initialState = {
  isSheetModalOpen: false,
  isSheetModalClosed: true,
  sheetModalContent: null,
  isCenterModalOpen: false,
  isCenterModalClosed: true,
  centerModalContent: null,
  sheetBackdropStyle: null,
};

export default function GlobalModalProvider(params) {
  const { children } = params;
  const [context, setContent] = useState(initialState);

  function dispatch(actionType, payload) {
    switch (actionType) {
      case GlobalModalDispatchEvents.TOGGLE_SHEET_MODAL:
        {
          setContent((prev) => ({
            ...prev,
            isSheetModalOpen: !prev.isSheetModalOpen,
            isSheetModalClosed: !prev.isSheetModalClosed,
          }));
        }
        break;
      case GlobalModalDispatchEvents.TOGGLE_CENTER_MODAL:
        {
          setContent((prev) => ({
            ...prev,
            isCenterModalOpen: !prev.isCenterModalOpen,
            isCenterModalClosed: !prev.isCenterModalClosed,
          }));
        }
        break;
      case GlobalModalDispatchEvents.OPEN_CENTER_MODAL:
        {
          setContent((prev) => ({
            ...prev,
            isCenterModalOpen: true,
            isCenterModalClosed: false,
          }));
        }
        break;
      case GlobalModalDispatchEvents.OPEN_SHEET_MODAL:
        {
          setContent((prev) => ({
            ...prev,
            isSheetModalOpen: true,
            isSheetModalClosed: false,
          }));
        }
        break;
      case GlobalModalDispatchEvents.CLOSE_SHEET_MODAL:
        {
          setContent((prev) => ({
            ...prev,
            isSheetModalOpen: false,
            isSheetModalClosed: true,
            sheetModalContent: null,
          }));
        }
        break;
      case GlobalModalDispatchEvents.SET_CENTER_MODAL_CONTENT:
        {
          setContent((prev) => ({
            ...prev,
            centerModalContent: payload,
          }));
        }
        break;
      case GlobalModalDispatchEvents.SET_SHEET_MODAL_CONTENT:
        {
          setContent((prev) => ({
            ...prev,
            sheetModalContent: payload,
          }));
        }
        break;
      case GlobalModalDispatchEvents.SET_SHEET_MODAL_BACKDROP_STYLE:
        {
          setContent((prev) => ({
            ...prev,
            sheetBackdropStyle: payload,
          }));
        }
        break;
    }
  }

  return (
    <GlobalModalContext.Provider value={{ context, dispatch }}>
      {children}
    </GlobalModalContext.Provider>
  );
}
