import { encodeFunctionData } from "viem";
import useAuth from "../../../hooks/useAuth";
import { usePrimaryWallet } from "../../../hooks/usePrimaryWallet";
import { useBuyCollectibleStore } from "../../../routes/PaidCollectible/stores/buyCollectibleStore";
import {
  addDelayInMs,
  calculateTotal,
  checkPrerequisites,
  processCollectiblesMinting,
  sortItems,
} from "../../../utils/checkout";
import { useCheckoutStore } from "../checkoutStore";
import {
  USDC_ADDRESS,
  USDC_ADDRESS_SEPOLIA,
} from "../../../constants/constants";
import useGlobalModal from "../../../hooks/useGlobalModal";
import { useGetCollectiblesManagerABI } from "./checkoutQueries";
import CollectiblePaymentSuccess from "../CollectiblePaymentSuccess";
import { useChainId, useSwitchChain, useWriteContract } from "wagmi";
import { base, baseSepolia } from "viem/chains";
import usdcJson from "../../../constants/usdc-abi.json";
import { useMemo } from "react";
import { useSmartWallets } from "@privy-io/react-auth/smart-wallets";
import { isProd } from "../../../utils/utils";
import useAccountKit from "../../../hooks/useAccountKit";

export const useCollectiblesCheckout = () => {
  const { loggedInUser } = useAuth();
  const { primaryWallet, receivingWallet } = usePrimaryWallet();
  const { client: smartWallet } = useSmartWallets();
  const {
    setIsMinting,
    setTxnHash,
    setBoughtCollectibles,
    collectiblesTotal,
    momentifyFee,
    setError,
    estimatedGas,
  } = useCheckoutStore();
  const { alchemyProvider } = useAccountKit();
  const { collectible, collectiblesSelected } = useBuyCollectibleStore();
  const { writeContractAsync } = useWriteContract();
  const { switchChain } = useSwitchChain();
  const chain = useChainId();
  const {
    toggleSheetModal,
    closeSheetModal,
    setSheetModalContent,
    setSheetModalBackdropStyle,
  } = useGlobalModal();
  const { data: collectiblesManagerABI } = useGetCollectiblesManagerABI();
  const { contract_address } = collectible || {};

  const USDC = isProd ? USDC_ADDRESS : USDC_ADDRESS_SEPOLIA;
  const chainTobeUsed = isProd ? base : baseSepolia;
  const COLLECTIBLES_MANAGER_ADDRESS = import.meta.env
    .VITE_COLLECTIBLES_MANAGER_ADDRESS;

  const { parsedTotal } = useMemo(() => {
    if (!collectiblesTotal) return { formattedTotal: "0", parsedTotal: 0 };

    const total = calculateTotal({
      ticketsTotal: collectiblesTotal,
      momentifyFee,
      gas: estimatedGas,
    });

    return total;
  }, [collectiblesTotal, momentifyFee, estimatedGas]);

  const buyWithPaymaster = async () => {
    // Buy tickets with paymaster
    try {
      const isPrerequisitesMet = checkPrerequisites({
        loggedInUser,
        primaryWallet,
        receivingWallet,
        setError,
      });

      if (!isPrerequisitesMet) return;

      setIsMinting(true);

      const txnHash = await smartWallet.sendTransaction({
        account: smartWallet.account,
        chain: chainTobeUsed,
        calls: [
          {
            to: USDC, // USDC Address
            data: encodeFunctionData({
              abi: usdcJson.abi,
              functionName: "approve",
              args: [COLLECTIBLES_MANAGER_ADDRESS, parsedTotal],
            }),
          },
          {
            to: COLLECTIBLES_MANAGER_ADDRESS,
            data: encodeFunctionData({
              abi: collectiblesManagerABI,
              functionName: "ERC1155Mint",
              args: [
                contract_address, // Collectibles Contract
                USDC, // USDC Address
                receivingWallet.address, // Wallet Address of User
                collectiblesSelected.length, // Selected Collectibles
                1,
              ],
            }),
          },
        ],
      });

      console.log("Transaction Hash:", txnHash);

      if (!txnHash) {
        setIsMinting(false);
        console.warn("Failed to mint tickets");
        setError("Failed to mint tickets. Please try again later.");
        return;
      }

      const boughtCollectibles = sortItems(collectiblesSelected);

      const stateUpdaters = {
        setTxnHash,
        setBoughtCollectibles,
        setIsMinting,
        setError,
      };

      // Process minting
      const mintingResult = await processCollectiblesMinting(
        {
          tx_id: txnHash,
          userWallet: receivingWallet.address,
          contractAddress: contract_address,
          boughtCollectibles,
          loggedInUser,
        },
        stateUpdaters
      ).catch((error) => {
        console.error("Failed to process minting:", error);
        return { success: false };
      });

      if (mintingResult.success) {
        closeSheetModal();

        setSheetModalBackdropStyle({});
        await addDelayInMs(1000);

        setSheetModalContent(<CollectiblePaymentSuccess />);
        toggleSheetModal();
        return;
      }
    } catch (error) {
      console.error(error);
      setError("Something went wrong. Please try again later.");
    } finally {
      setIsMinting(false);
    }
  };

  const buyWihoutPaymaster = async () => {
    try {
      const isPrerequisitesMet = checkPrerequisites({
        loggedInUser,
        primaryWallet,
        receivingWallet,
        setError,
      });

      if (!isPrerequisitesMet) return;

      setIsMinting(true);

      if (isProd && chain !== base.id) {
        switchChain({ chainId: base.id });
        primaryWallet?.switchChain &&
          (await primaryWallet?.switchChain(base.id));
      } else if (!isProd && chain !== baseSepolia.id) {
        switchChain({ chainId: baseSepolia.id });
        primaryWallet?.switchChain &&
          (await primaryWallet?.switchChain(baseSepolia.id));
      }

      console.log("Starting Approval...");

      const usdcApprovalTxn = await writeContractAsync({
        address: USDC,
        abi: usdcJson.abi,
        functionName: "approve",
        args: [COLLECTIBLES_MANAGER_ADDRESS, parsedTotal],
        chain: chainTobeUsed,
        chainId: chainTobeUsed.id,
      });

      if (!usdcApprovalTxn) {
        setIsMinting(false);
        console.warn("Failed to approve USDC");
        setError("Failed to approve USDC. Please try again later.");
        return;
      }

      console.log("USDC Approved:", usdcApprovalTxn);
      console.log("Starting Batch Mint...");

      const batchMintTxn = await writeContractAsync({
        address: COLLECTIBLES_MANAGER_ADDRESS,
        abi: collectiblesManagerABI,
        functionName: "ERC1155Mint",
        args: [
          contract_address, // Collectibles Contract
          USDC, // USDC Address
          receivingWallet.address, // Wallet Address of User
          collectiblesSelected.length, // Selected Collectibles
          1,
        ],
        chain: chainTobeUsed,
        chainId: chainTobeUsed.id,
      });

      if (!batchMintTxn) {
        setIsMinting(false);
        console.warn("Failed to mint tickets");
        setError("Failed to mint tickets. Please try again later.");
        return;
      }

      console.log("Batch Minted:", batchMintTxn);

      setTxnHash(batchMintTxn);

      const boughtCollectibles = sortItems(collectiblesSelected);

      const stateUpdaters = {
        setTxnHash,
        setBoughtCollectibles,
        setIsMinting,
        setError,
      };

      // Process minting
      const mintingResult = await processCollectiblesMinting(
        {
          tx_id: batchMintTxn,
          userWallet: receivingWallet.address,
          contractAddress: contract_address,
          boughtCollectibles,
          loggedInUser,
        },
        stateUpdaters
      ).catch((error) => {
        console.error("Failed to process minting:", error);
        return { success: false };
      });

      if (mintingResult.success) {
        closeSheetModal();

        setSheetModalBackdropStyle({});
        await addDelayInMs(1000);

        setSheetModalContent(<CollectiblePaymentSuccess />);
        toggleSheetModal();
        return;
      }
    } catch (error) {
      console.error(error);
      setError("Something went wrong. Please try again later.");
    } finally {
      setIsMinting(false);
    }
  };

  const buyForFree = async () => {
    try {
      const isPrerequisitesMet = checkPrerequisites({
        loggedInUser,
        primaryWallet,
        receivingWallet,
        setError,
      });

      if (!isPrerequisitesMet) return;

      const hasPrice = collectiblesSelected.some(
        (collectible) => collectible.price > 0
      );

      if (hasPrice) {
        setError(
          "There are some collectibles with price. Please remove them before buying."
        );
        return;
      }

      if (!alchemyProvider) {
        setError(
          "Something went wrong. Please refresh the page and try again."
        );
        return;
      }

      setIsMinting(true);

      // await smartWallet?.switchChain({ id: chainTobeUsed.id });

      // const txnHash = await smartWallet.sendTransaction({
      //   account: smartWallet.account,
      //   to: COLLECTIBLES_MANAGER_ADDRESS, // The desired target contract address
      //   chain: chainTobeUsed,
      //   data: encodeFunctionData({
      //     abi: collectiblesManagerABI,
      //     functionName: "ERC1155Mint",
      //     args: [
      //       contract_address, // Collectibles Contract
      //       USDC, // USDC Address
      //       receivingWallet.address, // Wallet Address of User
      //       collectiblesSelected.length, // Selected Collectibles
      //       1,
      //     ],
      //   }),
      //   value: 0,
      // });

      // Send the user operation
      const userOperation = await alchemyProvider.sendUserOperation({
        uo: {
          target: import.meta.env.VITE_COLLECTIBLES_MANAGER_ADDRESS, // The desired target contract address
          data: encodeFunctionData({
            abi: collectiblesManagerABI,
            functionName: "ERC1155Mint",
            args: [
              contract_address, // Collectibles Contract
              USDC, // USDC Address
              receivingWallet.address, // Wallet Address of User
              collectiblesSelected.length, // Selected Collectibles
              1,
            ],
          }),
        },
      });
      // Wait for the user operation to be mined
      const txnHash = await alchemyProvider.waitForUserOperationTransaction({
        hash: userOperation.hash,
      });

      console.log("Transaction Hash:", txnHash);

      if (!txnHash) {
        setIsMinting(false);
        console.warn("Failed to mint collectibles");
        setError("Failed to mint collectibles. Please try again later.");
        return;
      }

      const boughtCollectibles = sortItems(collectiblesSelected);

      const stateUpdaters = {
        setTxnHash,
        setBoughtCollectibles,
        setIsMinting,
        setError,
      };

      // Process minting
      const mintingResult = await processCollectiblesMinting(
        {
          tx_id: txnHash,
          userWallet: receivingWallet.address,
          contractAddress: contract_address,
          boughtCollectibles,
          loggedInUser,
        },
        stateUpdaters
      ).catch((error) => {
        console.error("Failed to process minting:", error);
        return { success: false };
      });

      if (mintingResult.success) {
        closeSheetModal();

        setSheetModalBackdropStyle({});
        await addDelayInMs(1000);

        setSheetModalContent(<CollectiblePaymentSuccess />);
        toggleSheetModal();
        return;
      }
    } catch (error) {
      console.error(error);
      setIsMinting(false);
      setError("Something went wrong. Please try again later.");
    } finally {
      setIsMinting(false);
    }
  };

  return {
    buyWihoutPaymaster,
    buyForFree,
    buyWithPaymaster,
  };
};
