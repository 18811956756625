import { useCheckoutStore } from "../checkoutStore";
import {
  addDelayInMs,
  calculateTotal,
  checkPrerequisites,
  checkTokensMinted,
  countAndDeduplicateTickets,
  deployTicketingMetadata,
} from "../../../utils/checkout";
import { useGetTicketManagerABI } from "./checkoutQueries";
import { useMemo } from "react";
import usdcJson from "../../../constants/usdc-abi.json";
import { useChainId, useSwitchChain, useWriteContract } from "wagmi";
import { isProd } from "../../../utils/utils";
import {
  USDC_ADDRESS,
  USDC_ADDRESS_SEPOLIA,
} from "../../../constants/constants";
import { base, baseSepolia } from "viem/chains";
import { usePrimaryWallet } from "../../../hooks/usePrimaryWallet";
import { useBuyTicketStore } from "../../BuyTicket/buyTicketStore";
import useGlobalModal from "../../../hooks/useGlobalModal";
import PaymentSuccess from "../PaymentSuccess";
import { insertTicketsMinted } from "../../../api/ticketing";
import useAuth from "../../../hooks/useAuth";
import { encodeFunctionData } from "viem";
import { useSmartWallets } from "@privy-io/react-auth/smart-wallets";
import useAccountKit from "../../../hooks/useAccountKit";

export const useTicketCheckout = () => {
  const { loggedInUser } = useAuth();
  const { ticketsSelected, contract, event } = useBuyTicketStore();
  const {
    ticketsTotal,
    momentifyFee,
    setIsMinting,
    setTxnHash,
    setBoughtTickets,
    setError,
  } = useCheckoutStore();
  const { alchemyProvider } = useAccountKit();
  const { primaryWallet, receivingWallet } = usePrimaryWallet();
  const { client: smartWallet } = useSmartWallets();
  const { data: ticketManagerABI } = useGetTicketManagerABI();
  const { writeContractAsync } = useWriteContract();
  const chain = useChainId();
  const { switchChain } = useSwitchChain();
  const { setSheetModalContent, toggleSheetModal, closeSheetModal } =
    useGlobalModal();
  const {
    id: event_id,
    headline_artist_id,
    headline_artist,
    event_name,
    venue,
    date: event_date,
  } = event || {};
  const { estimatedGasPrice, contractAddress, image, symbol } = contract || {};

  const USDC = isProd ? USDC_ADDRESS : USDC_ADDRESS_SEPOLIA;
  const chainTobeUsed = isProd ? base : baseSepolia;
  const TICKETS_MANAGER_ADDRESS = import.meta.env.VITE_TICKETS_MANAGER_ADDRESS;

  const { parsedTotal } = useMemo(() => {
    if (!ticketsTotal) return { formattedTotal: "0", parsedTotal: 0 };

    const total = calculateTotal({
      ticketsTotal,
      momentifyFee,
      gas: estimatedGasPrice,
    });

    return total;
  }, [ticketsTotal, momentifyFee, estimatedGasPrice]);

  const buyWithPaymaster = async () => {
    // Buy tickets with paymaster
    try {
      const isPrerequisitesMet = checkPrerequisites({
        loggedInUser,
        primaryWallet,
        receivingWallet,
        ticketsSelected,
        setError,
      });

      if (!isPrerequisitesMet) return;

      setIsMinting(true);

      const tickets = countAndDeduplicateTickets(ticketsSelected).map(
        ({ tier_id, count, type }) => ({
          tierName: type,
          tierId: tier_id,
          numTokens: count,
        })
      );

      const { success: deployMetadataSuccess } = await deployTicketingMetadata(
        ticketsSelected,
        {
          contractAddress,
          event_name,
          symbol,
          image,
          headline_artist,
          headline_artist_id,
          event_date,
          event_id,
          venue,
          userId: loggedInUser?.id,
        }
      );

      if (!deployMetadataSuccess) {
        setIsMinting(false);
        console.warn("Failed to deploy metadata");
        setError("Failed to deploy metadata. Please try again later.");
        return;
      }

      const txnHash = await smartWallet.sendTransaction({
        account: smartWallet.account,
        chain: chainTobeUsed,
        calls: [
          {
            to: USDC, // USDC
            data: encodeFunctionData({
              abi: usdcJson.abi,
              functionName: "approve",
              args: [TICKETS_MANAGER_ADDRESS, parsedTotal],
            }),
          },
          {
            to: TICKETS_MANAGER_ADDRESS,
            data: encodeFunctionData({
              abi: ticketManagerABI,
              functionName: "batchMint",
              args: [
                contractAddress, // Tickets Contract
                receivingWallet.address, // Wallet Address of User
                tickets, // Selected Tickets
                USDC, // USDC
              ],
            }),
          },
        ],
      });

      console.log("Transaction Hash:", txnHash);

      if (!txnHash) {
        setIsMinting(false);
        console.warn("Failed to mint tickets");
        setError("Failed to mint tickets. Please try again later.");
        return;
      }

      const boughtTickets = countAndDeduplicateTickets(ticketsSelected);

      const { data: batchMintData, success: getTicketsSuccess } =
        (await checkTokensMinted(
          receivingWallet.address,
          contractAddress,
          txnHash
        ).catch((error) => {
          console.error("Failed to get minted tickets:", error);
          return { success: false, data: null };
        })) || {};

      if (!getTicketsSuccess || !batchMintData || !batchMintData?.length) {
        setError("Failed to get minted tickets. Please try again later.");
        return;
      }

      const { success, data } =
        (await insertTicketsMinted({
          tickets: boughtTickets,
          userId: loggedInUser?.id,
          userWallet: receivingWallet.address,
          txnHash: txnHash,
          mints: batchMintData || [],
        })) || {};

      if (!success || !data || !data?.length) {
        setIsMinting(false);
        console.warn("Failed to insert tickets minted");
        setError("Failed to insert tickets minted. Please try again later.");
        return;
      }

      setBoughtTickets(boughtTickets);

      toggleSheetModal();
      setSheetModalContent(<PaymentSuccess />);
      await addDelayInMs(500);
      toggleSheetModal();

      console.log("Tickets Minted Inserted to DB");
      setIsMinting(false);
    } catch (error) {
      console.error(error);
      setIsMinting(false);
      setError("Something went wrong. Please try again later.");
    } finally {
      setIsMinting(false);
    }
  };

  const buyWihoutPaymaster = async () => {
    try {
      const isPrerequisitesMet = checkPrerequisites({
        loggedInUser,
        primaryWallet,
        receivingWallet,
        ticketsSelected,
        setError,
      });

      if (!isPrerequisitesMet) return;

      setIsMinting(true);

      if (isProd && chain !== base.id) {
        switchChain({ chainId: base.id });
        primaryWallet?.switchChain &&
          (await primaryWallet?.switchChain(base.id));
      } else if (!isProd && chain !== baseSepolia.id) {
        switchChain({ chainId: baseSepolia.id });
        primaryWallet?.switchChain &&
          (await primaryWallet?.switchChain(baseSepolia.id));
      }

      console.log("Starting Approval...");

      const usdcApprovalTxn = await writeContractAsync({
        address: USDC,
        abi: usdcJson.abi,
        functionName: "approve",
        args: [TICKETS_MANAGER_ADDRESS, parsedTotal],
        chain: chainTobeUsed,
        chainId: chainTobeUsed.id,
      });

      if (!usdcApprovalTxn) {
        setIsMinting(false);
        console.warn("Failed to approve USDC");
        setError("Failed to approve USDC. Please try again later.");
        return;
      }

      console.log("USDC Approved:", usdcApprovalTxn);
      console.log("Starting Batch Mint...");

      const { success: deployMetadataSuccess } = await deployTicketingMetadata(
        ticketsSelected,
        {
          contractAddress,
          event_name,
          symbol,
          image,
          headline_artist,
          headline_artist_id,
          event_date,
          event_id,
          venue,
          userId: loggedInUser?.id,
        }
      );

      if (!deployMetadataSuccess) {
        setIsMinting(false);
        console.warn("Failed to deploy metadata");
        setError("Failed to deploy metadata. Please try again later.");
        return;
      }

      const tickets = countAndDeduplicateTickets(ticketsSelected).map(
        ({ tier_id, count, type }) => ({
          tierName: type,
          tierId: tier_id,
          numTokens: count,
        })
      );

      const batchMintTxn = await writeContractAsync({
        address: TICKETS_MANAGER_ADDRESS,
        abi: ticketManagerABI,
        functionName: "batchMint",
        args: [
          contractAddress, // Tickets Contract
          receivingWallet.address, // Wallet Address of User
          tickets, // Selected Tickets
          USDC, // USDC
        ],
        chain: chainTobeUsed,
        chainId: chainTobeUsed.id,
      });

      if (!batchMintTxn) {
        setIsMinting(false);
        console.warn("Failed to mint tickets");
        setError("Failed to mint tickets. Please try again later.");
        return;
      }

      console.log("Batch Minted:", batchMintTxn);

      setTxnHash(batchMintTxn);

      const boughtTickets = countAndDeduplicateTickets(ticketsSelected);

      setBoughtTickets(boughtTickets);

      console.log("Getting Tickets Minted...");

      const { data: batchMintData, success: getTicketsSuccess } =
        (await checkTokensMinted(
          receivingWallet.address,
          contractAddress,
          batchMintTxn
        )) || {};

      if (!getTicketsSuccess || !batchMintData || !batchMintData?.length) {
        setIsMinting(false);
        console.warn("Failed to get minted tickets");
        setError("Failed to get minted tickets. Please try again later.");
        return;
      }

      console.log("Tickets Minted:", { batchMintData });
      console.log("Inserting Tickets Minted...");

      const { success, data } =
        (await insertTicketsMinted({
          tickets: boughtTickets,
          userId: loggedInUser?.id,
          userWallet: receivingWallet.address,
          txnHash: batchMintTxn,
          mints: batchMintData || [],
        })) || {};

      if (!success || !data || data?.length < 1) {
        setIsMinting(false);
        console.warn("Failed to insert tickets minted");
        setError("Failed to insert tickets minted. Please try again later.");
        return;
      }

      toggleSheetModal();
      setSheetModalContent(<PaymentSuccess />);
      await addDelayInMs(500);
      toggleSheetModal();

      console.log("Tickets Inserted:", data);
    } catch (error) {
      console.error(error);
      setError("Something went wrong. Please try again later.");
    } finally {
      setIsMinting(false);
    }
  };

  const buyForFree = async () => {
    try {
      const isPrerequisitesMet = checkPrerequisites({
        loggedInUser,
        primaryWallet,
        receivingWallet,
        ticketsSelected,
        setError,
      });

      if (!isPrerequisitesMet) return;

      const hasPrice = ticketsSelected.some((ticket) => ticket.price > 0);

      if (hasPrice) {
        setError(
          "There are some tickets with price. Please remove them before buying."
        );
        return;
      }

      if (!alchemyProvider) {
        setError(
          "Something went wrong. Please refresh the page and try again."
        );
        return;
      }

      setIsMinting(true);

      const tickets = countAndDeduplicateTickets(ticketsSelected).map(
        ({ tier_id, count, type }) => ({
          tierName: type,
          tierId: tier_id,
          numTokens: count,
        })
      );

      const { success: deployMetadataSuccess } = await deployTicketingMetadata(
        ticketsSelected,
        {
          contractAddress,
          event_name,
          symbol,
          image,
          headline_artist,
          headline_artist_id,
          event_date,
          event_id,
          venue,
          userId: loggedInUser?.id,
        }
      );

      if (!deployMetadataSuccess) {
        setIsMinting(false);
        console.warn("Failed to deploy metadata");
        setError("Failed to deploy metadata. Please try again later.");
        return;
      }

      // const txnHash = await smartWallet.sendTransaction({
      //   account: smartWallet.account,
      //   to: TICKETS_MANAGER_ADDRESS, // The desired target contract address
      //   chain: chainTobeUsed,
      //   data: encodeFunctionData({
      //     abi: ticketManagerABI,
      //     functionName: "batchMint",
      //     args: [
      //       contractAddress, // Tickets Contract
      //       receivingWallet.address, // Wallet Address of User
      //       tickets, // Selected Tickets
      //       USDC, // USDC
      //     ],
      //   }),
      //   value: 0,
      // });

      // Send the user operation
      const userOperation = await alchemyProvider.sendUserOperation({
        uo: {
          target: import.meta.env.VITE_TICKETS_MANAGER_ADDRESS, // The desired target contract address
          data: encodeFunctionData({
            abi: ticketManagerABI,
            functionName: "batchMint",
            args: [
              contractAddress, // Tickets Contract
              receivingWallet.address, // Wallet Address of User
              tickets, // Selected Tickets
              USDC, // USDC
            ],
          }),
        },
      });
      // Wait for the user operation to be mined
      const txnHash = await alchemyProvider.waitForUserOperationTransaction({
        hash: userOperation.hash,
      });

      console.log("Transaction Hash:", txnHash);

      if (!txnHash) {
        setIsMinting(false);
        console.warn("Failed to mint tickets");
        setError("Failed to mint tickets. Please try again later.");
        return;
      }

      const boughtTickets = countAndDeduplicateTickets(ticketsSelected);

      const { data: batchMintData, success: getTicketsSuccess } =
        (await checkTokensMinted(
          receivingWallet.address,
          contractAddress,
          txnHash
        ).catch((error) => {
          console.error("Failed to get minted tickets:", error);
          setError("Failed to get minted tickets. Please try again later.");
          return { success: false, data: null };
        })) || {};

      if (!getTicketsSuccess || !batchMintData || !batchMintData?.length) {
        setError("Failed to get minted tickets. Please try again later.");
        return;
      }

      const { success, data } =
        (await insertTicketsMinted({
          tickets: boughtTickets,
          userId: loggedInUser?.id,
          userWallet: receivingWallet.address,
          txnHash: txnHash,
          mints: batchMintData || [],
        })) || {};

      if (!success || !data || !data?.length) {
        setIsMinting(false);
        console.warn("Failed to insert tickets minted");
        setError("Failed to insert tickets minted. Please try again later.");
        return;
      }

      setBoughtTickets(boughtTickets);

      closeSheetModal();
      setSheetModalContent(<PaymentSuccess />);
      await addDelayInMs(500);
      toggleSheetModal();

      console.log("Tickets Minted Inserted to DB");
      setIsMinting(false);
    } catch (error) {
      console.error(error);
      setIsMinting(false);
      setError("Something went wrong. Please try again later.");
    } finally {
      setIsMinting(false);
    }
  };

  return { buyWithPaymaster, buyWihoutPaymaster, buyForFree };
};
