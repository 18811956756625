import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useBackstageEventMintsQuery } from "./hooks/backstageEventQueries";
import {
  Container,
  Header,
  InputContainer,
} from "./BackstageManualCheckin-Styles";
import InputBar from "../../components/BackstageManualCheckin/InputBar";
import BackButton from "../../components/BackButton";
import EmptyState from "../../components/EmptyState";
import TwoTickets from "../../assets/svg/two-tickets.svg?react";
import { ButtonRect } from "../../GlobalStyles";
import Mints from "../../components/BackstageManualCheckin/Mints";
import BackstageEventFilters from "../../components/BackstageEventScans/Filters";
import { useTicketFiltersStore } from "../BackstageEvent/stores/ticketFiltersStore";
import SelectTicketFilter from "../../components/BackstageEventScans/SelectTicketFilter";

export default function BackstageManualCheckin() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { isOpen } = useTicketFiltersStore();
  const { data, isLoading } = useBackstageEventMintsQuery({
    eventId: id,
  });
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/backstage/events/${id}?date=${searchParams.get("date")}`);
  };

  if (isOpen) {
    return <SelectTicketFilter data={data} />;
  }

  if (!data?.length && !isLoading) {
    return (
      <EmptyState
        icon={<TwoTickets height={24} width={24} stroke="white" />}
        title="No Tickets Found"
        description="This event has no tickets yet."
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: "16px 20px",
        }}
        button={
          <ButtonRect primary onClick={handleBack}>
            Go back
          </ButtonRect>
        }
      />
    );
  }

  return (
    <Container>
      <Header>
        <InputContainer>
          <BackButton
            style={{
              paddingRight: "0px",
              width: 28,
            }}
            onClick={handleBack}
          />
          <InputBar />
        </InputContainer>
        <BackstageEventFilters
          style={{
            padding: "16px 0",
          }}
        />
      </Header>
      <Mints data={data} isLoading={isLoading} />
    </Container>
  );
}
