import { useNavigate, useParams } from "react-router-dom";
import TicketItem from "../../components/Tickets/TicketItem";
import TopNavV2 from "../../components/TopNavV2";
import { useGetMintedTicket } from "./hooks/ticketTransferQueries";
import { Container, Content } from "./TicketTransfer-Styles";
import TicketTransferSearch from "../../components/TicketTransfer/TicketTransferSearch";
import TicketTransferFooter from "../../components/TicketTransfer/TicketTransferFooter";
import { useTicketTransferStore } from "./stores/ticketTransferStore";
import { useEffect } from "react";
import { useAlchemyQuery } from "../Profile/hooks/profileQueries";

export default function TicketTransfer() {
  const { profileId, mintId } = useParams();
  const navigate = useNavigate();
  const { selectedUsers, setTicket, reset } = useTicketTransferStore();
  const { data, isLoading: isTicketLoading } = useGetMintedTicket({
    userId: profileId,
    mintId,
  });

  useAlchemyQuery();

  const isLoading = isTicketLoading;

  const isTransferable = selectedUsers?.length > 0;

  useEffect(() => {
    if (!data) return;

    reset();
    setTicket({ ...data, count: 1 });
  }, [data, setTicket, reset]);

  const handleBack = () => {
    reset();

    if (!data) return navigate(-1);

    const { event_id, type } = data || {};
    navigate(`/profile/${profileId}/tickets/${event_id}/type/${type}`);
  };

  return (
    <Container>
      <TopNavV2
        title="Transfer Ticket"
        onBack={handleBack}
        isSticky
        isBlurred
      />
      <Content style={{ padding: `0 20px ${isTransferable ? "0" : "16px"}` }}>
        <TicketItem ticket={{ ...data, count: 1 }} isLoading={isLoading} />
        <TicketTransferSearch isLoading={isLoading} />
      </Content>
      {isTransferable && <TicketTransferFooter />}
    </Container>
  );
}
