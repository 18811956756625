import { useBackstageEvents } from "./hooks/backstageSearchQueries";
import { useBackstageStore } from "../../store/backstageStore";
import BackstageEvents from "../../components/BackstageEvents";
import {
  FilterButtonsContainer,
  SearchResultsContainer,
} from "../../components/BackstageSearch/BackstageSearchResults/BackstageSearchResults-Styles";
import BackstageFilterButton from "../../components/BackstageSearch/BackstageFilterButton";
import { useBackstageSearchStore } from "./stores/backstageSearchStore";
import TopNavV2 from "../../components/TopNavV2";
import EmptyState from "../../components/EmptyState";
import Bookmark from "../../assets/svg/bookmark.svg?react";
import { useBackstageAccessQuery } from "../BackstageProfile/hooks/backstageProfileQueries";
import useAuth from "../../hooks/useAuth";

export default function BackstageSearch() {
  const { loggedInUser } = useAuth();
  const { artistIds, venueIds } = useBackstageStore();
  const { type } = useBackstageSearchStore();

  const futureEvents = useBackstageEvents({
    artistIds,
    venueIds,
  });

  const pastEvents = useBackstageEvents({
    artistIds,
    venueIds,
    type: "past",
  });

  useBackstageAccessQuery({
    userId: loggedInUser?.id ?? null,
  });

  const futureResults = futureEvents?.data?.pages?.flat();
  const pastResults = pastEvents?.data?.pages?.flat();

  if (
    !futureResults?.length &&
    !pastResults?.length &&
    !futureEvents?.isLoading &&
    !pastEvents?.isLoading
  ) {
    return (
      <>
        <TopNavV2 title="Events" isSticky isBlurred noBack />
        <SearchResultsContainer
          style={{
            paddingTop: 0,
          }}
        >
          <EmptyState
            icon={<Bookmark height={28} width={28} stroke="white" />}
            title="No Events Found"
            description="You don't have access to events yet."
            style={{
              paddingTop: "60%",
            }}
          />
        </SearchResultsContainer>
      </>
    );
  }

  return (
    <>
      <TopNavV2 title="Events" isSticky isBlurred noBack />
      <SearchResultsContainer
        style={{
          paddingTop: 0,
        }}
      >
        <FilterButtonsContainer>
          <BackstageFilterButton type="all" />
          <BackstageFilterButton type="future" />
          <BackstageFilterButton type="past" />
        </FilterButtonsContainer>
        {(type === "all" || type === "future") && (
          <BackstageEvents
            type="Upcoming"
            events={futureResults}
            isLoading={futureEvents.isLoading}
          />
        )}
        {(type === "all" || type === "past") && (
          <BackstageEvents
            type="Past"
            events={pastResults}
            isLoading={pastEvents.isLoading}
          />
        )}
      </SearchResultsContainer>
    </>
  );
}
